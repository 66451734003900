import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';

const schema = Yup.object().shape({
  newPassword: Yup.string().min(6, 'Password must be at least 6 characters').required('New Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

const ChangePasswordDialog = ({ open, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await schema.validate(formData, { abortEarly: false });
      await onSubmit(formData.newPassword);
      setLoading(false);
      setFormData({ newPassword: '', confirmPassword: '' });
      onClose();
    } catch (error) {
      if (error.name === 'ValidationError') {
        const validationErrors = {};
        error.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
      }
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="newPassword"
            autoComplete="new-password"
            label="New Password"
            type="password"
            fullWidth
            variant="standard"
            value={formData.newPassword}
            onChange={handleChange}
            error={!!errors.newPassword}
            helperText={errors.newPassword}
          />
          <TextField
            margin="dense"
            name="confirmPassword"
            label="Confirm Password"
            autoComplete="new-password"
            type="password"
            fullWidth
            variant="standard"
            value={formData.confirmPassword}
            onChange={handleChange}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton loading={loading} onClick={handleSubmit} variant="contained">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

ChangePasswordDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ChangePasswordDialog;
