/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Drawer, Box, Typography, IconButton, Modal, Grid } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
// import PriceWithColor from './PriceWithColor';
import RHFToggleButtonGroup from '../../../../components/hook-form/RHFToggleButtonGroup';
import axiosInstance from '../../../../utils/axios';
import modifyScriptName from '../../../../utils/modifyScriptName';
import useResponsive from '../../../../hooks/useResponsive';

export const Wrapper = ({ children, open, onClose }) => {
  const isMobile = useResponsive('down', 'sm');
  return isMobile ? (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Box sx={{ p: 2 }}>{children}</Box>
    </Drawer>
  ) : (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: 'none',
          p: 4,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};

// const QuoteValueItem = ({ label, value }) => (
//   <Box display="flex" justifyContent="space-between">
//     <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
//       {label}
//     </Typography>
//     <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
//       :
//     </Typography>
//     <Typography variant="body1" textAlign="right">
//       {value}
//     </Typography>
//   </Box>
// );
const QuoteValueItem = ({ label, value }) => (
  <Grid container spacing={1} alignItems="left">
    <Grid item xs={4}>
      <Typography variant="body1" textAlign="left" sx={{ whiteSpace: 'nowrap' }}>
        {label}
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
        :
      </Typography>
    </Grid>
    <Grid item xs={7}>
      <Typography variant="body1" textAlign="right" sx={{ whiteSpace: 'nowrap' }}>
        {value}
      </Typography>
    </Grid>
  </Grid>
);

function BuyScriptDrawer({ open, onClose, script, isMobile }) {
  const { quotesData } = useSelector((state) => state?.trade);
  const [scriptQuote, setScriptQuote] = useState(null);
  const updatedData = quotesData[script?.instrument_token];
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchScriptPrice = async () => {
      try {
        if (script) {
          const response = await axiosInstance.get(`/script/quote?scriptId=${script._id}`);
          const quote = Object.values(response.data.data)[0];
          setScriptQuote(quote);
        }
      } catch (err) {
        console.log('ERR', err);
      }
    };
    fetchScriptPrice();
  }, [script]);

  const defaultValues = {
    quantity: 1,
    limit: 0,
    orderType: 'MARKET',
  };

  const schema = yup.object().shape({
    quantity: yup
      .number()
      .integer('Quantity must be an integer')
      .typeError('Quantity must be a number')
      .required('Quantity is required')
      .positive('Quantity must be a positive number'),
    limit: yup.number().when('orderType', {
      is: 'LIMIT',
      then: yup
        .number()
        .typeError('Limit must be a number')
        .required('Limit is required')
        .positive('Limit must be a positive number'),
    }),
    orderType: yup.string().required('Order type is required').oneOf(['MARKET', 'LIMIT'], 'Invalid order type'),
  });

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = methods;

  const orderType = watch('orderType');

  useEffect(() => {
    if (orderType === 'LIMIT') {
      setValue('limit', updatedData.depth?.buy[0]?.price || 0);
    }
  }, [orderType, scriptQuote, setValue]);

  // console.log('SCRIPT', script);
  const onSubmit = async (data, event) => {
    try {
      if (data.orderType === 'MARKET') {
        delete data.limit;
      }
      data.scriptId = script._id;
      // data.side = event.nativeEvent.submitter.innerText;
      data.side = event.nativeEvent.submitter.getAttribute('data-side');
      const response = await axiosInstance.post('/trade/add', data);
      enqueueSnackbar(response.data.message);
      if (response.data.tradesCancelled) {
        enqueueSnackbar(response.data.cancelMessage, { persist: true });
      }
      onClose();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error', displayAsDialog: true });
    }
  };

  useEffect(() => {
    // console.log('METHODS', methods.getFieldState('orderType'));
  }, [methods.watch()]);

  if (!script) return null;

  return (
    <Wrapper
      isMobile={isMobile}
      open={open}
      onClose={onClose}
      children={
        <Box width={{ xs: '100%', sm: '350px' }} marginX={'auto'} border={'none'}>
          <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" textAlign={'center'} pb={2}>
            {modifyScriptName(script.tradingsymbol)}
          </Typography>
          {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center', width: '100%' }}>
            <Box sx={{ flex: 1, mr: 1, p: 1, background: theme.palette.background.neutral }}>
              <Typography variant="body1">Buy</Typography>
              <PriceWithColor price={updatedData?.depth?.sell[0]?.price.toFixed(2) || 0} variant={'h6'} />
            </Box>
            <Box sx={{ flex: 1, ml: 1, p: 1, background: theme.palette.background.neutral }}>
              <Typography variant="body1">Sell</Typography>
              <PriceWithColor price={updatedData?.depth?.buy[0]?.price.toFixed(2) || 0} variant={'h6'} />
            </Box>
          </Box> */}
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} sx={{ width: '100%' }}>
            <RHFToggleButtonGroup
              name="orderType"
              label="Order Type"
              color="primary"
              defaultValue={defaultValues.orderType}
              sx={{ width: '100%', mb: 2 }}
              options={[
                { label: 'MARKET', value: 'MARKET' },
                { label: 'LIMIT', value: 'LIMIT' },
              ]}
            />
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <RHFTextField name="quantity" label="Quantity" type="number" />
              {methods.getValues().orderType === 'LIMIT' && <RHFTextField name="limit" label="Limit" type="number" />}
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, my: 3 }}>
              <LoadingButton
                type="submit"
                data-side="BUY"
                sx={{
                  flex: 1,
                  background: 'green',
                  color: 'white',
                  '&:hover': { background: 'green', opacity: 0.75 },
                  p: 2,
                  textTransform: 'uppercase',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                loading={isSubmitting}
              >
                {methods.getValues().orderType === 'MARKET' ? 'Buy' : 'Buy Order'}
                {methods.getValues().orderType === 'MARKET' && (
                  <Typography variant="h6">
                    {+updatedData?.depth?.sell[0]?.price.toFixed(2) ||
                      (+updatedData?.ohlc?.high ? +updatedData?.ohlc?.close.toFixed(2) : 0)}
                  </Typography>
                )}
              </LoadingButton>
              <LoadingButton
                type="submit"
                data-side="SELL"
                sx={{
                  flex: 1,
                  background: 'red',
                  color: 'white',
                  '&:hover': { background: 'red', opacity: 0.75 },
                  p: 2,
                  textTransform: 'uppercase',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                loading={isSubmitting}
              >
                {methods.getValues().orderType === 'MARKET' ? 'Sell' : 'Sell Order'}
                {methods.getValues().orderType === 'MARKET' && (
                  <Typography variant="h6">
                    {+updatedData?.depth?.buy[0]?.price.toFixed(2) ||
                      (+updatedData?.ohlc?.high ? +updatedData?.ohlc?.close.toFixed(2) : 0)}
                  </Typography>
                )}
              </LoadingButton>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 2,
              }}
            >
              {/* First row */}
              <Box>
                {methods.getValues().orderType === 'LIMIT' && (
                  // <Typography variant="body1" textAlign={'left'}>
                  //   Bid:{' '}
                  //   {+updatedData?.depth?.buy[0]?.price.toFixed(2) ||
                  //     (+updatedData?.ohlc?.high ? +updatedData?.ohlc?.close.toFixed(2) : 0)}
                  // </Typography>
                  <QuoteValueItem
                    label={'Bid'}
                    value={
                      +updatedData?.depth?.buy[0]?.price.toFixed(2) ||
                      (+updatedData?.ohlc?.high ? +updatedData?.ohlc?.close.toFixed(2) : 0)
                    }
                  />
                )}
                {/* <Typography variant="body1" textAlign={'left'}>
                  Open: {updatedData?.ohlc?.open}
                </Typography>
                <Typography variant="body1" textAlign={'left'}>
                  High: {updatedData?.ohlc?.high}
                </Typography>
                <Typography variant="body1" textAlign={'left'}>
                  Upper Circuit: {scriptQuote?.upper_circuit_limit}
                </Typography> */}
                <QuoteValueItem label={'Open'} value={updatedData?.ohlc?.open} />
                <QuoteValueItem label={'High'} value={updatedData?.ohlc?.high} />
                <QuoteValueItem label={'UC'} value={scriptQuote?.upper_circuit_limit} />
              </Box>
              <Box>
                {methods.getValues().orderType === 'LIMIT' && (
                  // <Typography variant="body1" textAlign={'left'}>
                  //   Ask:{' '}
                  //   {+updatedData?.depth?.sell[0]?.price.toFixed(2) ||
                  //     (+updatedData?.ohlc?.high ? +updatedData?.ohlc?.close.toFixed(2) : 0)}
                  // </Typography>
                  <QuoteValueItem
                    label={'Ask'}
                    value={
                      +updatedData?.depth?.sell[0]?.price.toFixed(2) ||
                      (+updatedData?.ohlc?.high ? +updatedData?.ohlc?.close.toFixed(2) : 0)
                    }
                  />
                )}
                {/* <Typography variant="body1" textAlign={'left'}>
                  Close: {updatedData?.ohlc?.close}
                </Typography>
                <Typography variant="body1" textAlign={'left'}>
                  Low: {updatedData?.ohlc?.low}
                </Typography>
                <Typography variant="body1" textAlign={'left'}>
                  Lower Circuit: {scriptQuote?.lower_circuit_limit}
                </Typography> */}
                <QuoteValueItem label={'Close'} value={updatedData?.ohlc?.close} />
                <QuoteValueItem label={'Low'} value={updatedData?.ohlc?.low} />
                <QuoteValueItem label={'LC'} value={scriptQuote?.lower_circuit_limit} />
              </Box>
            </Box>
          </FormProvider>
        </Box>
      }
    />
  );
}

export default BuyScriptDrawer;
