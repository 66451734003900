import PropTypes from 'prop-types';
// import { useState } from 'react';
// // @mui
// import { useTheme } from '@mui/material/styles';
import { TableRow, Checkbox, TableCell } from '@mui/material';
// utils
import { fDate } from '../../../../utils/formatTime';
import { fCurrency } from '../../../../utils/formatNumber';
// components
// import Label from '../../../../components/Label';
// import Image from '../../../../components/Image';
// import Iconify from '../../../../components/Iconify';
// import { TableMoreMenu } from '../../../../components/table';
//

// ----------------------------------------------------------------------

InstumentTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  // onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  // onDeleteRow: PropTypes.func,
  userType: PropTypes.object,
};

export default function InstumentTableRow({ row, selected, onSelectRow, userType }) {
  console.log('ROW', row);

  //   const { name, cover, createdAt, inventoryType, price } = row;
  const { tradingsymbol, name, expiry, exchange, last_price: lastPrice } = row;

  // const [openMenu, setOpenMenuActions] = useState(null);

  // const handleOpenMenu = (event) => {
  //   setOpenMenuActions(event.currentTarget);
  // };

  // const handleCloseMenu = () => {
  //   setOpenMenuActions(null);
  // };

  return (
    <TableRow hover selected={selected}>
      {userType.isClient && (
        <TableCell padding="checkbox" align="left">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
      )}

      {/* <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Image disabledEffect alt={name} src={cover} sx={{ borderRadius: 1.5, width: 48, height: 48, mr: 2 }} />
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell> */}

      <TableCell>{tradingsymbol}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{fDate(expiry)}</TableCell>
      <TableCell>{exchange.name}</TableCell>
      <TableCell>{fCurrency(lastPrice)}</TableCell>

      {/* <TableCell align="center">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={
            (inventoryType === 'out_of_stock' && 'error') || (inventoryType === 'low_stock' && 'warning') || 'success'
          }
          sx={{ textTransform: 'capitalize' }}
        >
          {inventoryType ? sentenceCase(inventoryType) : ''}
        </Label>
      </TableCell> */}

      {/* <TableCell align="right">{fCurrency(price)}</TableCell> */}

      {/* <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>
            </>
          }
        />
      </TableCell> */}
    </TableRow>
  );
}
