import * as Yup from 'yup';

const personalDetailValidator = (role, userType, isEdit) => {
  let schema = Yup.object().shape({
    username: Yup.string()
      .transform((value) => value.toLowerCase())
      .required('Username is required')
      .max(16, 'Username must be at most 16 characters')
      .matches(/^\S*$/, 'No whitespace allowed in username'),
    ...(isEdit
      ? {}
      : {
          password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters long')
            .matches(/^\S*$/, 'No whitespace allowed in password'),
        }),
    email: Yup.string().email('Invalid email'),
    firstname: Yup.string(),
    lastname: Yup.string(),
    phone: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    country: Yup.string(),
  });

  if (userType.toLowerCase() === 'client') {
    schema = schema.shape({
      isDemoAccount: Yup.boolean().required('Demo validity is required'),
      enablePattern: Yup.boolean().required('Pattern set status is required'),
      pattern: Yup.string().when('enablePattern', {
        is: true,
        then: Yup.string()
          .required('Pattern is required')
          .matches(/^\d{4}$/, 'Pattern must be a four-digit number'),
      }),
    });
    if (role.toLowerCase() === 'admin') {
      schema = schema.shape({
        manager: Yup.string().required('Broker is required').trim(),
      });
    }
  }
  return schema;
};

export default personalDetailValidator;
