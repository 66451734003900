import { createSlice } from '@reduxjs/toolkit';
import { symbol } from 'prop-types';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  forwarderData: [],
  currentPage: null,
  totalPages: null,
  totalResults: null,
  userProfile: {},
};

const slice = createSlice({
  name: 'forwarder',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET FORWARDERS
    getForwardListSuccess(state, action) {
      state.isLoading = false;
      state.forwarderData = action?.payload?.users;
      state.totalPage = action?.payload?.totalPages;
      state.currentPage = action?.payload?.page;
      state.totalResults = action?.payload?.totalResults;
    },

    updateForwardListSuccess(state, action) {
      state.isLoading = false;
      const { forwarderID, filterStatus, newStatus } = action.payload;
      if (filterStatus === 'all') {
        const findIndex = state.forwarderData.findIndex((data) => data._id === forwarderID);
        state.forwarderData[findIndex].status = newStatus;
      } else {
        state.forwarderData = state.forwarderData.filter((data) => data._id !== forwarderID);
      }
    },

    getUserProfileSuccess(state, action) {
      symbol.isLoading = false;
      state.userProfile = action?.payload?.user;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const forWarderActions = slice.actions;

// ----------------------------------------------------------------------

// GET FORWARDERS DETAILS
export function getForwarders(pageSize, pageNumber, statusData, order, searchWord, cancelToken) {
  return async () => {
    let response;
    try {
      if (searchWord?.length > 0) {
        response = await axios.get(
          `/admin/user/?status=${statusData}&order=${order}&pageSize=${pageSize}&page=${pageNumber}&search=${searchWord}`,
          { cancelToken: cancelToken.token }
        );
      } else {
        await dispatch(slice.actions.startLoading());
        response = await axios.get(
          `/admin/user/?status=${statusData}&order=${order}&pageSize=${pageSize}&page=${pageNumber}`
        );
      }
      const { data, status, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.getForwardListSuccess(data));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
      if (axios.isCancel(error) || error) {
        // console.log(error);
      }
    }
  };
}

// PUT FORWARDERS

export function putForwarders(forwarderID, statusTab, notes, filterStatus) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/admin/user/${forwarderID}?${statusTab}=true`);
      const { status, newStatus, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.updateForwardListSuccess({ forwarderID, statusTab, filterStatus, newStatus }));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}

// GET USER PROFILE

export function getUserProfile(forwarderID) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/admin/user/${forwarderID}`);
      const { status, data, message } = response?.data;
      if (status === 200) {
        dispatch(slice.actions.getUserProfileSuccess(data));
      } else {
        dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
