/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { RHFSwitch, RHFTextField } from '../../../../../components/hook-form';
import RHFToggleButtonGroup from '../../../../../components/hook-form/RHFToggleButtonGroup';
import RHFAutoComplete from '../../../../../components/hook-form/RHFAutoComplete';
import MinMaxInput from './MinMaxInput';

// const EQUITY_SETTINGS_LIST = [
//   '500',
//   '1000',
//   '1500',
//   '2000',
//   '3000',
//   '4000',
//   '6000',
//   '8000',
//   '10000',
//   '15000',
//   '20000',
//   '250000',
//   '30000',
// ];
const MCX_SETTINGS_LIST = [
  {
    commodity: 'metal',
    subtitle: 'Metal ( gold, silver ) ',
  },
  {
    commodity: 'miniMetal',
    subtitle: 'Mini ( Goldm, Silverm,MGold,Msilver ) ',
  },
  {
    commodity: 'baseMetal',
    subtitle: 'Base Metal ( Copper, Nickle, Lead, Zinc, Alum ) ',
  },
  {
    commodity: 'energy',
    subtitle: 'Energy ( Natual Gas,Crude ) ',
  },
];

// const MCX_SETTINGS_LIST = [
//   { commodity: 'GOLD',subtitle:'GOLD' },
//   { commodity: 'SILVER',subtitle:'SILVER' },
//   { commodity: 'GOLDM',subtitle:"GOLDM" },
//   { commodity: 'SILVERM',subtitle:"SILVERM" },
//   { commodity: 'MGOLD',subtitle:"MGOLD" },
//   { commodity: 'MSILVER',subtitle:"MSILVER" },
//   { commodity: 'LEAD',subtitle:"LEAD" },
//   { commodity: 'ZINC',subtitle:"ZINC" },
//   { commodity: 'COPPER',subtitle:"COPPER" },
//   { commodity: 'CRUDEOIL',subtitle:"CRUDEOIL" },
//   { commodity: 'NATURALGAS',subtitle:"NATURALGAS" },
// ];
const COMEX_SETTINGS_LIST = [
  {
    commodity: 'gold',
    subtitle: 'Gold',
  },
  {
    commodity: 'silver',
    subtitle: 'Silver',
  },
  {
    commodity: 'copper',
    subtitle: 'Copper',
  },
  {
    commodity: 'naturalgas',
    subtitle: 'Natural Gas',
  },
  {
    commodity: 'crudeoil',
    subtitle: 'Crude Oil',
  },
];

// const MCX_SETTINGS_LIST = [
//   { commodity: 'metal', subtitle: 'Metal (gold, silver)' },
//   { commodity: 'miniMetal', subtitle: 'Mini (Goldm, Silverm, MGOLD, MSILVER)' },
//   { commodity: 'baseMetal', subtitle: 'Base Metal (Copper, Nickel, Lead, Zinc, Aluminium)' },
//   { commodity: 'energy', subtitle: 'Energy (Natural Gas, Crude Oil)' },
// ];

const MCX_SUBFIELDS = {
  metal: ['GOLD', 'SILVER'],
  miniMetal: ['GOLDM', 'SILVERM', 'MGOLD', 'MSILVER'],
  baseMetal: ['LEAD', 'ZINC', 'COPPER', 'ALUMINIUM'],
  energy: ['CRUDEOIL', 'NATURALGAS'],
};

const ExchangeCommodityInput = ({ exchange, methods }) => {
  const [expandedCommodities, setExpandedCommodities] = useState([]);

  useEffect(() => {
    // Find which commodities have values set and add them to the expanded list
    const defaultExpanded = Object.keys(MCX_SUBFIELDS).filter((commodity) =>
      MCX_SUBFIELDS[commodity].some((subfield) => methods.getValues(`${exchange}.${subfield}.perTradeLimit`))
    );
    console.log('Default expanded commodities:', defaultExpanded);
    if (defaultExpanded.length > 0) {
      setExpandedCommodities(defaultExpanded);
    }
  }, [exchange, methods]);

  const handleExpandClick = (commodity) => {
    if (expandedCommodities.includes(commodity)) {
      // Remove the commodity if it's already expanded
      setExpandedCommodities(expandedCommodities.filter((item) => item !== commodity));
    } else {
      // Add the commodity to the expanded list
      setExpandedCommodities([...expandedCommodities, commodity]);
    }
  };

  return (
    <Box>
      {MCX_SETTINGS_LIST.map(({ commodity, subtitle }) => (
        <Box key={commodity} sx={{ marginBottom: 3 }}>
          <Box
            sx={{
              display: 'grid',
              rowGap: 2,
              columnGap: 2,
              gridTemplateColumns: { xs: '1fr', md: '1fr 4fr' },
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Typography
              textTransform={'uppercase'}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleExpandClick(commodity)}
            >
              {subtitle}
            </Typography>
            <Box
              sx={{
                display: 'grid',
                rowGap: 2,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(2,1fr)', md: 'repeat(6,1fr)' },
                marginTop: 2,
              }}
            >
              <RHFTextField name={`${exchange}.${commodity}.perTradeLimit`} label="Per Trade Qty Limit" type="tel" />
              <RHFTextField name={`${exchange}.${commodity}.perScriptLimit`} label="Per Script Qty Limit" type="tel" />
              <RHFTextField name={`${exchange}.${commodity}.brokerageValue`} label="Brokerage" type="tel" />
              <RHFTextField name={`${exchange}.${commodity}.intradayExposure`} label="Intraday Exposure" type="tel" />
              <RHFTextField name={`${exchange}.${commodity}.holdingExposure`} label="Holding Exposure" type="tel" />
              <RHFTextField name={`${exchange}.${commodity}.refundBrokerage`} label="Refund Brokerage" type="tel" />
            </Box>
          </Box>

          {expandedCommodities.includes(commodity) && (
            <Box
              sx={{
                display: 'grid',
                rowGap: 2,
                columnGap: 2,
                gridTemplateColumns: { xs: '1fr', md: '1fr 4fr' },
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: 2,
              }}
            >
              {MCX_SUBFIELDS[commodity]?.map((subfield) => (
                <React.Fragment key={subfield}>
                  <Typography textTransform={'uppercase'}>{subfield}</Typography>
                  <Box
                    sx={{
                      display: 'grid',
                      rowGap: 2,
                      columnGap: 2,
                      gridTemplateColumns: { xs: 'repeat(2,1fr)', md: 'repeat(6,1fr)' },
                      marginTop: 2,
                    }}
                  >
                    <RHFTextField
                      name={`${exchange}.${subfield}.perTradeLimit`}
                      label="Per Trade Qty Limit"
                      type="tel"
                    />
                    <RHFTextField
                      name={`${exchange}.${subfield}.perScriptLimit`}
                      label="Per Script Qty Limit"
                      type="tel"
                    />
                    <RHFTextField name={`${exchange}.${subfield}.brokerageValue`} label="Brokerage" type="tel" />
                    <RHFTextField
                      name={`${exchange}.${subfield}.intradayExposure`}
                      label="Intraday Exposure"
                      type="tel"
                    />
                    <RHFTextField
                      name={`${exchange}.${subfield}.holdingExposure`}
                      label="Holding Exposure"
                      type="tel"
                    />
                    <RHFTextField
                      name={`${exchange}.${subfield}.refundBrokerage`}
                      label="Refund Brokerage"
                      type="tel"
                    />
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

const CommodityInputs = ({ exchange, commodity, subtitle }) => (
  <>
    <Typography textTransform={'uppercase'}>{subtitle} </Typography>
    <Box
      sx={{
        display: 'grid',
        rowGap: 2,
        columnGap: 2,
        gridTemplateColumns: { xs: 'repeat(2,1fr)', md: 'repeat(6,1fr)' },
      }}
    >
      <RHFTextField name={`${exchange}.${commodity}.perTradeLimit`} label="Per Trade Qty Limit" type="tel" />
      <RHFTextField name={`${exchange}.${commodity}.perScriptLimit`} label="Per Script Qty Limit" type="tel" />
      <RHFTextField name={`${exchange}.${commodity}.brokerageValue`} label="Brokerage" type="tel" />
      <RHFTextField name={`${exchange}.${commodity}.intradayExposure`} label="Intraday Exposure" type="tel" />
      <RHFTextField name={`${exchange}.${commodity}.holdingExposure`} label="Holding Exposure" type="tel" />
      <RHFTextField name={`${exchange}.${commodity}.refundBrokerage`} label="Refund Brokerage" type="tel" />
    </Box>
  </>
);

const TradingSettingsForm = ({
  isEdit,
  userType,
  manager,
  methods,
  defaultValues,
  EQUITY_SETTINGS_LIST,
  allowEquity,
  allowComex,
  allowMCX,
  setAllowComex,
  setAllowEquity,
  setAllowMCX,
}) => {
  // const [allowEquity, setAllowEquity] = useState(currentUser?.equityConfig?.allowEquity || false);
  // const [allowMCX, setAllowMCX] = useState(currentUser?.MCX?.allowMCX || false);
  // const [allowComex, setAllowComex] = useState(currentUser?.comexConfig?.allowComex || false);
  const { setValue, watch } = methods;
  const { docs } = useSelector((state) => state.trade?.instrumentData);
  const uniqueScripts = (docs || []).reduce(
    (acc, script) => {
      const key = `${script.name}-${script.exchange}`;
      if (!acc.set.has(key)) {
        acc.set.add(key);
        acc.result.push(script);
      }
      return acc;
    },
    { set: new Set(), result: [] }
  ).result;
  const theme = useTheme();

  const [isNSEconfigExpanded, setIsNSEconfigExpanded] = useState(false); // Initial collapsed state
  const [isMCXconfigExpanded, setIsMCXconfigExpanded] = useState(false); // Initial collapsed state
  const [isCOMEXconfigExpanded, setIsCOMEXconfigExpanded] = useState(false); // Initial collapsed state
  const [isTradeConfigExpanded, setIsTradeConfigExpanded] = useState(false); // Initial collapsed state
  // Function to toggle accordion state
  const HandleNSEConfigExpand = () => {
    setIsNSEconfigExpanded((prev) => !prev); // Toggle between true and false
  };
  const HandleMCXConfigExpand = () => {
    setIsMCXconfigExpanded((prev) => !prev); // Toggle between true and false
  };
  const HandleCOMEXConfigExpand = () => {
    setIsCOMEXconfigExpanded((prev) => !prev); // Toggle between true and false
  };
  const HandleTradeSettingsConfigExpand = () => {
    setIsTradeConfigExpanded((prev) => !prev); // Toggle between true and false
  };

  methods.setValue('allowEquity', allowEquity);
  methods.setValue('allowMCX', allowMCX);
  methods.setValue('allowComex', allowComex);
  const profitLossType = manager?.profitLossType || 'adminwise';
  const adminEquityBrokerage = watch('NSE.brokerageValue');
  const superAdminEquityBrokerage = watch('NSE.superAdminBrokerageValue');
  const adminMCXBrokerageValue = watch('MCX.brokerageValue');
  const superAdminMCXBrokerage = watch('MCX.superAdmin.brokerageValue');
  const mcxBrokerageType = watch('NSE.brokerageType');

  useEffect(() => {
    if (userType === 'Broker' && profitLossType === 'brokerwise') {
      const newBrokerBrokerage = 100 - Number(adminEquityBrokerage) - Number(superAdminEquityBrokerage);
      console.log('neeeeeeeeeeeeeeeeeeeeee', newBrokerBrokerage);
      setValue('NSE.brokerBrokerageValue', newBrokerBrokerage);
    }
  }, [userType, setValue, profitLossType, adminEquityBrokerage, superAdminEquityBrokerage]);

  useEffect(() => {
    if (userType === 'Broker' && profitLossType === 'brokerwise') {
      const newBrokerBrokerage = 100 - Number(adminMCXBrokerageValue) - Number(superAdminMCXBrokerage);
      console.log('neeeeeeeeeeeeeeeeeeeeee', newBrokerBrokerage);
      setValue('MCX.broker.brokerageValue', newBrokerBrokerage);
    }
  }, [userType, setValue, profitLossType, adminMCXBrokerageValue, superAdminMCXBrokerage, mcxBrokerageType]);

  let formContent = null;
  if (userType !== 'Client') {
    formContent = (
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {/* Existing Switches for Allowing Exchanges */}
          {manager?.NSE?.allow && (
            <Box alignItems={'center'} display={'flex'} flexDirection={'row'}>
              <Switch
                name="allowEquity"
                label="Allow Equity"
                sx={{ justifyContent: 'start', mx: 2 }}
                onChange={(e) => {
                  setAllowEquity((prev) => !prev);
                  methods.setValue('allowEquity', e.target.value === 'on');
                }}
                checked={allowEquity}
              />
              <Typography>Equity</Typography>
            </Box>
          )}
          {manager?.MCX?.allow && (
            <Box alignItems={'center'} display={'flex'} flexDirection={'row'}>
              <Switch
                name="allowMCX"
                label="Allow MCX"
                sx={{ justifyContent: 'start', mx: 2 }}
                onChange={(e) => {
                  setAllowMCX((prev) => !prev);
                  methods.setValue('allowMCX', e.target.value === 'on');
                }}
                checked={allowMCX}
              />
              <Typography>MCX</Typography>
            </Box>
          )}
          {manager?.COMEX?.allow && (
            <Box alignItems={'center'} display={'flex'} flexDirection={'row'}>
              <Switch
                name="allowComex"
                label="Allow COMEX"
                sx={{ justifyContent: 'start', mx: 2 }}
                onChange={(e) => {
                  setAllowComex((prev) => !prev);
                  methods.setValue('allowComex', e.target.value === 'on');
                }}
                checked={allowComex}
              />
              <Typography>COMEX</Typography>
            </Box>
          )}
        </Box>

        {/* NSE Section */}
        {allowEquity && (
          <Accordion disabled={!allowEquity} expanded={allowEquity}>
            <AccordionSummary id="equity">
              <Typography fontWeight={'bold'}>Equity Config</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, minmax(0, 1fr))', md: 'repeat(2, minmax(0, 1fr))', my: 2 },
                }}
              >
                {/* Existing Fields */}
                {(userType === 'Broker' ||
                  (userType === 'Admin' && methods.getValues('profitLossType') === 'adminwise')) && (
                  <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Typography sx={{ mr: 2 }}>Equity Brokerage Type</Typography>
                      <RHFToggleButtonGroup
                        name="NSE.brokerageType"
                        label="Equity Brokerage Type"
                        color="primary"
                        defaultValue={defaultValues?.NSE?.brokerageType || 'percentage'}
                        sx={{ width: '100%' }}
                        options={[
                          { label: 'percentage', value: 'percentage' },
                          { label: 'crorebase', value: 'crorebase' },
                        ]}
                      />
                    </Box>
                    {/* <RHFTextField name="NSE.brokerageValue" label="Equity Brokerage Value" type="tel" /> */}
                  </>
                )}
                {/* Existing Fields */}
                <Box sx={{ gridColumn: { xs: 'span 1', md: 'span 1' } }}>
                  <RHFAutoComplete
                    name="NSE.blockedScripts"
                    options={uniqueScripts.filter((doc) => doc.exchange?.name === 'NSE')}
                    label="Blocked Equity Script"
                    placeholder="Select Script to Block"
                    value={methods.getValues().NSE?.blockedScripts}
                  />
                </Box>
                {(userType === 'Broker' ||
                  (userType === 'Admin' && methods.getValues('profitLossType') === 'adminwise')) && (
                  <RHFTextField name="NSE.brokerageValue" label="Equity Brokerage Value" type="tel" />
                )}
                {/* Conditionally Render Super Admin Brokerage Fields */}
                {userType === 'Broker' && profitLossType === 'brokerwise' && (
                  <>
                    <RHFTextField name="NSE.superAdminBrokerageValue" label="Super Admin Brokerage" />
                    <RHFTextField name="NSE.brokerBrokerageValue" label="Broker Brokerage" disabled />
                  </>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        {allowMCX && (
          <Accordion disabled={!allowMCX} expanded={allowMCX}>
            <AccordionSummary id="mcx">
              <Typography fontWeight={'bold'}>MCX Config</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
                }}
              >
                {(userType === 'Broker' ||
                  (userType === 'Admin' && methods.getValues('profitLossType') === 'adminwise')) && (
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography sx={{ mr: 2 }}>MCX Brokerage Type</Typography>
                    <RHFToggleButtonGroup
                      name="MCX.brokerageType"
                      label="MCX Brokerage Type"
                      defaultValue={defaultValues?.MCX?.brokerageType || 'percentage'}
                      color="primary"
                      sx={{ width: '100%' }}
                      options={[
                        { label: 'percentage', value: 'percentage' },
                        { label: 'lotwise', value: 'lotwise' },
                      ]}
                    />
                  </Box>
                )}

                {/* Blocked Scripts Field */}
                <RHFAutoComplete
                  name="MCX.blockedScripts"
                  options={uniqueScripts.filter((doc) => doc.exchange.name === 'MCX')}
                  label="Blocked MCX Script"
                  placeholder="Select Script to Block"
                  value={methods.getValues().MCX.blockedScripts}
                />

                {/* Display MCX Brokerage Value or Lotwise Fields */}
                {(userType === 'Broker' ||
                  (userType === 'Admin' && methods.getValues('profitLossType') === 'adminwise')) && (
                  <>
                    {methods.getValues().MCX?.brokerageType === 'percentage' && (
                      <RHFTextField name="MCX.brokerageValue" label="MCX Brokerage Value" type="tel" />
                    )}
                    {methods.getValues().MCX?.brokerageType === 'lotwise' && (
                      <>
                        <Box
                          sx={{
                            display: 'grid',
                            columnGap: 2,
                            rowGap: 3,
                            gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)' },
                          }}
                        >
                          <RHFTextField
                            name="MCX.brokerage.metal"
                            label="Metal Brokerage"
                            helperText={
                              methods?.formState?.errors?.MCX?.brokerage?.metal?.message || '( Gold, Silver )'
                            }
                            type="tel"
                          />
                          <RHFTextField
                            name="MCX.brokerage.miniMetal"
                            label="Mini Metal Brokerage"
                            helperText={
                              methods?.formState?.errors?.MCX?.brokerage?.miniMetal?.message ||
                              '( GoldM, SilverM, MGold, MSilver )'
                            }
                            type="tel"
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'grid',
                            columnGap: 2,
                            rowGap: 3,
                            gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)' },
                          }}
                        >
                          <RHFTextField
                            name="MCX.brokerage.baseMetal"
                            label="Base Metal Brokerage"
                            helperText={
                              methods?.formState?.errors?.MCX?.brokerage?.baseMetal?.message ||
                              '( Copper, Nickel, Lead, Zinc, Aluminium )'
                            }
                            type="tel"
                          />
                          <RHFTextField
                            name="MCX.brokerage.energy"
                            label="Energy Brokerage"
                            helperText={
                              methods?.formState?.errors?.MCX?.brokerage?.energy?.message ||
                              '( Natural Gas, Crude Oil )'
                            }
                            type="tel"
                          />
                        </Box>
                      </>
                    )}
                  </>
                )}
                {/* Super Admin Brokerage Type - Conditional Display */}
                {userType === 'Broker' && profitLossType === 'brokerwise' && (
                  <>
                    {methods.getValues().MCX?.brokerageType === 'percentage' && (
                      <>
                        <RHFTextField name="MCX.superAdmin.brokerageValue" label="Super Admin Brokerage" type="tel" />
                        <RHFTextField name="MCX.broker.brokerageValue" label="Broker Brokerage" type="tel" disabled />
                      </>
                    )}
                    {methods.getValues().MCX?.brokerageType === 'lotwise' && (
                      <Box
                        sx={{
                          display: 'grid',
                          columnGap: 2,
                          rowGap: 3,
                          gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' },
                          gridColumn: { xs: 'span 1', md: 'span 2' },
                        }}
                      >
                        <RHFTextField
                          name="MCX.superAdmin.brokerage.metal"
                          label="Super Admin Metal Brokerage"
                          helperText="( Gold, Silver )"
                          type="tel"
                        />
                        <RHFTextField
                          name="MCX.superAdmin.brokerage.miniMetal"
                          label="Super Admin Mini Metal Brokerage"
                          helperText="( GoldM, SilverM, MGold, MSilver )"
                          type="tel"
                        />
                        <RHFTextField
                          name="MCX.superAdmin.brokerage.baseMetal"
                          label="Super Admin Base Metal Brokerage"
                          helperText="( Copper, Nickel, Lead, Zinc, Aluminium )"
                          type="tel"
                        />
                        <RHFTextField
                          name="MCX.superAdmin.brokerage.energy"
                          label="Super Admin Energy Brokerage"
                          helperText="( Natural Gas, Crude Oil )"
                          type="tel"
                        />
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        {allowComex && (
          <Accordion disabled={!allowComex} expanded={allowComex}>
            <AccordionSummary id="comex">
              <Typography fontWeight={'bold'}>COMEX Config</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
                  mb: 3,
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography sx={{ mr: 2 }}>COMEX Brokerage Type</Typography>
                  <RHFToggleButtonGroup
                    name="COMEX.brokerageType"
                    label="Comex Brokerage Type"
                    defaultValue={defaultValues?.COMEX?.brokerageType || 'percentage'}
                    color="primary"
                    sx={{ width: '100%' }}
                    options={[
                      { label: 'percentage', value: 'percentage' },
                      { label: 'lotwise', value: 'lotwise' },
                    ]}
                  />
                </Box>
                <RHFAutoComplete
                  name="COMEX.blockedScripts"
                  options={uniqueScripts.filter((doc) => doc.exchange.name === 'COMEX')}
                  // options={docs.filter((doc) => doc.exchange.name === 'COMEX')}
                  label="Blocked comex Script"
                  placeholder="Select Script to Block"
                  value={methods.getValues().COMEX?.blockedScripts}
                />

                {methods.getValues().COMEX?.brokerageType === 'percentage' && (
                  <RHFTextField name="COMEX.brokerageValue" label="Comex Brokerage Value" type="tel" />
                )}
              </Box>
              {methods.getValues().COMEX?.brokerageType === 'lotwise' && (
                <>
                  <Box
                    sx={{
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(5, 1fr)' },
                    }}
                  >
                    <RHFTextField name="COMEX.brokerage.gold" label="GOLD brokerage" type="tel" />
                    <RHFTextField name="COMEX.brokerage.silver" label="Silver brokerage" type="tel" />
                    {/* </Box> */}
                    {/* <Box
                            sx={{
                              display: 'grid',
                              columnGap: 2,
                              rowGap: 3,
                              gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
                            }}
                          > */}
                    <RHFTextField name="COMEX.brokerage.copper" label="Copper Brokerage" type="tel" />
                    <RHFTextField name="COMEX.brokerage.naturalgas" label="NG Brokerage" type="tel" />
                    <RHFTextField name="COMEX.brokerage.crudeoil" label="crude Brokerage" type="tel" />
                  </Box>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    );
  } else {
    formContent = (
      <Box>
        {/* <Box sx={{ display: 'flex' }}> */}
        <RHFTextField
          disabled={isEdit}
          name="balance"
          label="Trading Capital"
          sx={{ my: 2, justifyContent: 'center', width: { xs: '100%', md: '50%' } }}
        />
        {/* </Box> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',

            // justifyContent: 'space-between',
            // flexWrap: 'wrap',
          }}
        >
          {manager?.NSE?.allow && (
            <Box alignItems={'center'} display={'flex'} flexDirection={'row'}>
              <Switch
                name="allowEquity"
                label="Equity"
                sx={{ justifyContent: 'start' }}
                onChange={(e) => {
                  setAllowEquity((prev) => !prev);
                  methods.setValue('allowEquity', e.target.value === 'on');
                }}
                checked={allowEquity}
              />
              <Typography textTransform={'uppercase'}>Equity</Typography>
            </Box>
          )}
          {manager?.MCX?.allow && (
            <Box alignItems={'center'} display={'flex'} flexDirection={'row'}>
              <Switch
                name="allowMCX"
                label="MCX"
                sx={{ justifyContent: 'start' }}
                onChange={(e) => {
                  setAllowMCX((prev) => !prev);
                  methods.setValue('allowMCX', e.target.value === 'on');
                }}
                checked={allowMCX}
              />
              <Typography textTransform={'uppercase'}>MCX</Typography>
            </Box>
          )}
          {manager?.COMEX?.allow && (
            <Box alignItems={'center'} display={'flex'} flexDirection={'row'}>
              <Switch
                name="allowComex"
                label="COMEX"
                sx={{ justifyContent: 'start' }}
                onChange={(e) => {
                  setAllowComex((prev) => !prev);
                  methods.setValue('allowComex', e.target.value === 'on');
                }}
                checked={allowComex}
              />
              <Typography textTransform={'uppercase'}>COMEX</Typography>
            </Box>
          )}
          {/* <RHFSwitch
                  name="allowMCX"
                  label="Allow MCX"
                  labelPlacement="start"
                  sx={{ justifyContent: 'start' }}
                  onClick={() => {
                    setAllowMCX((prev) => !prev);
                  }}
                /> */}
        </Box>
        {allowEquity && (
          <>
            <Accordion disabled={!allowEquity} expanded={isNSEconfigExpanded}>
              <AccordionSummary
                id="equity"
                onClick={HandleNSEConfigExpand}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {/* Typography and Icon Container */}
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    textTransform="uppercase"
                    sx={{ flexGrow: 1 }} // Ensures Typography takes up available space
                  >
                    NSE Config
                  </Typography>
                  <ExpandMore sx={{ ml: 2, fontSize: '2rem' }} /> {/* Add margin-left for spacing */}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {/* <Card sx={{ p: 3 }}> */}
                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant="h6"
                    fontWeight={theme.typography.fontWeightBold}
                    textTransform="uppercase"
                    textAlign={'center'}
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.getContrastText(theme.palette.primary.main),
                      mb: 2,
                    }}
                  >
                    Brokerage & exposure (lot wise)
                  </Typography>
                  <Box
                    sx={{
                      display: 'grid',
                      rowGap: 2,
                      columnGap: 2,
                      gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' },
                    }}
                  >
                    <RHFTextField name="NSE.brokerageValue" label="Brokerage" />
                    <RHFTextField name="NSE.intradayExposure" label="Intraday Exposure" />
                    <RHFTextField name="NSE.holdingExposure" label="Holding Exposure" />
                    <RHFTextField name="NSE.refundBrokerage" label="Refund Brokerage" />
                    <RHFTextField name="NSE.brokerageType" value="crorebase" sx={{ display: 'none' }} />
                  </Box>
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    fontWeight={theme.typography.fontWeightBold}
                    textTransform="uppercase"
                    textAlign={'center'}
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.getContrastText(theme.palette.primary.main),
                      mb: 2,
                    }}
                  >
                    Allowed shares/lots
                  </Typography>
                  <RHFSwitch name="NSE.enabledLotBaseTrading" label="Enable Lot-Based Trading" />
                  <Box
                    sx={{
                      display: 'grid',
                      rowGap: 2,
                      columnGap: 20,
                      gridTemplateColumns: 'repeat(1, 1fr)',
                      my: 1,
                      alignItems: 'start',
                    }}
                  >
                    <Box
                      sx={{ display: 'grid', rowGap: 2, columnGap: 3, gridTemplateColumns: 'repeat(1, 1fr)', mb: 2 }}
                    >
                      <Typography
                        variant="h6"
                        fontWeight={theme.typography.fontWeightBold}
                        textTransform={'uppercase'}
                        textAlign={'center'}
                        mb={2}
                      >
                        Index Settings
                      </Typography>
                      <Box
                        sx={{
                          display: 'grid',
                          rowGap: 2,
                          columnGap: 3,
                          gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' }, // 3-column layout
                          alignItems: 'start',
                        }}
                      >
                        <MinMaxInput key="nifty" value="nifty" label={'NIFTY'} index={0} />
                        <MinMaxInput key="bankNifty" value="bankNifty" label={'BANKNIFTY'} index={1} />
                        <MinMaxInput key="finNifty" value="finNifty" label={'FINNIFTY'} index={2} />
                      </Box>
                    </Box>
                    <Box sx={{ display: 'grid', rowGap: 2, columnGap: 3, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                      <Typography
                        variant="h6"
                        fontWeight={theme.typography.fontWeightBold}
                        textTransform={'uppercase'}
                        textAlign={'center'}
                        mb={2}
                      >
                        Equity Settings
                      </Typography>
                      <Box
                        sx={{
                          display: 'grid',
                          rowGap: 5,
                          columnGap: 3,
                          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr' },
                          alignItems: 'start',
                        }}
                      >
                        {EQUITY_SETTINGS_LIST.map((val, i) => {
                          let label = '';
                          if (i === 0) {
                            label = `PRICE RANGE BELOW ${val}`;
                          } else {
                            label = `PRICE RANGE ${EQUITY_SETTINGS_LIST[i - 1]}-${val}`;
                          }
                          return <MinMaxInput key={i} value={val} label={label} index={i} />;
                        })}
                        <MinMaxInput
                          key="rest"
                          value="rest"
                          label={`PRICE RANGE ABOVE ${EQUITY_SETTINGS_LIST[EQUITY_SETTINGS_LIST.length - 1]}`}
                          index={0}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </>
        )}
        {allowMCX && (
          <Accordion disabled={!allowMCX} expanded={isMCXconfigExpanded}>
            <AccordionSummary
              id="mcx"
              onClick={HandleMCXConfigExpand}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {/* Typography and Icon Container */}
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  textTransform="uppercase"
                  sx={{ flexGrow: 1 }} // Ensures Typography takes up available space
                >
                  MCX Config
                </Typography>
                <ExpandMore sx={{ ml: 2, fontSize: '2rem' }} /> {/* Add margin-left for spacing */}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Typography
                  variant="h6"
                  fontWeight={theme.typography.fontWeightBold}
                  textTransform="uppercase"
                  textAlign={'center'}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.getContrastText(theme.palette.primary.main),
                    mb: 2,
                  }}
                >
                  Brokerage & exposure (lot wise)
                </Typography>
              </Box>
              <Box
              // sx={{
              //   display: 'grid',
              //   rowGap: 2,
              //   columnGap: 2,
              //   gridTemplateColumns: { xs: '1fr ', md: '1fr 4fr' },
              //   justifyContent: 'flex-end',
              //   alignItems: 'center',
              //   // wordBreak: 'break-word',
              // }}
              >
                {/* {MCX_SETTINGS_LIST.map((obj) => (
                  <CommodityInputs
                    exchange={'MCX'}
                    commodity={obj.commodity}
                    subtitle={obj.subtitle}
                    key={obj.commodity}
                  />
                ))} */}
                <ExchangeCommodityInput exchange={'MCX'} methods={methods} />
              </Box>
              <Divider sx={{ p: 1 }} />
              <Box
                sx={{
                  display: 'grid',
                  rowGap: 2,
                  columnGap: 2,
                  gridTemplateColumns: { xs: '1fr ', md: '1fr 4fr' },
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  mt: 2,
                  // wordBreak: 'break-word',
                }}
              >
                <Typography textTransform={'uppercase'}>{'Total Lot for All Commodity'} </Typography>
                <Box
                  sx={{
                    display: 'grid',
                    rowGap: 2,
                    columnGap: 2,
                    gridTemplateColumns: { xs: 'repeat(2,1fr)', md: 'repeat(6,1fr)' },
                  }}
                >
                  <RHFTextField
                    name="MCX.maximumOpenLotAllowed"
                    label=""
                    sx={{ gridColumn: 'span 2', textAlign: 'center' }}
                  />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
        {allowComex && (
          <Accordion disabled={!allowComex} expanded={isCOMEXconfigExpanded}>
            <AccordionSummary
              id="comex"
              onClick={HandleCOMEXConfigExpand}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {/* Typography and Icon Container */}
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  textTransform="uppercase"
                  sx={{ flexGrow: 1 }} // Ensures Typography takes up available space
                >
                  COMEX Config
                </Typography>
                <ExpandMore sx={{ ml: 2, fontSize: '2rem' }} /> {/* Add margin-left for spacing */}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="h6"
                  fontWeight={theme.typography.fontWeightBold}
                  textTransform="uppercase"
                  textAlign={'center'}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.getContrastText(theme.palette.primary.main),
                    mb: 2,
                  }}
                >
                  Lot size settings
                </Typography>
                <Box
                  sx={{
                    display: 'grid',
                    rowGap: 2,
                    columnGap: 2,
                    gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' },
                  }}
                >
                  <RHFTextField name="COMEX.maximumLotforSingleTrade" label="Maximum Lot Size for Single COMEX Trade" />
                  <RHFTextField name="COMEX.maximumOpenLotPerScript" label="Maximum Open Lots per COMEX Script" />
                  <RHFTextField name="COMEX.maximumOpenLotAllowed" label="Total Lot Size for All Trades" />
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="h6"
                  fontWeight={theme.typography.fontWeightBold}
                  textTransform="uppercase"
                  textAlign={'center'}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.getContrastText(theme.palette.primary.main),
                    mb: 2,
                  }}
                >
                  Brokerage & exposure (lot wise)
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  rowGap: 2,
                  columnGap: 2,
                  gridTemplateColumns: { xs: '1fr', md: '1fr 4fr' },
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                {COMEX_SETTINGS_LIST.map((obj) => (
                  <CommodityInputs
                    key={obj.commodity}
                    commodity={obj.commodity}
                    subtitle={obj.subtitle}
                    exchange={'COMEX'}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
        {(allowEquity || allowMCX) && (
          <Accordion expanded={isTradeConfigExpanded}>
            <AccordionSummary
              id="trade-congif"
              onClick={HandleTradeSettingsConfigExpand}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Typography variant="h5" fontWeight="bold" textTransform="uppercase" sx={{ flexGrow: 1 }}>
                  Trade Settings
                </Typography>
                <ExpandMore sx={{ ml: 2, fontSize: '2rem' }} />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {allowEquity && (
                <>
                  <Typography sx={{ marginBottom: 1 }}>NSE</Typography>
                  <Box
                    sx={{
                      display: 'grid',
                      rowGap: 2,
                      columnGap: 2,
                      gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <RHFTextField name="NSE.tradeHoldTime" label="NSE Hold Time" />
                    <RHFAutoComplete
                      name="NSE.blockedScripts"
                      options={uniqueScripts.filter((doc) => doc.exchange?.name === 'NSE')}
                      label="Blocked NSE Script"
                      placeholder="Select Script to Block"
                      value={methods.getValues().NSE?.blockedScripts}
                    />
                  </Box>
                </>
              )}
              {allowMCX && (
                <>
                  <Typography sx={{ marginBottom: 1, marginTop: 2 }}>MCX</Typography>
                  <Box
                    sx={{
                      display: 'grid',
                      rowGap: 2,
                      columnGap: 2,
                      gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <RHFTextField name="MCX.tradeHoldTime" label="MCX Hold Time" />
                    <RHFAutoComplete
                      name="MCX.blockedScripts"
                      options={uniqueScripts.filter((doc) => doc.exchange.name === 'MCX')}
                      label="Blocked MCX Script"
                      placeholder="Select Script to Block"
                      value={methods.getValues().MCX.blockedScripts}
                    />
                  </Box>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    );
  }
  return formContent;
};

export default TradingSettingsForm;
