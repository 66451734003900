import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  loanList: [],
  currentPage: null,
  totalPages: null,
  totalResults: null,
  loanProfile: {},
};

const slice = createSlice({
  name: 'bankerRole',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LOAN LIST
    getLoanListSuccess(state, action) {
      state.isLoading = false;
      state.loanList = action?.payload?.clientLoanApplications;
      state.loanProfile = {};
      state.totalPage = action?.payload?.totalPages;
      state.currentPage = action?.payload?.page;
      state.totalResults = action?.payload?.totalResults;
    },

    // GET ARCHIVED PROJECTS
    getArchivedProjectsSuccess(state, action) {
      state.isLoading = false;

      state.projectListData = action?.payload?.projects;
      state.loanProfile = {};
      state.totalPage = action?.payload?.totalPages;
      state.currentPage = action?.payload?.page;
      state.totalResults = action?.payload?.totalResults;
    },

    // POST PROJECTS
    postProjectSuccess(state) {
      state.isLoading = false;
    },

    // PUT EDIT PROJECTS
    editProjectSuccess(state, action) {
      state.isLoading = false;
      const { operatorID, filterStatus, newStatus } = action.payload;
      if (filterStatus === 'all') {
        const findIndex = state.operatorData.findIndex((data) => data._id === operatorID);
        state.operatorData[findIndex].status = newStatus;
      } else {
        state.operatorData = state.operatorData.filter((data) => data._id !== operatorID);
      }
    },

    // PUT DELETE PROJECTS
    deleteProjectSuccess(state) {
      state.isLoading = false;
    },

    // PUT REPOST PROJECTS
    rePostProjectSuccess(state) {
      state.isLoading = false;
    },

    // GET PROJECT PROFILE
    getProjectProfileSuccess(state, action) {
      state.isLoading = false;
      state.projectProfile = action?.payload?.projectProfile;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const bankerRoleActions = slice.actions;

// ----------------------------------------------------------------------

// GET LOAN DETAILS
export function getLoanList(pageSize, pageNumber, statusData, order, searchWord, cancelToken) {
  return async () => {
    let response;
    try {
      if (searchWord?.length > 0) {
        response = await axios.get(
          `/banker/client-app/all?page=${pageNumber}&pageSize=${pageSize}&status=${statusData}&search=${searchWord}&order=${order}`,
          {
            cancelToken: cancelToken.token,
          }
        );
      } else {
        await dispatch(slice.actions.startLoading());
        response = await axios.get(
          `/banker/client-app/all?page=${pageNumber}&pageSize=${pageSize}&status=${statusData}&order=${order}`
        );
      }
      const { data, code, message } = response?.data;
      if (code === 200) {
        await dispatch(slice.actions.getLoanListSuccess(data));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
      if (axios.isCancel(error) || error) {
        // console.log(error);
      }
    }
  };
}

// PUT EDIT PROJECTS

export function editProject(formData) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      const response = await axios.put(`/operator/project/update`, formData);
      const { status, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.editProjectSuccess());
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}

// PUT DELETE PROJECTS

export function deleteProject(projectID) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/operator/project/delete/${projectID}`);
      const { status, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.deleteProjectSuccess());
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}

// PUT REPOST PROJECTS

export function rePostProject(projectID) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/operator/project/repost/${projectID}`);
      const { status, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.rePostProjectSuccess());
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}

// POST PROJECTS

export function postProjects(formData) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      const response = await axios.post('/operator/add-project', formData);
      const { status, message } = response?.data;
      if (status === 201) {
        await dispatch(slice.actions.postProjectSuccess());
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}

// GET PROJECT PROFILE

export function getProjectProfile(projectID) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/operator/project/${projectID}`);
      const { status, data, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.getProjectProfileSuccess(data));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}

// GET ARCHIVED PROJECTS

export function getArchivedProjects(pageSize, pageNumber, statusData, order, searchWord, cancelToken) {
  return async () => {
    let response;
    try {
      if (searchWord?.length > 0) {
        response = await axios.get(
          `/operator/project/archived?page=${pageNumber}&pageSize=${pageSize}&status=${statusData}&search=${searchWord}&order=${order}`,
          {
            cancelToken: cancelToken.token,
          }
        );
      } else {
        await dispatch(slice.actions.startLoading());
        response = await axios.get(
          `/operator/project/archived?page=${pageNumber}&pageSize=${pageSize}&status=${statusData}&order=${order}`
        );
      }
      const { data, status, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.getArchivedProjectsSuccess(data));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
      if (axios.isCancel(error) || error) {
        // console.log(error);
      }
    }
  };
}
