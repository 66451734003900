import PropTypes from 'prop-types';

// @mui
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useTheme } from '@mui/material/styles';
import { MenuItem, Box, Chip } from '@mui/material';
import { useState } from 'react';

// ----------------------------------------------------------------------

RHFMultiSelect.propTypes = {
  options: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  }),
  valueChange: PropTypes.func,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function RHFMultiSelect({ options, valueChange, ...other }) {
  const [personName, setPersonName] = useState([]);

  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    valueChange(value);
    setPersonName(value === 'string' ? value.split(',') : value);
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  return (
    <FormControl
      sx={{
        // m: 1,
        width: '100%',
      }}
    >
      <InputLabel id="demo-multiple-chip-label">Business Services</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        fullWidth
        disabled={other.disabled}
        native={false}
        value={(other?.services?.length > 0 ? other?.services : 'NA') || personName}
        // renderValue={() => (other?.services?.length > 0 && other?.services.join(', ')) || 'NA'}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {typeof selected === 'object' &&
              selected?.length > 0 &&
              selected.map((value) => <Chip key={value} label={value} />)}
          </Box>
        )}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Business Services" />}
        MenuProps={MenuProps}
      >
        {options?.length > 0 &&
          options.map((name) => (
            <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
              {name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
