// import PropTypes from 'prop-types';
// import { useState } from 'react';

// // @mui
// import {
//   Stack,
//   InputAdornment,
//   TextField,
//   //  MenuItem
// } from '@mui/material';

// // components
// import Iconify from '../../../../components/Iconify';

// // ----------------------------------------------------------------------

// UserTableToolbar.propTypes = {
//   onFilterName: PropTypes.func,
//   searchPlaceholder: PropTypes.string,
//   // filterRole: PropTypes.string,
//   // onFilterRole: PropTypes.func,
//   // optionsRole: PropTypes.arrayOf(PropTypes.string),
// };

// export default function UserTableToolbar({
//   onFilterName,
//   searchPlaceholder,
//   // filterRole,
//   // onFilterRole,
//   // optionsRole,
// }) {
//   const [name, setName] = useState();

//   return (
//     <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
//       {/* <TextField
//         fullWidth
//         select
//         label="Role"
//         value={filterRole}
//         onChange={onFilterRole}
//         SelectProps={{
//           MenuProps: {
//             sx: { '& .MuiPaper-root': { maxHeight: 260 } },
//           },
//         }}
//         sx={{
//           maxWidth: { sm: 240 },
//           textTransform: 'capitalize',
//         }}
//       >
//         {optionsRole.map((option) => (
//           <MenuItem
//             key={option}
//             value={option}
//             sx={{
//               mx: 1,
//               my: 0.5,
//               borderRadius: 0.75,
//               typography: 'body2',
//               textTransform: 'capitalize',
//             }}
//           >
//             {option}
//           </MenuItem>
//         ))}
//       </TextField> */}

//       <TextField
//         fullWidth
//         value={name}
//         onChange={(event) => {
//           onFilterName(event.target.value);
//           setName(event.target.value);
//         }}
//         placeholder={searchPlaceholder}
//         InputProps={{
//           startAdornment: (
//             <InputAdornment position="start">
//               <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
//             </InputAdornment>
//           ),
//         }}
//       />
//     </Stack>
//   );
// }

import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Stack, InputAdornment, TextField, Typography } from '@mui/material';
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

UserTableToolbar.propTypes = {
  onFilterName: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  header: PropTypes.string,
};

export default function UserTableToolbar({ onFilterName, searchPlaceholder, header }) {
  const [name, setName] = useState('');
  const [debouncedName, setDebouncedName] = useState(name);

  useEffect(() => {
    // Set a timeout to update the debouncedName after 300ms
    const handler = setTimeout(() => {
      setDebouncedName(name);
    }, 200); // 300ms debounce delay

    // Clear the timeout if name changes (prevents multiple calls)
    return () => {
      clearTimeout(handler);
    };
  }, [name]);

  // Effect to call the API when debouncedName changes
  useEffect(() => {
    onFilterName(debouncedName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedName]);

  return (
    // <Stack
    //   spacing={2}
    //   direction={{ xs: 'column', sm: 'row' }}
    //   sx={{ py: 1, px: 1, justifyContent: { xs: 'center', sm: 'flex-end' } }}
    // >
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent={header ? 'space-between' : 'flex-end'}
      sx={{ py: 1, px: 1 }}
    >
      {header && (
        <Typography variant="h4" color="primary" sx={{ flexGrow: 1 }}>
          {header}
        </Typography>
      )}
      <TextField
        value={name}
        onChange={(event) => setName(event.target.value)}
        placeholder={searchPlaceholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiInputBase-input': {
            padding: 1, // Set padding to zero
          },
        }}
      />
    </Stack>
  );
}
