import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import {
  PATH_AFTER_LOGIN,
  PATH_AFTER_LOGIN_BANKER,
  PATH_AFTER_LOGIN_INSURER,
  PATH_AFTER_LOGIN_OPERATOR,
} from '../config';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, userType } = useAuth();
  const getPathAfterLogin = () => {
    let path = '/login';
    if (userType?.isSuperAdmin) {
      path = PATH_AFTER_LOGIN;
    } else if (userType?.isAdmin) {
      path = PATH_AFTER_LOGIN_INSURER;
    } else if (userType?.isBroker) {
      path = PATH_AFTER_LOGIN_OPERATOR;
    } else if (userType?.isClient) {
      path = PATH_AFTER_LOGIN_BANKER;
    }
    return path;
  };

  if (isAuthenticated) {
    return <Navigate to={getPathAfterLogin()} />;
  }

  return <>{children}</>;
}
