import { memo } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { AppBar } from '@mui/material';
// config

// components
import { NavSectionHorizontal } from '../../../components/nav-section';
//
// import navConfig from './NavConfig';
import clientNavConfig from './ClientNavConfig';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  transition: theme.transitions.create('top', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  width: '100%',
  position: 'fixed',
  zIndex: theme.zIndex.appBar,
  // padding: theme.spacing(1, 0),
  boxShadow: theme.customShadows.z8,
  // top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  top: 'auto',
  bottom: 0,
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

function NavbarHorizontal() {
  // const [value, setValue] = useState(0);
  return (
    <RootStyle>
      {/* <Container maxWidth={false}> */}
      <NavSectionHorizontal navConfig={clientNavConfig} />
      {/* <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="Recents" />
          <BottomNavigationAction label="Favorites" />
          <BottomNavigationAction label="Nearby" />
        </BottomNavigation> */}
      {/* </Container> */}
    </RootStyle>
  );
}

export default memo(NavbarHorizontal);
