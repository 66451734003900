import { Box, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import MinMaxInput from './MinMaxInput';

const PriceConfigForm = ({ EQUITY_SETTINGS_LIST }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'grid',
        rowGap: 2,
        columnGap: 20,
        gridTemplateColumns: 'repeat(1, 1fr)',
        my: 1,
        alignItems: 'start',
      }}
    >
      <Box sx={{ display: 'grid', rowGap: 2, columnGap: 3, gridTemplateColumns: 'repeat(1, 1fr)', mb: 2 }}>
        <Typography
          variant="h6"
          fontWeight={theme.typography.fontWeightBold}
          textTransform={'uppercase'}
          textAlign={'center'}
          mb={2}
        >
          Index Settings
        </Typography>
        <Box
          sx={{
            display: 'grid',
            rowGap: 2,
            columnGap: 3,
            gridTemplateColumns: { xs: '1fr', sm: 'repeat(3, 1fr)' }, // 3-column layout
            alignItems: 'start',
          }}
        >
          <MinMaxInput key="nifty" value="nifty" label={'NIFTY'} index={0} />
          <MinMaxInput key="bankNifty" value="bankNifty" label={'BANKNIFTY'} index={1} />
          <MinMaxInput key="finNifty" value="finNifty" label={'FINNIFTY'} index={2} />
        </Box>
      </Box>
      <Box sx={{ display: 'grid', rowGap: 2, columnGap: 3, gridTemplateColumns: 'repeat(1, 1fr)' }}>
        <Typography
          variant="h6"
          fontWeight={theme.typography.fontWeightBold}
          textTransform={'uppercase'}
          textAlign={'center'}
          mb={2}
        >
          Equity Settings
        </Typography>
        <Box
          sx={{
            display: 'grid',
            rowGap: 5,
            columnGap: 3,
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr 1fr' },
            alignItems: 'start',
          }}
        >
          {EQUITY_SETTINGS_LIST.map((val, i) => {
            let label = '';
            if (i === 0) {
              label = `PRICE RANGE BELOW ${val}`;
            } else {
              label = `PRICE RANGE ${EQUITY_SETTINGS_LIST[i - 1]}-${val}`;
            }
            return <MinMaxInput key={i} value={val} label={label} index={i} />;
          })}
          <MinMaxInput
            key="rest"
            value="rest"
            label={`PRICE RANGE ABOVE ${EQUITY_SETTINGS_LIST[EQUITY_SETTINGS_LIST.length - 1]}`}
            index={0}
          />
        </Box>
      </Box>
    </Box>
  );
};

PriceConfigForm.propTypes = {
  EQUITY_SETTINGS_LIST: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PriceConfigForm;
