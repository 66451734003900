// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// redux-slice
import { dispatch } from '../../../redux/store';
import { postOperator } from '../../../redux/slices/operator';

// components
import UserCreate from '../UserCreate';

export default function BrokerNew() {
  async function addOperator(payload, isEdit, userId) {
    await dispatch(postOperator(payload, isEdit, userId));
  }
  return (
    <>
      <UserCreate
        postSubmitData={(payload, isEdit, userId) => addOperator(payload, isEdit, userId)}
        userType="Broker"
        path={PATH_DASHBOARD.insurerRole.broker.root}
      />
    </>
  );
}
