import PropTypes from 'prop-types';
import { m, AnimatePresence } from 'framer-motion';
import { useState } from 'react';

// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { List, Box, IconButton, ListItemText, ListItem, Typography, Link } from '@mui/material';

// utils
import { fData } from '../../utils/formatNumber';
import getFileData from '../../utils/getFileData';

// components
import Image from '../Image';
import Iconify from '../Iconify';
import { varFade } from '../animate';
import LightboxModal from '../LightboxModal';
import pdfImage from '../../assets/images/pdf.png';

// ----------------------------------------------------------------------

MultiFilePreview.propTypes = {
  files: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
  showPreview: PropTypes.bool,
  id: PropTypes.string,
  isEdit: PropTypes.bool,
  pageName: PropTypes.string,
};

export default function MultiFilePreview({ id, isEdit, showPreview = false, files, onRemove, pageName }) {
  const hasFile = files && files.length > 0;

  const [openLightbox, setOpenLightbox] = useState(false);

  const theme = useTheme();

  const [selectedImage, setSelectedImage] = useState(0);

  const handleOpenLightbox = (file) => {
    setOpenLightbox(true);
    setSelectedImage(file);
  };

  return (
    <List disablePadding sx={{ ...(hasFile && { my: 3, display: 'flex', flexWrap: 'wrap' }) }}>
      <AnimatePresence>
        {(typeof files === 'string' || typeof files === 'object') && pageName === 'saleskit' && (
          <ListItem
            component={m.div}
            {...varFade().inRight}
            sx={{
              p: 0,
              m: 0.5,
              width: 100,
              height: 100,
              borderRadius: 1.25,
              overflow: 'hidden',
              position: 'relative',
              display: 'inline-flex',
              border: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
          >
            <Image
              sx={{
                '&:hover': { opacity: 0.72 },
              }}
              alt="preview"
              src={typeof file === 'object' ? `http://localhost:8080/${files}` : pdfImage}
              ratio="1/1"
            />
            {/* {(!id || isEdit) && onRemove && (
              <IconButton
                size="small"
                onClick={() => onRemove(files)}
                sx={{
                  top: 6,
                  p: '2px',
                  right: 6,
                  position: 'absolute',
                  color: 'common.white',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                  '&:hover': {
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                  },
                }}
              >
                <Iconify icon={'eva:close-fill'} />
              </IconButton>
            )} */}
            <Link href={`http://localhost:8080/${files}`} underline="always" target="_blank" rel="noreferrer">
              <IconButton
                className="showActions"
                size="small"
                sx={{
                  top: '40%',
                  p: '2px',
                  right: '40%',
                  opacity: 0,
                  position: 'absolute',
                  color: 'common.white',
                  '&:hover': {
                    opacity: 0.72,
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                  },
                }}
              >
                <Iconify icon={'eva:eye-fill'} />
              </IconButton>
            </Link>
          </ListItem>
        )}
        {typeof files === 'object' &&
          pageName !== 'saleskit' &&
          files.map((file, index) => {
            const { key, size, preview } = getFileData(file, index);
            if (showPreview) {
              return (
                <>
                  <Box style={{ display: 'flex', flexDirection: 'column' }} key={key + Math.random()}>
                    <ListItem
                      component={m.div}
                      {...varFade().inRight}
                      sx={{
                        p: 0,
                        m: 0.5,
                        width: 100,
                        height: 100,
                        borderRadius: 1.25,
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-flex',
                        border: (theme) => `solid 1px ${theme.palette.divider}`,
                        '&:hover': { '& .showActions': { opacity: 1 } },
                      }}
                    >
                      <Image
                        sx={{
                          '&:hover': { opacity: 0.72 },
                        }}
                        alt="preview"
                        src={typeof file === 'object' ? preview : `http://localhost:8080/${file}`}
                        ratio="1/1"
                      />

                      {(!id || isEdit) && onRemove && (
                        <IconButton
                          size="small"
                          onClick={() => onRemove(file)}
                          sx={{
                            top: 6,
                            p: '2px',
                            right: 6,
                            position: 'absolute',
                            color: 'common.white',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                            '&:hover': {
                              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                            },
                          }}
                        >
                          <Iconify icon={'eva:close-fill'} />
                        </IconButton>
                      )}
                      <IconButton
                        className="showActions"
                        size="small"
                        onClick={() => {
                          handleOpenLightbox(file);
                        }}
                        sx={{
                          top: '40%',
                          p: '2px',
                          right: '40%',
                          opacity: 0,
                          position: 'absolute',
                          color: 'common.white',
                          '&:hover': {
                            opacity: 0.72,
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                          },
                        }}
                      >
                        <Iconify icon={'eva:eye-fill'} />
                      </IconButton>
                      <LightboxModal
                        images={[file]}
                        mainSrc={`http://localhost:8080/${selectedImage}`}
                        photoIndex={getFileData(file, index)?.name}
                        isOpen={openLightbox}
                        onCloseRequest={() => setOpenLightbox(false)}
                      />
                    </ListItem>
                    {getFileData(file, index)?.name?.includes('pdf') && (
                      <Typography variant="h7" sx={{ color: theme.palette.primary.main }}>
                        {getFileData(file, index)?.name}
                      </Typography>
                    )}
                  </Box>
                </>
              );
            }

            return (
              <>
                <ListItem
                  component={m.div}
                  {...varFade().inRight}
                  sx={{
                    my: 1,
                    px: 2,
                    py: 0.75,
                    borderRadius: 0.75,
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                  }}
                >
                  <Iconify icon={'eva:file-fill'} sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }} />

                  <ListItemText
                    primary={typeof file === 'string' ? file : getFileData(file, index)?.name}
                    secondary={typeof file === 'string' ? '' : fData(size || 0)}
                    primaryTypographyProps={{ variant: 'subtitle2' }}
                    secondaryTypographyProps={{ variant: 'caption' }}
                  />

                  {onRemove && (
                    <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                      <Iconify icon={'eva:close-fill'} />
                    </IconButton>
                  )}
                </ListItem>
                {getFileData(file, index)?.name?.includes('pdf') && (
                  <Typography>{getFileData(file, index)?.name}</Typography>
                )}
              </>
            );
          })}
      </AnimatePresence>
    </List>
  );
}
