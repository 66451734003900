import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useSnackbar } from 'notistack';
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// @mui
import {
  Box,
  Card,
  Table,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useLocation } from 'react-router';

// hooks
import useSettings from '../../../hooks/useSettings';
import useTable, { emptyRows } from '../../../hooks/useTable';

// redux-slice
import { dispatch } from '../../../redux/store';
import { getUserFiles } from '../../../redux/slices/operator';

// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../../components/table';
import TableLoader from '../../../components/table/TableLoader';

// sections
import { UserTableToolbar } from '../../../sections/@dashboard/user/list';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import UserFileListRow from './UserFileListRow';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'username', label: 'Username', align: 'left' },
  { id: 'firstname', label: 'Full Name', align: 'left' },
  { id: 's3Key', label: 'File', align: 'left' },
  { id: 'uploadedAt', label: 'Uploaded At', align: 'left' },
  { id: '', label: 'Download', align: 'center' },
];
// ----------------------------------------------------------------------

export default function UserFileList() {
  const {
    dense,
    page,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
  } = useTable({
    defaultRowsPerPage: 10,
    defaultOrderBy: 'uploadedAt',
    defaultOrder: 'desc',
  });

  const { themeStretch } = useSettings();

  // const navigate = useNavigate();

  // const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const [filterName, setFilterName] = useState('');

  const [order, setOrder] = useState('desc');

  const OperatorData = useSelector((state) => state.operator);

  // const [currentPage, setCurrentPage] = useState(1);

  const tableData = OperatorData?.userFileDetails?.docs || [];

  useEffect(() => {
    // dispatch(getUserFiles(10, 1, filterActivity, order));
    dispatch(
      getUserFiles({
        page: 1,
        pageSize: 10,
        sortBy: 'uploadedAt',
        sortOrder: 'desc',
        search: filterName,
      })
    );
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterName = (filterName) => {
    try {
      dispatch(
        getUserFiles({
          page: 1,
          pageSize: 10,
          sortBy: orderBy,
          sortOrder: order,
          search: filterName,
        })
      );
      setFilterName(filterName);
    } catch (error) {
      if (axios.isCancel(error) || error) {
        console.log(error);
      }
    }
  };

  // const handleFilterActivity = (name) => {
  //   setCurrentTab(name);
  //   dispatch(getUserFiles(rowsPerPage, 1, name, order));
  // };

  const handleDeleteRows = () => {
    setSelected([]);
  };

  // const dataFiltered = applySortFilter({
  //   tableData,
  //   comparator: getComparator(order, orderBy),
  //   filterName,
  //   filterActivity,
  //   rowsPerPage,
  //   currentPage,
  // });
  const dataFiltered = tableData;

  const denseHeight = dense ? 52 : 72;

  const isNotFound = !dataFiltered.length && !!filterName;

  return (
    <Page title="Weekly Settlement">
      <Container maxWidth={themeStretch ? false : 'lg'} style={{ padding: 0 }}>
        <HeaderBreadcrumbs
          heading="Settlement Reports"
          links={[
            { name: 'Dashboard', href: `/${location.pathname.slice(1).split('/')[0]}` },
            {
              name: 'weekly settlement',
            },
          ]}
        />
        <Card>
          <Divider />

          <UserTableToolbar
            filterName={filterName}
            onFilterName={(name) => {
              handleFilterName(name);
            }}
            searchPlaceholder="Search User"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row._id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={(id) => {
                    onSort(id);
                    dispatch(
                      getUserFiles({
                        pageSize: rowsPerPage,
                        page: 1,
                        search: filterName,
                        sortOrder: order === 'asc' ? 'desc' : 'asc',
                        sortBy: id,
                      })
                    );
                    setOrder(order === 'asc' ? 'desc' : 'asc');
                  }}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row._id)
                    )
                  }
                />

                <TableBody>
                  {dataFiltered?.length > 0 &&
                    !OperatorData?.isLoading &&
                    dataFiltered.map((row) => (
                      <UserFileListRow
                        key={row?.fileId}
                        row={row}
                        selected={selected.includes(row?._id)}
                        onSelectRow={() => onSelectRow(row?._id)}
                      />
                    ))}

                  {OperatorData?.isLoading && <TableLoader />}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                  {dataFiltered?.length === 0 && !OperatorData?.isLoading && <TableNoData isNotFound={isNotFound} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 25, 50]}
              count={OperatorData?.userFileDetails?.totalDocs || 10}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={false}
              page={OperatorData?.userFileDetails?.page - 1}
              onPageChange={(e) => {
                if (e.target.dataset.testid === 'KeyboardArrowRightIcon') {
                  // setCurrentPage(OperatorData?.activityLogs?.page + 1);
                  dispatch(
                    getUserFiles({
                      pageSize: rowsPerPage,
                      page: OperatorData?.userFileDetails?.page + 1,
                      sortBy: orderBy,
                      sortOrder: order,
                      search: filterName,
                    })
                  );
                } else {
                  // setCurrentPage(OperatorData?.activityLogs?.page - 1);
                  dispatch(
                    getUserFiles({
                      pageSize: rowsPerPage,
                      page: OperatorData?.userFileDetails?.page - 1,
                      sortBy: orderBy,
                      sortOrder: order,
                      search: filterName,
                    })
                  );
                }
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(event.target.value);
                dispatch(
                  getUserFiles({
                    pageSize: event.target.value,
                    page: 1,
                    sortBy: orderBy,
                    sortOrder: order,
                    search: filterName,
                  })
                );
              }}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
