/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import PropTypes from 'prop-types';

// @mui
import {
  Box,
  Card,
  Table,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Switch,
  Grid,
  Button,
  Menu,
  MenuItem,
  Checkbox,
  // FormControlLabel,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router';

// routes
// import { PATH_DASHBOARD } from '../../../routes/paths';

import { FilterList } from '@mui/icons-material';
// hooks
import useTabs from '../../../hooks/useTabs';
import useSettings from '../../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../../hooks/useTable';

// redux-slice
import { dispatch } from '../../../redux/store';
import { getArchivedProjects } from '../../../redux/slices/role/operatorRole';
import { getScriptWiseTrade } from '../../../redux/slices/operator';

// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../../components/table';
import TableLoader from '../../../components/table/TableLoader';

// sections
import { UserTableToolbar } from '../../../sections/@dashboard/user/list';
import useAuth from '../../../hooks/useAuth';
import ScriptWiseTradeTableRow from './ScriptWiseTradeTableRow';
import useTrade from '../../../hooks/useTrade';

// import axios from '../../../utils/axios';
// axios

// ----------------------------------------------------------------------

// const STATUS_OPTIONS = ['all', 'active', 'pending', 'rejected'];

const ROLE_OPTIONS = ['all', 'archived'];

// const TABLE_HEAD = [
//   { id: 'projectName', label: 'Project Name', align: 'left' },
//   { id: 'address', label: 'Address', align: 'left' },
//   { id: 'images', label: 'Project Images', align: 'left' },
//   { id: 'salesKit', label: 'Project Sales Kit', align: 'left' },
//   { id: 'createdDate', label: 'Created At', align: 'left' },
//   { id: 'isVerified', label: 'Verified', align: 'center' },
//   { id: 'status', label: 'Status', align: 'left' },
//   { id: '' },
// ];
const OPEN_TABLE_HEAD = [
  { id: 'script.tradingsymbol', label: 'ScriptName', align: 'left' },
  { id: 'net', label: 'Net', align: 'right' },
  { id: 'totalBuyQuantity', label: 'ActiveBuy', align: 'right' },
  { id: 'totalSellQuantity', label: 'ActiveSell', align: 'right' },
  { id: 'buy', label: 'AvgBuyRate', align: 'right' },
  { id: 'sell', label: 'AvgSellRate', align: 'right' },
  { id: 'total', label: 'total', align: 'right' },
  { id: 'last_price', label: 'CMP', align: 'right' },
  { id: 'activeProfitLoss', label: 'ActiveP/L', align: 'right' },
];
const CLOSE_TABLE_HEAD = [
  { id: 'script.tradingsymbol', label: 'ScriptName', align: 'left' },
  { id: 'grossPl', label: 'GrossP/L', align: 'right' },
  { id: 'buy', label: 'AvgBuyRate', align: 'right' },
  { id: 'sell', label: 'AvgSellRate', align: 'right' },
  { id: 'profit', label: 'BookedP/L', align: 'right' },
  { id: 'brokerage', label: 'Brokerage', align: 'right' },
  { id: 'quantity', label: 'Quantity', align: 'right' },
];

// ----------------------------------------------------------------------
ScriptWiseTradeList.propTypes = {
  type: PropTypes.string,
};
export default function ScriptWiseTradeList({ type }) {
  const {
    dense,
    page,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
  } = useTable({
    defaultRowsPerPage: 50,
    defaultOrderBy: 'script.tradingsymbol',
  });

  const { themeStretch } = useSettings();

  const { enqueueSnackbar } = useSnackbar();

  const [filterName, setFilterName] = useState('');

  const [filterRole, setFilterRole] = useState('all');

  const [order, setOrder] = useState('asc');

  const { user } = useAuth();

  const location = useLocation();

  const { userId } = useParams();

  const navigate = useNavigate();

  const { localSocket } = useTrade();

  const ClientsData = useSelector((state) => state?.operator);

  // const { clientTrades } = useSelector((state) => state?.operator);

  const [currentPage, setCurrentPage] = useState(1);

  const tableData = ClientsData?.scriptwiseTrades?.docs || [];
  // const dashboardPath = location.pathname.slice(1).split('/');

  const { currentTab: filterStatus } = useTabs('all');

  // State for menu and segements
  const [anchorElsegements, setAnchorElsegements] = useState(null);
  const [segements, setsegements] = useState({
    NSE: false,
    MCX: false,
  });

  // Function to handle opening the segment menu
  const handleSegmentMenuClick = (event) => {
    setAnchorElsegements(event.currentTarget);
  };

  // Function to handle closing the segment menu
  const handleSegmentMenuClose = () => {
    setAnchorElsegements(null);
  };

  // Function to handle checkbox changes
  const handleSegmentChange = (event) => {
    setsegements({
      ...segements,
      [event.target.name]: event.target.checked,
    });
    console.log('Selected segements:', { ...segements, [event.target.name]: event.target.checked });
  };

  const getSelectedsegements = () => Object.keys(segements).filter((key) => segements[key]);

  let currUserId = user?._id;
  if (userId) {
    currUserId = userId;
  }
  // Send active positions message through WebSocket if type is 'OPEN'

  useEffect(() => {
    dispatch(
      getScriptWiseTrade({
        userId: currUserId,
        pageSize: 50,
        pageNumber: 1,
        filterStatus,
        order,
        type,
        searchWord: filterName,
        sortBy: 'script.tradingsymbol',
        segements: getSelectedsegements(),
      })
    );

    // dispatch(getClientsTrade(10, 1, null));
  }, [dispatch, type, segements]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const sendActivePositionsMessage = (pageNumber, pageSize, sortBy, sortOrder, search, segements) => {
      if (type !== 'OPEN') return;

      const message = {
        event: 'active_positions_subscribe',
        data: {
          page: pageNumber,
          limit: pageSize,
          sortBy,
          sortOrder,
          id: currUserId,
          search,
          segements,
        },
      };

      if (localSocket && localSocket.readyState === WebSocket.OPEN) {
        localSocket.send(JSON.stringify(message));
      }
    };
    if (type === 'OPEN' && localSocket) {
      localSocket.onopen = () => {
        sendActivePositionsMessage(
          ClientsData?.scriptwiseTrades?.page || 1,
          rowsPerPage || 50,
          orderBy,
          order,
          filterName,
          segements
        );
      };
      sendActivePositionsMessage(
        ClientsData?.scriptwiseTrades?.page || 1,
        rowsPerPage || 50,
        orderBy,
        order,
        filterName,
        getSelectedsegements()
      );
    }
    return () => {
      if (localSocket && localSocket.readyState === WebSocket.OPEN) {
        localSocket.send(JSON.stringify({ event: 'active_positions_unsubscribe' }));
      }
    };
  }, [
    type,
    rowsPerPage,
    orderBy,
    order,
    localSocket,
    currUserId,
    filterName,
    ClientsData?.scriptwiseTrades?.page,
    segements,
  ]); // Runs on mount and when type or rowsPerPage chan

  const handleFilterName = (filterName) => {
    // if (cancelToken) {
    //   cancelToken.cancel();
    // }
    // cancelToken = axios.CancelToken.source();

    try {
      // if (filterRole === 'all') {
      // dispatch(getProjectList(rowsPerPage, currentPage, filterStatus, order, filterName, cancelToken));
      dispatch(
        getScriptWiseTrade({
          userId: currUserId,
          pageSize: 50,
          pageNumber: 1,
          filterStatus,
          order,
          sortBy: orderBy,
          type,
          searchWord: filterName,
          segements: getSelectedsegements(),
          // cancelToken,
        })
      );
      setFilterName(filterName);
      // } else {
      //   dispatch(getArchivedProjects(rowsPerPage, currentPage, filterStatus, order, filterName, cancelToken));
      // }
    } catch (error) {
      if (axios.isCancel(error) || error) {
        console.log(error);
      }
    }
  };

  const handleFilterRole = (name) => {
    setFilterRole(name);
    try {
      if (name === 'all') {
        // dispatch(getProjectList(10, 1, filterStatus, order));
        dispatch(
          getScriptWiseTrade({
            userId: currUserId,
            pageSize: 50,
            pageNumber: 1,
            filterStatus,
            order,
            type,
            segements: getSelectedsegements(),
          })
        );
      } else {
        dispatch(getArchivedProjects(50, 1, filterStatus, order));
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleDeleteRows = () => {
    // const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    // setTableData(deleteRows);
  };

  const handleViewRow = async (id) => {
    // console.log('ONVIEW ROW', id);
    // navigate(PATH_DASHBOARD.insurerRole.positions.grouped.view(type, id));
    navigate(`${location.pathname}/${id}`);
  };

  // const handleApproveUser = (id, type, notes, filterStatus) => {
  //   try {
  //     dispatch(putProjectList(id, type, notes, filterStatus));
  //     enqueueSnackbar('Banker Approved Successfully');
  //   } catch (error) {
  //     enqueueSnackbar(error.message);
  //   }
  // };
  // const handleRejectUser = (id, type, notes, filterStatus) => {
  //   try {
  //     dispatch(putProjectList(id, type, notes, filterStatus));
  //     enqueueSnackbar('Banker Rejected Successfully');
  //   } catch (error) {
  //     enqueueSnackbar(error.message);
  //   }
  // };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
    rowsPerPage,
    currentPage,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  return (
    <Page title="Positions">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`${type === 'OPEN' ? 'ACTIVE' : 'CLOSED'} POSITIONs`}
          links={[
            { name: 'Dashboard', href: `/${location.pathname.slice(1).split('/')[0]}` },
            {
              name: `${type === 'OPEN' ? 'Active' : 'Closed'}`,
            },
          ]}
        />

        <Card>
          {/* <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={(e, value) => {
              setCurrentTab(value);
              // setPageCurrent(1);
              // setRowsPerPage(2);
              // if (filterRole === 'all') {
              //   dispatch(getProjectList(rowsPerPage, 1, value, order));
              // } else {
              //   dispatch(getArchivedProjects(rowsPerPage, 1, value, order));
              // }
            }}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs> */}

          {/* <Divider /> */}
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Grid container spacing={2} justifyContent={'end'}>
              <Grid item xs={6} md={2}>
                <Button
                  aria-controls="segment-menu"
                  aria-haspopup="true"
                  onClick={handleSegmentMenuClick} // Open segements menu
                  startIcon={<FilterList />}
                  fullWidth
                  sx={{ padding: 2, fontSize: 16 }}
                >
                  Exchange
                </Button>
                <Menu
                  id="segment-menu"
                  anchorEl={anchorElsegements}
                  open={Boolean(anchorElsegements)}
                  onClose={handleSegmentMenuClose}
                >
                  <MenuItem>
                    <Checkbox checked={segements.NSE} onChange={handleSegmentChange} name="NSE" />
                    NSE
                  </MenuItem>
                  <MenuItem>
                    <Checkbox checked={segements.MCX} onChange={handleSegmentChange} name="MCX" />
                    MCX
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
            <UserTableToolbar
              filterName={filterName}
              filterRole={filterRole}
              onFilterName={(name) => {
                handleFilterName(name);
              }}
              onFilterRole={(name) => handleFilterRole(name)}
              optionsRole={ROLE_OPTIONS}
              searchPlaceholder="Search Scripts"
            />
          </Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  onSort={(id) => {
                    onSort(id);

                    setOrder(order === 'asc' ? 'desc' : 'asc');
                  }}
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={type === 'OPEN' ? OPEN_TABLE_HEAD : CLOSE_TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={(id) => {
                    onSort(id);
                    if (filterRole === 'all') {
                      dispatch(
                        getScriptWiseTrade({
                          userId: currUserId,
                          pageSize: rowsPerPage,
                          pageNumber: 1,
                          searchWord: filterName,
                          type,
                          order: order === 'asc' ? 'desc' : 'asc',
                          sortBy: id,
                          segements: getSelectedsegements(),
                        })
                      );
                    } else {
                      //   dispatch(
                      //     getArchivedProjects(rowsPerPage, currentPage, filterStatus, order === 'asc' ? 'desc' : 'asc')
                      //   );
                      dispatch(
                        getScriptWiseTrade({
                          userId: currUserId,
                          pageSize: rowsPerPage,
                          pageNumber: 1,
                          searchWord: filterName,
                          type,
                          order: order === 'asc' ? 'desc' : 'asc',
                          sortBy: id,
                          segements: getSelectedsegements(),
                        })
                      );
                    }
                    setOrder(order === 'asc' ? 'desc' : 'asc');
                  }}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {/* {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => ( */}
                  {ClientsData?.isLoading && <TableLoader />}
                  {!ClientsData?.isLoading &&
                    dataFiltered?.length > 0 &&
                    dataFiltered.map((row) => (
                      <ScriptWiseTradeTableRow
                        key={row?.script?._id}
                        row={row}
                        filterStatus={filterStatus}
                        filterRole={filterRole}
                        selected={selected.includes(row?._id)}
                        onSelectRow={() => onSelectRow(row?._id)}
                        type={type}
                        onViewRow={() => handleViewRow(row?.script?._id)}
                        // approveUser={() =>
                        //   handleApproveUser(row?._id, 'approve', 'You are Approved by Admin', filterStatus)
                        // }
                        // rejectUser={() =>
                        //   handleRejectUser(
                        //     row?._id,
                        //     'reject',
                        //     'Your Profile has some illegal data that is the reason to reject you',
                        //     filterStatus
                        //   )
                        // }
                      />
                    ))}

                  {/* {dataFiltered?.length === 0 && ClientsData?.isLoading && <TableLoader />} */}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                  {dataFiltered?.length === 0 && !ClientsData?.isLoading && <TableNoData isNotFound={isNotFound} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[50, 75, 100]}
              component="div"
              count={ClientsData?.scriptwiseTrades?.totalDocs || 50}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={false}
              page={ClientsData?.scriptwiseTrades?.page ? ClientsData?.scriptwiseTrades?.page - 1 : 0}
              onPageChange={(e) => {
                if (e.target.dataset.testid === 'KeyboardArrowRightIcon') {
                  setCurrentPage(ClientsData?.scriptwiseTrades?.page + 1);
                  if (filterRole === 'all') {
                    dispatch(
                      getScriptWiseTrade({
                        userId: currUserId,
                        pageSize: rowsPerPage,
                        pageNumber: ClientsData?.scriptwiseTrades?.page + 1,
                        searchWord: filterName,
                        sortBy: orderBy,
                        order,
                        type,
                        segements: getSelectedsegements(),
                      })
                    );
                  } else {
                    dispatch(
                      getScriptWiseTrade({
                        userId: currUserId,
                        pageSize: rowsPerPage,
                        pageNumber: ClientsData?.scriptwiseTrades?.page + 1,
                        searchWord: filterName,
                        sortBy: orderBy,
                        order,
                        type,
                        segements: getSelectedsegements(),
                      })
                    );
                  }
                } else {
                  setCurrentPage(ClientsData?.page - 1);
                  if (filterRole === 'all') {
                    dispatch(
                      getScriptWiseTrade({
                        userId: currUserId,
                        pageSize: rowsPerPage,
                        pageNumber: ClientsData?.scriptwiseTrades?.page - 1,
                        searchWord: filterName,
                        sortBy: orderBy,
                        order,
                        type,
                        segements: getSelectedsegements(),
                      })
                    );
                  } else {
                    dispatch(
                      getScriptWiseTrade({
                        userId: currUserId,
                        pageSize: rowsPerPage,
                        pageNumber: ClientsData?.scriptwiseTrades?.page - 1,
                        searchWord: filterName,
                        sortBy: orderBy,
                        order,
                        type,
                        segements: getSelectedsegements(),
                      })
                    );
                  }
                }
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(event.target.value);
                if (filterRole === 'all') {
                  dispatch(
                    getScriptWiseTrade({
                      userId: currUserId,
                      pageSize: event.target.value,
                      pageNumber: 1,
                      sortBy: orderBy,
                      order,
                      type,
                      segements: getSelectedsegements(),
                    })
                  );
                } else {
                  dispatch(
                    getScriptWiseTrade({
                      userId: currUserId,
                      pageSize: event.target.value,
                      pageNumber: 1,
                      filterName,
                      type,
                      segements: getSelectedsegements(),
                    })
                  );
                }
              }}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName, filterStatus }) {
  // const stabilizedThis = tableData.map((el, index) => [el, index]);

  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });

  // tableData = stabilizedThis.map((el) => el[0]);

  // if (filterName) {
  //   tableData = tableData.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  // }

  // if (filterStatus !== 'all') {
  //   tableData = tableData.filter((item) => item.status === filterStatus);
  // }

  // if (filterRole !== 'all') {
  //   tableData = tableData.filter((item) => item.role === filterRole);
  // }
  console.log('TABLE DTA:', tableData, comparator, filterName, filterStatus);
  return tableData;
}
