/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import modifyScriptName from '../../../../utils/modifyScriptName';
import { fDateTime } from '../../../../utils/formatTime';
import { fNumber } from '../../../../utils/formatNumber';

const TradesRow = ({ trade, showTime = false }) => {
  const { type, buy, sell, boughtAt, soldAt, script, profit, brokerage, side, updatedAt, boughtBy, soldBy } = trade;

  let color;
  if (['EXECUTED', 'CANCELLED'].includes(type)) {
    color = 'green';
  }
  if (['OPEN', 'ORDER', 'PENDING'].includes(type)) {
    if (side === 'BUY') {
      color = 'blue';
    } else {
      color = 'red';
    }
  }

  return (
    <Box sx={{ borderBottom: '0.5px solid grey', width: '100%', py: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        {/* First column */}
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
            <Typography variant="h5">{modifyScriptName(script.tradingsymbol)},</Typography>
            <Typography variant="h5" textTransform="capitalize" color={color}>
              {type === 'ORDER' && `${showTime ? `Close ${side === 'BUY' ? 'Sell ' : 'Buy '}` : ''}`}
              {type === 'OPEN' && ` New ${side.toLowerCase()}`}
              {['EXECUTED', 'CANCELLED'].includes(type) && `${type.toLowerCase()} ${side.toLowerCase()} `}
              {trade.quantity}
            </Typography>
          </Box>
          {type === 'ORDER' && !showTime && (
            <Typography variant="h4" color={`${profit > 0 ? 'blue' : 'red'}`} sx={{ marginLeft: 'auto' }}>
              {fNumber(profit?.toFixed(2) || 0)}
            </Typography>
          )}
        </Box>
        {type === 'ORDER' && showTime && (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid container columnGap={1} direction="column" display={'grid'} gridTemplateColumns={'1fr 3fr'}>
              <Grid item display={'grid'}>
                <Typography variant="subtitle3">BoughtAt: </Typography>
                <Typography variant="subtitle3">SoldAt:</Typography>
              </Grid>
              <Grid item display={'grid'}>
                <Typography variant="subtitle3">
                  {boughtAt ? fDateTime(boughtAt) : '-'}
                  {` (${boughtBy})`}
                </Typography>
                <Typography variant="subtitle3">
                  {soldAt ? fDateTime(soldAt) : '-'}
                  {` (${soldBy})`}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="h4" color={`${profit > 0 ? 'blue' : 'red'}`}>
              {fNumber(profit?.toFixed(2) || 0)}
            </Typography>
          </Box>
        )}
        {['EXECUTED', 'CANCELLED'].includes(type) && (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid container columnGap={2.5}>
              <Grid item>
                <Typography variant="subtitle2">{type === 'CANCELLED' ? 'CancelledAt' : 'UpdatedAt'}: </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">{fDateTime(updatedAt)}</Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {type === 'OPEN' && (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid container columnGap={2.5}>
              <Grid item>
                <Typography variant="subtitle2">{side === 'BUY' ? 'BoughtAt' : 'SoldAt'}: </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">{fDateTime(side === 'BUY' ? boughtAt : soldAt)}</Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid item xs={['EXECUTED', 'PENDING', 'CANCELLED'].includes(trade.type) ? 12 : 6}>
            {/* Buy and sell prices */}
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              {/* Buy price */}
              {buy && (
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="subtitle3">Buy:</Typography>
                  <Typography variant="subtitle3"> {fNumber(buy?.toFixed(2)) || '--'}</Typography>
                </Box>
              )}
              {sell && (
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="subtitle3">Sell:</Typography>
                  <Typography variant="subtitle3"> {fNumber(sell?.toFixed(2)) || '--'}</Typography>
                </Box>
              )}
            </Box>
          </Grid>
          {['OPEN', 'ORDER'].includes(trade.type) && (
            <Grid item xs={6}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="subtitle3">Brokerage:</Typography>
                <Typography variant="subtitle3"> {fNumber(brokerage?.toFixed(2)) || '--'}</Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default TradesRow;
