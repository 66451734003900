import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

// @mui
import {
  Box,
  // Tab,
  // Tabs,
  Card,
  Table,
  // Switch,
  // Button,
  Tooltip,
  // Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  Button,
  // FormControlLabel,
} from '@mui/material';

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// hooks
import useTabs from '../../../hooks/useTabs';
import useSettings from '../../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../../hooks/useTable';

// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../../components/table';
import TableLoader from '../../../components/table/TableLoader';

// sections
import { UserTableToolbar, UserTableRow } from '../../../sections/@dashboard/user/list';

// redux-slice
import { dispatch } from '../../../redux/store';
import { getBrokerProfile, getClientProfile, getOperator, putOperator } from '../../../redux/slices/operator';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

// const STATUS_OPTIONS = ['all', 'active', 'pending', 'banned', 'rejected'];

const ROLE_OPTIONS = [
  'all',
  'ux designer',
  'full stack designer',
  'backend developer',
  'project manager',
  'leader',
  'ui designer',
  'ui/ux designer',
  'front end developer',
  'full stack developer',
];

// const TABLE_HEAD = [
//   { id: 'name', label: 'Name', align: 'left' },
//   { id: 'organization', label: 'Organization', align: 'left' },
//   { id: 'mobileNumber', label: 'Contact Number', align: 'left' },
//   { id: 'role', label: 'Role', align: 'left' },
//   { id: 'createdDate', label: 'Created At', align: 'left' },
//   { id: 'isVerified', label: 'Verified', align: 'center' },
//   { id: 'status', label: 'Status', align: 'left' },
//   { id: '' },
// ];

// const TABLE_HEAD = [
//   { id: 'username', label: 'Username', align: 'left' },
//   { id: 'isActive', label: 'Status', align: 'left' },
//   { id: 'createdDate', label: 'Created At', align: 'left' },
// ];
const TABLE_HEAD = [
  { id: '#' },
  { id: 'username', label: 'Username', align: 'left' },
  { id: 'firstName', label: 'Full Name', align: 'left' },
  { id: 'manager.username', label: 'Manager', align: 'left' },
  // { id: 'balance', label: 'balance', align: 'left' },
  // { id: 'bookedPl', label: 'bookedPl', align: 'left' },
  // { id: 'brokerage', label: 'brokerage', align: 'left' },
  // { id: 'netPl', label: 'netPl', align: 'left' },
  // { id: 'freeMargin', label: 'Free margin', align: 'left' },
  // { id: 'isDemoAccount', label: 'Demo A/C', align: 'left' },
  { id: 'isActive', label: 'Status', align: 'left' },
  { id: 'createdAt', label: 'Created At', align: 'left' },
];

const TABLE_HEAD_ADMIN = [
  { id: '#' },
  { id: 'username', label: 'Username', align: 'left' },
  { id: 'firstName', label: 'Full Name', align: 'left' },
  { id: 'isActive', label: 'Status', align: 'left' },
  { id: 'createdAt', label: 'Created At', align: 'left' },
];

// ----------------------------------------------------------------------

export default function BrokerList() {
  const {
    dense,
    page,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
  } = useTable({
    defaultRowsPerPage: 10,
  });

  const { user } = useAuth();
  const { brokerId, clientId } = useParams();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [broker, setBroker] = useState('');
  const [client, setClient] = useState('');

  const [filterName] = useState('');

  const [filterRole, setFilterRole] = useState('all');

  const [order, setOrder] = useState('desc');

  const location = useLocation();

  const operatorData = useSelector((state) => state?.operator);

  const [currentPage, setCurrentPage] = useState(1);

  const { currentTab: filterStatus } = useTabs('all');

  const [breadcrumbList, setBreadCrumbLists] = useState([
    { name: 'Dashboard', href: PATH_DASHBOARD.root },
    {
      name: 'broker',
      href: PATH_DASHBOARD.insurerRole.broker.root,
    },
  ]);

  let cancelToken;
  const tableData = operatorData?.operatorData;

  // useEffect(() => {
  //   dispatch(getOperator(id || user?._id, 10, 1, filterStatus, order));
  //   if (id) {
  //     dispatch(getOperatorProfile(id));
  //     //   breadcrumList.push({
  //     //     name: operatorData?.operatorProfile?.manager.username,
  //     //     href: PATH_DASHBOARD.insurerRole.broker.list(operatorData?.operatorProfile?.manager._id),
  //     //   });
  //   }
  // }, [id]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchBrokerData = async () => {
      try {
        const brokerData = await dispatch(getBrokerProfile(brokerId));
        setBroker(brokerData?.user?.username);
      } catch (error) {
        console.error('Error fetching broker data:', error);
      }
    };

    const fetchClientData = async () => {
      try {
        const clientData = await dispatch(getClientProfile(clientId));
        setClient(clientData?.user?.username);
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    };

    const fetchBreadcrumbData = async () => {
      if (brokerId) {
        await fetchBrokerData();
      }
      if (clientId) {
        await fetchClientData();
      }
    };

    fetchBreadcrumbData();
    dispatch(getOperator(clientId || brokerId || user?._id, 10, 1, filterStatus, order));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, brokerId, clientId]);

  // <HeaderBreadcrumbs
  //         heading={userName ? `${userName}'s Trades` : 'Trades'}
  //         links={[
  //           { name: 'Dashboard', href: `/${location.pathname.slice(1).split('/')[0]}` }, // Base link to Dashboard
  //           ...(userId
  //             ? [{ name: user.username, href: `/${location.pathname.split('/').slice(1, -1).join('/')}` }]
  //             : []), // Conditional link based on userId
  //           { name: userName || 'Balance' }, // Final link which is userName or default to 'Balance'
  //         ]}
  //       />

  useEffect(() => {
    const updatedBreadCrumbs = [
      { name: 'Dashboard', href: `/${location.pathname.slice(1).split('/')[0]}` }, // Base link to Dashboard
      { name: 'Admin', href: PATH_DASHBOARD.insurerRole.broker.root },
    ];

    if (brokerId) {
      updatedBreadCrumbs.push({ name: broker, href: PATH_DASHBOARD.insurerRole.broker.list(brokerId) });
    }
    if (clientId) {
      updatedBreadCrumbs.push({ name: client, href: PATH_DASHBOARD.operator.listclient(brokerId, clientId) });
    }

    setBreadCrumbLists(updatedBreadCrumbs);
  }, [brokerId, clientId, broker, client, location.pathname]);

  const handleFilterName = (filterName) => {
    if (cancelToken) {
      cancelToken.cancel();
    }
    cancelToken = axios.CancelToken.source();

    try {
      dispatch(
        getOperator(brokerId || user?._id, rowsPerPage, currentPage, filterStatus, order, filterName, cancelToken)
      );
    } catch (error) {
      if (axios.isCancel(error) || error) {
        console.log(error);
      }
    }
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = () => {
    // const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    // setTableData(deleteRow);
  };

  const handleDeleteRows = () => {
    // const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    // setTableData(deleteRows);
  };

  const handleEditRow = (id) => {
    if (brokerId) {
      navigate(PATH_DASHBOARD.insurerRole.broker.editClient(paramCase(id)));
    } else {
      navigate(PATH_DASHBOARD.insurerRole.broker.editBroker(paramCase(id)));
    }
  };
  const handleClickRow = (id) => {
    if (brokerId) {
      navigate(PATH_DASHBOARD.insurerRole.broker.listclient(brokerId, id));
    } else {
      navigate(PATH_DASHBOARD.insurerRole.broker.list(id));
    }
  };

  const handleViewRow = (id) => {
    if (brokerId) {
      navigate(PATH_DASHBOARD.insurerRole.broker.listclient(brokerId, id));
    } else {
      navigate(PATH_DASHBOARD.insurerRole.broker.list(id));
    }
  };

  const handleBanUser = (id, type, notes, filterStatus) => {
    try {
      dispatch(putOperator(id, type, notes, filterStatus));
      enqueueSnackbar('Operator Banned Successfully');
    } catch (error) {
      enqueueSnackbar(error.message);
    }
  };
  const handleApproveUser = (id, type, notes, filterStatus) => {
    try {
      dispatch(putOperator(id, type, notes, filterStatus));
      enqueueSnackbar('Operator Approved Successfully');
    } catch (error) {
      enqueueSnackbar(error.message);
    }
  };
  const handleRejectUser = (id, type, notes, filterStatus) => {
    try {
      dispatch(putOperator(id, type, notes, filterStatus));
      enqueueSnackbar('Operator Rejected Successfully');
    } catch (error) {
      enqueueSnackbar(error.message);
    }
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
    rowsPerPage,
    currentPage,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  return (
    <Page title="Operator">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={brokerId ? 'Client List' : 'Broker List'}
          //   links={[
          //     { name: 'Dashboard', href: PATH_DASHBOARD.root },
          //     {
          //       name: 'broker',
          //       href: PATH_DASHBOARD.insurerRole.broker.root,
          //     },
          //   ]}
          links={breadcrumbList}
          action={
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
              <Button
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.insurerRole.broker.new}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                sx={{ my: 1, textTransform: 'uppercase' }}
              >
                Create Broker
              </Button>
              {/* <Button
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.insurerRole.broker.client.new}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                sx={{ mx: 2, my: 1, textTransform: 'uppercase' }}
              >
                Create Client
              </Button> */}
            </Box>
          }
        />

        <Card>
          {/* <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={(e, value) => {
              setCurrentTab(value);
              // setPageCurrent(1);
              // setRowsPerPage(2);
              dispatch(getOperator(brokerId || user?._id, rowsPerPage, 1, value, order));
            }}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs>

          <Divider /> */}

          <UserTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={(name) => {
              handleFilterName(name);
            }}
            onFilterRole={handleFilterRole}
            optionsRole={ROLE_OPTIONS}
            searchPlaceholder="Search Broker"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={brokerId ? TABLE_HEAD : TABLE_HEAD_ADMIN}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={(id) => {
                    onSort(id);
                    dispatch(
                      getOperator(
                        brokerId || user?._id,
                        rowsPerPage,
                        currentPage,
                        filterStatus,
                        order === 'asc' ? 'desc' : 'asc'
                      )
                    );
                    setOrder(order === 'asc' ? 'desc' : 'asc');
                  }}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {/* {dataFiltered.(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => ( */}
                  {dataFiltered?.length > 0 &&
                    dataFiltered.map((row) => (
                      <UserTableRow
                        key={row?._id}
                        showEdit
                        row={row}
                        filterStatus={filterStatus}
                        selected={selected.includes(row?._id)}
                        onSelectRow={() => onSelectRow(row?._id)}
                        onClick={() => handleClickRow(row?._id)}
                        onDeleteRow={() => handleDeleteRow(row?._id)}
                        onViewRow={() => handleViewRow(row?._id)}
                        onEditRow={() => handleEditRow(row?._id)}
                        banUser={() =>
                          handleBanUser(
                            row?._id,
                            'ban',
                            'Your Profile has some illegal data that is the reason to ban you',
                            filterStatus
                          )
                        }
                        approveUser={() =>
                          handleApproveUser(row?._id, 'approve', 'You are Approved by Admin', filterStatus)
                        }
                        rejectUser={() =>
                          handleRejectUser(
                            row?._id,
                            'reject',
                            'Your Profile has some illegal data that is the reason to reject you',
                            filterStatus
                          )
                        }
                      />
                    ))}
                  {dataFiltered?.length === 0 && operatorData?.isLoading && <TableLoader />}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                  {dataFiltered?.length === 0 && !operatorData?.isLoading && <TableNoData isNotFound={isNotFound} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={operatorData?.totalResults || 10}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={false}
              // rowsPerPageOptions={[]}
              page={operatorData?.currentPage !== null ? operatorData?.currentPage - 1 : 0}
              onPageChange={(e) => {
                if (e.target.dataset.testid === 'KeyboardArrowRightIcon') {
                  setCurrentPage(operatorData?.currentPage + 1);
                  dispatch(
                    getOperator(brokerId || user?._id, rowsPerPage, operatorData?.currentPage + 1, filterStatus, order)
                  );
                } else {
                  setCurrentPage(operatorData?.currentPage - 1);
                  dispatch(
                    getOperator(brokerId || user?._id, rowsPerPage, operatorData?.currentPage - 1, filterStatus, order)
                  );
                }
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(event.target.value);
                dispatch(getOperator(brokerId || user?._id, event.target.value, 1, filterStatus, order));
              }}
            />

            {/* <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            /> */}
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName, filterStatus, filterRole }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter((item) => item.status === filterStatus);
  }

  if (filterRole !== 'all') {
    tableData = tableData.filter((item) => item.role === filterRole);
  }

  return tableData;
}
