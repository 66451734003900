import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import forwarderReducer from './slices/forwarder';
import entriesReducer from './slices/entries';
import notificationReducer from './slices/notifications';
import InsurerReducer from './slices/insurer';
import OperatorReducer from './slices/operator';
import BankerReducer from './slices/banker';
import operatorRoleReducer from './slices/role/operatorRole';
import insurerRoleReducer from './slices/role/insurerRole';
import bankerRoleReducer from './slices/role/bankerRole';
import TradeReducer from './slices/trades';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout'],
// };
// const forwarderPersist = {
//   key: 'forwarder',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['userProfile'],
// };
// const insurerPersist = {
//   key: 'insurer',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['insurerProfile', 'clientProfile'],
// };
// const operatorPersist = {
//   key: 'operator',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['operatorProfile'],
// };
// const operatorRolePersist = {
//   key: 'operatorRole',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['projectProfile'],
// };
// const bankerPersist = {
//   key: 'banker',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['bankerProfile'],
// };

const rootReducer = combineReducers({
  mail: mailReducer,
  notification: notificationReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  entries: entriesReducer,
  operatorRole: operatorRoleReducer,
  insurerRole: insurerRoleReducer,
  bankerRole: bankerRoleReducer,
  forwarder: forwarderReducer,
  insurer: InsurerReducer,
  operator: OperatorReducer,
  banker: BankerReducer,
  product: productReducer,
  trade: TradeReducer,
});

export { rootPersistConfig, rootReducer };
