import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  contacts: { byId: {}, allIds: [] },
  conversations: { byId: {}, allIds: [] },
  allConversations: [],
  searchConversations: [],
  chatConversations: [],
  activeConversationId: null,
  participants: [],
  recipients: [],
  filterConversationData: null,
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT SSUCCESS
    getContactsSuccess(state, action) {
      const contacts = action.payload;
      state.contacts.byId = objFromArray(contacts);
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },

    // GET CONVERSATIONS SUCCESS
    getConversationsSuccess(state, action) {
      state.allConversations = action.payload;
      state.isLoading = false;
    },

    // GET CONVERSATIONS SUCCESS
    searchConversationsSuccess(state, action) {
      state.searchConversations = action.payload;
      state.isLoading = false;
    },

    // POST MESSAGE SUCCESS
    postConversationSuccess(state) {
      state.isLoading = false;
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      state.isLoading = false;
      state.chatConversations = action.payload;
      let filterData = [];
      filterData = state.allConversations.filter((object1) =>
        action.payload.some((object2) => object1.conversation === object2.conversation)
      );
      filterData[0].chatConversations = action.payload;
    },

    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const conversationData = action.payload;
      state?.chatConversations.unshift(conversationData);
    },

    // ON LAST MESSAGE
    onLastMessage(state, action) {
      const filterConversationData = state.allConversations
        .filter((data) => data?.conversation === action.payload.conversation)
        .map((data) => data);
      filterConversationData[0].msg_text = action.payload.msg_text;
      filterConversationData[0].seen = false;
      filterConversationData[0].time = new Date();
      const objectReplace = state.allConversations.map((data) =>
        action.payload.conversation === data?.conversation ? filterConversationData : data
      );
      state.filterConversationData = objectReplace;
    },

    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addRecipients, onSendMessage, onLastMessage, resetActiveConversation } = slice.actions;

// ----------------------------------------------------------------------

export function getContacts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/chat/contacts');
      dispatch(slice.actions.getContactsSuccess(response.data.contacts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversation(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/chat/conversation', {
        params: { conversationKey },
      });
      dispatch(slice.actions.getConversationSuccess(response.data.conversation));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.get('/api/chat/conversation/mark-as-seen', {
        params: { conversationId },
      });
      dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/chat/participants', {
        params: { conversationKey },
      });
      dispatch(slice.actions.getParticipantsSuccess(response.data.participants));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// GET ALL CONVERSATIONS

export function getConversations() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/conversations');
      const { status, conversations, message } = response?.data;
      if (status === 200) {
        dispatch(slice.actions.getConversationsSuccess(conversations));
      } else {
        dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// SEARCH CONVERSATIONS

export function getSearchConversations(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/conversations?search=${value}`);
      const { status, conversations, message } = response?.data;
      if (status === 200) {
        dispatch(slice.actions.searchConversationsSuccess(conversations));
      } else {
        dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// POST SEND MESSAGE

export function postSendMessage(payload) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/message', payload);
      const { status, message } = response;
      if (status === 200) {
        dispatch(slice.actions.postConversationSuccess());
      } else {
        dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// GET CHAT

export function getChat(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/chats/${conversationKey}`);
      const { status, message, chats } = response?.data;
      if (status === 200) {
        dispatch(slice.actions.getConversationSuccess(chats));
      } else {
        dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// PUT MAKE AS A SEEN

export function putMakeSeenChat(seenId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/make-as-seen/${seenId}`);
      const { status, message, chats } = response?.data;
      if (status === 200) {
        dispatch(slice.actions.getConversationSuccess(chats));
      } else {
        dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
