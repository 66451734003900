import React from 'react';
import PropTypes from 'prop-types';
import { Typography, TableRow, TableCell } from '@mui/material';

function ExchangeMargin({ config, type }) {
  // const getDetails = (category, header) => {
  //   const categoryConfig = config[category];
  //   if (!categoryConfig) return null;

  //   return (
  //     <div>
  //       <Typography variant="h6" textTransform={'uppercase'}>
  //         {header}
  //       </Typography>
  //       <Typography variant="body1">Brokerage: {categoryConfig.brokerageValue}</Typography>
  //       <Typography variant="body1">Intraday Margin: {categoryConfig.intradayExposure}</Typography>
  //       <Typography variant="body1">Holding Margin: {categoryConfig.holdingExposure}</Typography>
  //     </div>
  //   );
  // };
  const getTableRow = (category, header) => {
    const categoryConfig = config[category];
    if (!categoryConfig) return null;

    return (
      <TableRow key={category}>
        {/* <TableCell>
          <Typography variant="body1" textTransform={'uppercase'}>
            {type}
          </Typography>
        </TableCell> */}
        <TableCell>
          <Typography variant="body2" textTransform={'uppercase'}>
            {header}
          </Typography>
        </TableCell>
        <TableCell sx={{ textAlign: 'center' }}>{categoryConfig.brokerageValue}</TableCell>
        <TableCell sx={{ textAlign: 'center' }}>{categoryConfig.intradayExposure}</TableCell>
        <TableCell sx={{ textAlign: 'center' }}>{categoryConfig.holdingExposure}</TableCell>
      </TableRow>
    );
  };

  return (
    <>
      {type === 'NSE' && (
        <>
          <TableRow key="MCX" style={{ border: '1px solid black' }}>
            <TableCell rowSpan={2}>
              <Typography variant="body2" textTransform={'uppercase'} maxWidth={'20px'}>
                NSE
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={'15%'}>
              <Typography variant="body2" textTransform={'uppercase'}>
                ALL
              </Typography>
            </TableCell>
            <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{config.brokerageValue}/ 1 CR</TableCell>
            <TableCell sx={{ textAlign: 'center' }}>{config.intradayExposure}</TableCell>
            <TableCell sx={{ textAlign: 'center' }}>{config.holdingExposure}</TableCell>
          </TableRow>
        </>
      )}
      {type === 'MCX' && (
        <>
          <TableRow key="MCX" style={{ border: '1px solid black' }}>
            <TableCell rowSpan={5}>
              <Typography variant="body2" textTransform={'uppercase'}>
                MCX
              </Typography>
            </TableCell>
          </TableRow>
          {getTableRow('metal', 'Metal')}
          {getTableRow('miniMetal', 'MINI metal')}
          {getTableRow('baseMetal', 'Base Metal')}
          {getTableRow('energy', 'Energy')}
        </>
      )}
      {type === 'COMEX' && (
        <>
          <TableRow key="COMEX" style={{ border: '1px solid black' }}>
            <TableCell rowSpan={6}>
              <Typography variant="body2" textTransform={'uppercase'}>
                COMEX
              </Typography>
            </TableCell>
          </TableRow>
          {getTableRow('gold', 'Gold')}
          {getTableRow('silver', 'silver')}
          {getTableRow('copper', 'copper')}
          {getTableRow('crude', 'crude')}
          {getTableRow('NG', 'natural gas')}
        </>
      )}
    </>
  );
}

ExchangeMargin.propTypes = {
  config: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default ExchangeMargin;
