import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

// @mui
import {
  Box,
  // Tab,
  // Tabs,
  Card,
  Table,
  // Switch,
  // Button,
  Tooltip,
  // Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  // FormControlLabel,
} from '@mui/material';

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// hooks
import useTabs from '../../../hooks/useTabs';
import useSettings from '../../../hooks/useSettings';
import useTable, { emptyRows } from '../../../hooks/useTable';

// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../../components/table';
import TableLoader from '../../../components/table/TableLoader';

// sections
import { UserTableToolbar } from '../../../sections/@dashboard/user/list';

// redux-slice
import { dispatch } from '../../../redux/store';
import { getRiskyClients, getOperatorProfile } from '../../../redux/slices/operator';
import useAuth from '../../../hooks/useAuth';
import RiskUserTableRow from './RiskUserTableRow';

const TABLE_HEAD = [
  //   { id: 'select', label: '', align: 'center' }, // Checkbox for selection
  { id: 'trader.username', label: 'Trader', align: 'left' }, // Trader's username
  { id: 'broker.username', label: 'Broker', align: 'left' }, // Broker's username
  { id: 'admin.username', label: 'Admin', align: 'left' }, // Broker's username
  { id: 'balance', label: 'Balance', align: 'left' }, // Balance
  { id: 'availableBalance', label: 'Avl. Balance', align: 'left' }, // Available balance
  { id: 'activeProfitLoss', label: 'ActiveP/L', align: 'left' }, // Active profit/loss
  { id: 'totalProfit', label: 'TotalP/L', align: 'left' }, // Total profit
  { id: 'totalBrokerage', label: 'Brokerage', align: 'left' }, // Total brokerage
  { id: 'trader.config.tradeConfig.autoCloseIntraday', label: 'AutoCloseConfig', align: 'left' }, // Total brokerage
  //   { id: 'actions', label: 'Actions', align: 'right' }, // Action buttons (view, edit, delete)
];

// ----------------------------------------------------------------------

export default function RiskyClientList() {
  const {
    dense,
    page,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
  } = useTable({
    defaultRowsPerPage: 25,
    defaultOrderBy: 'createdAt',
    defaultOrder: 'desc',
  });
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [order, setOrder] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const { currentTab: filterStatus } = useTabs('all');
  const [balancePercentage, setBalancePercentage] = useState(20);

  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams();
  const operatorData = useSelector((state) => state?.operator);

  const tableData = operatorData?.riskyClients?.docs || [];
  console.log('table data', tableData);

  const handleBalanceChange = (event) => {
    const selectedPercentage = event.target.value;
    setBalancePercentage(selectedPercentage);

    // Dispatch the Redux Thunk with the selected balancePercentage
    // dispatch(getRiskyClients({
    //   pageSize: 25,
    //   pageNumber: 1,
    //   sortBy: 'createdAt',
    //   sortOrder: 'desc',
    //   balancePercentage: selectedPercentage, // Pass the selected balance percentage
    // }));
  };

  useEffect(() => {
    dispatch(
      getRiskyClients({
        userId: id,
        pageSize: 25,
        pageNumber: 1,
        filterStatus,
        sortOrder: order,
        sortBy: 'createdAt',
        searchWord: filterName,
        balancePercentage,
      })
    );
    if (id) {
      dispatch(getOperatorProfile(id, false));
      //   breadcrumList.push({
      //     name: operatorData?.operatorProfile?.manager.username,
      //     href: PATH_DASHBOARD.insurerRole.broker.list(operatorData?.operatorProfile?.manager._id),
      //   });
    }
  }, [id, balancePercentage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterName = (filterName) => {
    // if (cancelToken) {
    //   console.log('CANSSSSSSSSSSSS', cancelToken);
    //   cancelToken.cancel('CAncelling clients toekln');
    // }
    // const source = axios.CancelToken.source();
    // setCancelToken(source);
    try {
      dispatch(
        getRiskyClients({
          userId: id,
          pageSize: 25,
          pageNumber: 1,
          filterStatus,
          sortOrder: order,
          sortBy: orderBy,
          searchWord: filterName,
          balancePercentage,
        })
      );
      setFilterName(filterName);
    } catch (error) {
      if (axios.isCancel(error) || error) {
        console.log(error);
      }
    }
  };
  //   const handleUpdateUserSuccess = () => {
  //     dispatch(
  //       getRiskyClients({
  //         userId: id,
  //         pageSize: 25,
  //         pageNumber: 1,
  //         filterStatus,
  //         sortOrder: order,
  //         sortBy: orderBy,
  //         searchWord: filterName,
  //       })
  //     );
  //   };
  const handleFilterRole = (name) => {
    setFilterRole(name);
    try {
      dispatch(
        getRiskyClients({ userId: id, pageSize: 25, pageNumber: 1, filterStatus, sortOrder: orderBy, sortBy: order })
      );
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  const handleDeleteRow = () => {
    // const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    // setTableData(deleteRow);
  };

  const handleDeleteRows = () => {
    // const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    // setTableData(deleteRows);
  };

  const handleEditRow = (id) => {
    // navigate(PATH_DASHBOARD.operatorRole.clients.edit(id));
    if (user.role.rolename === 'broker') {
      navigate(PATH_DASHBOARD.operatorRole.clients.edit(id));
    }
    if (user.role.rolename === 'admin') {
      // if (location.pathname.includes('broker')) {
      //   navigate(PATH_DASHBOARD.insurerRole.broker.client.edit(id));
      // } else {
      navigate(PATH_DASHBOARD.insurerRole.client.edit(id));
      // }
    }
  };
  //   const handleDuplicateRow = (id) => {
  //     // navigate(PATH_DASHBOARD.operatorRole.clients.edit(id));
  //     if (user.role.rolename === 'broker') {
  //       navigate(PATH_DASHBOARD.operatorRole.clients.edit(id));
  //     }
  //     if (user.role.rolename === 'admin') {
  //       navigate(PATH_DASHBOARD.insurerRole.broker.client.duplicate(id));
  //     }
  //   };
  //   const handleClickRow = (id) => {
  //     navigate(PATH_DASHBOARD.operatorRole.clients.view(id));
  //   };
  const handleViewRow = (id) => {
    dispatch(getOperatorProfile(id));
    // dispatch(getProjectByID(10, 1, 'all', 'des', id));
    if (user.role.rolename === 'broker') {
      navigate(PATH_DASHBOARD.operatorRole.clients.view(id));
    }
    if (user.role.rolename === 'admin') {
      if (location.pathname.includes('broker')) {
        navigate(PATH_DASHBOARD.insurerRole.broker.client.view(id));
      } else {
        navigate(PATH_DASHBOARD.insurerRole.client.view(id));
      }
    }
  };

  const dataFiltered = tableData;

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

    const FILTERED_TABLE_HEAD = TABLE_HEAD.filter(item => {
      if (item.id === 'broker.username' && user?.role?.rolename === 'broker') {
        return false;
      }
      if (item.id === 'admin.username' && user?.role?.rolename === 'broker') {
        return false;
      }
      if (item.id === 'admin.username' && user?.role?.rolename === 'admin') {
        return false;
      }
      return true;
    });

  return (
    <Page title="Operator">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Risk Report"
          links={[
            { name: 'Dashboard', href: `/${location.pathname.slice(1).split('/')[0]}` },
            {
              name: 'client',
              href: PATH_DASHBOARD.operatorRole.clients.new,
            },
          ]}
        />

        <Card>
          {/* <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={(e, value) => {
              setCurrentTab(value);
              // setPageCurrent(1);
              // setRowsPerPage(2);
              dispatch(getOperator(id || user?._id, rowsPerPage, 1, value, order));
            }}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs>

          <Divider /> */}
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Select
              id="balance-percentage-select"
              value={balancePercentage}
              onChange={handleBalanceChange}
              displayEmpty
              sx={{
                padding: '2px', // Add padding
                marginRight: 1, // Add some margin on the right
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center', // Center the content vertically
                  padding: '2px', // Ensure padding inside the select to maintain consistent height
                },
              }}
            >
              {/* Define your percentage options here */}
              <MenuItem value={5}>5%</MenuItem>
              <MenuItem value={10}>10%</MenuItem>
              <MenuItem value={20}>20%</MenuItem>
              <MenuItem value={30}>30%</MenuItem>
              <MenuItem value={40}>40%</MenuItem>
              <MenuItem value={50}>50%</MenuItem>
              {/* <MenuItem value={60}>60%</MenuItem>
              <MenuItem value={70}>70%</MenuItem>
              <MenuItem value={80}>80%</MenuItem>
              <MenuItem value={90}>90%</MenuItem>
              <MenuItem value={100}>100%</MenuItem> */}
            </Select>
            <UserTableToolbar
              filterName={filterName}
              filterRole={filterRole}
              onFilterName={(name) => {
                handleFilterName(name);
              }}
              onFilterRole={handleFilterRole}
              searchPlaceholder="Search Client"
            />
          </Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={FILTERED_TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={(col) => {
                    onSort(col);
                    dispatch(
                      getRiskyClients({
                        userId: id,
                        pageSize: rowsPerPage,
                        pageNumber: 1,
                        searchWord: filterName,
                        filterName,
                        sortOrder: order === 'asc' ? 'desc' : 'asc',
                        sortBy: col,
                        balancePercentage,
                      })
                    );
                    setOrder(order === 'asc' ? 'desc' : 'asc');
                  }}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {/* {dataFiltered.(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => ( */}
                  {dataFiltered?.length > 0 &&
                    !operatorData?.isLoading &&
                    dataFiltered.map((row) => (
                      <RiskUserTableRow
                        key={row.id}
                        row={row}
                        selected={selected.indexOf(row.id) !== -1}
                        onViewRow={handleViewRow}
                        onEditRow={handleEditRow}
                        onDeleteRow={handleDeleteRow}
                        onSelectRow={onSelectRow}
                      />
                    ))}
                  {operatorData?.isLoading && <TableLoader />}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                  {dataFiltered?.length === 0 && !operatorData?.isLoading && <TableNoData isNotFound={isNotFound} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 75]}
              component="div"
              count={operatorData?.riskyClients?.totalResults || 25}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={false}
              page={operatorData?.riskyClients?.page !== null ? operatorData?.riskyClients?.page - 1 : 0}
              onPageChange={(e) => {
                if (e.target.dataset.testid === 'KeyboardArrowRightIcon') {
                  setCurrentPage(operatorData?.currentPage + 1);
                  dispatch(
                    getRiskyClients({
                      userId: id,
                      pageSize: rowsPerPage,
                      pageNumber: +operatorData?.currentPage + 1,
                      searchWord: filterName,
                      filterName,
                      sortOrder: order,
                      sortBy: orderBy,
                      balancePercentage,
                    })
                  );
                } else {
                  if (currentPage === 1) return;
                  setCurrentPage(operatorData?.riskyClients?.page - 1);
                  dispatch(
                    getRiskyClients({
                      userId: id,
                      pageSize: rowsPerPage,
                      pageNumber: +operatorData?.riskyClients?.page - 1,
                      searchWord: filterName,
                      filterName,
                      sortOrder: order,
                      sortBy: orderBy,
                      balancePercentage,
                    })
                  );
                }
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setCurrentPage(1);
                dispatch(
                  getRiskyClients({
                    userId: id,
                    pageSize: parseInt(event.target.value, 10),
                    pageNumber: 1,
                    searchWord: filterName,
                    filterName,
                    sortOrder: order,
                    sortBy: orderBy,
                    balancePercentage,
                  })
                );
              }}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
