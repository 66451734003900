import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  notificationsSeen: [],
  notificationsUnSeen: [],
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TEAM MEMBER DETAILS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
      const notifications = action.payload;
      state.notificationsSeen = notifications.filter((data) => data?.seen === true).map((data) => data);
      state.notificationsUnSeen = notifications.filter((data) => data?.seen === false).map((data) => data);
    },

    // POST NOTIFICATION MEMBER
    postNotificationSuccess(state) {
      state.isLoading = false;
    },

    // PUT NOTIFICATION SUCCESS
    putNotificationSuccess(state) {
      state.isLoading = false;
    },

    // CLEAR NOTIFICATION SUCCESS
    delNotificationSuccess(state) {
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const notificationActions = slice.actions;

// ----------------------------------------------------------------------

// GET NOTIFICATIONS

export async function getNotifications() {
  // return async () => {
  await dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(`/notification/`);
    const { status, message, notifications } = response?.data;
    if (status === 200) {
      await dispatch(slice.actions.getNotificationsSuccess(notifications));
    } else {
      dispatch(slice.actions.hasError(message));
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
  // };
}

// POST NOTIFICATIONS

export async function postNotifications(postData) {
  // return async () => {
  await dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`/notification/`, postData);
    const { status, message } = response?.data;
    if (status === 201) {
      await dispatch(slice.actions.postNotificationSuccess());
    } else {
      dispatch(slice.actions.hasError(message));
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
  // };
}

// PUT NOTIFICATIONS

export async function putNotification() {
  // return async () => {
  await dispatch(slice.actions.startLoading());
  try {
    const response = await axios.put(`/notification/make-as-seen`);
    const { status, message } = response?.data;
    if (status === 200) {
      await getNotifications();
      await dispatch(slice.actions.putNotificationSuccess());
    } else {
      dispatch(slice.actions.hasError(message));
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
  // };
}

// CLEAR NOTIFICATIONS

export async function clearNotifications() {
  // return async () => {
  await dispatch(slice.actions.startLoading());
  try {
    const response = await axios.delete(`/notification/clear`);
    const { status, message } = response?.data;
    if (status === 200) {
      await dispatch(slice.actions.delNotificationSuccess());
    } else {
      dispatch(slice.actions.hasError(message));
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
  // };
}
