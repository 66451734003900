import React from 'react';
import { Typography, Box, IconButton, Grid, Button } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
// import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import axiosInstance from '../../../utils/axios';
import { Wrapper } from '../../../sections/@dashboard/Trade/watchlist/BuyScriptDrawer';

const ExecuteLimitModal = ({ trade, isOpen, onClose, onUpdateSuccess }) => {
  //   const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteTrade = async () => {
    try {
      const response = await axiosInstance.put(`/trade/execute-limit/${trade._id}`);
      enqueueSnackbar(response.data.message, { variant: 'success' });
      onUpdateSuccess();
      onClose();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <Wrapper
      open={isOpen}
      onClose={onClose}
      children={
        <Box width={{ xs: '100%', sm: '350px' }} marginX={'auto'}>
          <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" textAlign="center" pb={2}>
            Execute Limit
          </Typography>
          <Typography variant="body1" textAlign="center" pb={2}>
            Are you sure you want to execute this limit?
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <Button variant="contained" fullWidth size="large" onClick={handleDeleteTrade} color="primary">
                Yes
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="outlined" fullWidth size="large" onClick={onClose}>
                No
              </Button>
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
};

ExecuteLimitModal.propTypes = {
  trade: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateSuccess: PropTypes.func,
};

export default ExecuteLimitModal;
