import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Container, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
import axios from '../../../../utils/axios';
import useAuth from '../../../../hooks/useAuth';
import PriceConfigForm from '../UserCreation/personalDetails/PriceConfigForm';
import Page from '../../../../components/Page';
import useSettings from '../../../../hooks/useSettings';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';

const numberValidator = (message) => Yup.number().typeError('Must be a number').required(message);

const EQUITY_SETTINGS_LIST = [
  '500',
  '1000',
  '1500',
  '2000',
  '3000',
  '4000',
  '6000',
  '8000',
  '10000',
  '15000',
  '20000',
  '25000',
  '30000',
];

const equityLotValidator = Yup.object().shape({
  min: numberValidator('Min is required'),
  max: numberValidator('Max is required').test('max', 'Max must be greater than Min', function compareMinMax(value) {
    const { min } = this.parent;
    return value >= min;
  }),
  perTradeLimit: numberValidator('Max Per Trade is required'),
});

const validationSchema = Yup.object().shape({
  NSE: Yup.object().shape({
    shareConfig: Yup.object().shape({
      nifty: equityLotValidator,
      bankNifty: equityLotValidator,
      finNifty: equityLotValidator,
      ...EQUITY_SETTINGS_LIST.reduce((acc, val) => {
        acc[val] = equityLotValidator;
        return acc;
      }, {}),
      rest: equityLotValidator,
    }),
  }),
});

const EquityLotSetting = () => {
  const { user, initialize } = useAuth();
  const { themeStretch } = useSettings();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(() => {
    const defaultValuesObj = {
      NSE: {
        shareConfig: {
          nifty: user?.NSE?.shareConfig?.nifty || { min: 0, max: 0, perTradeLimit: 0 },
          bankNifty: user?.NSE?.shareConfig?.bankNifty || { min: 0, max: 0, perTradeLimit: 0 },
          finNifty: user?.NSE?.shareConfig?.finNifty || { min: 0, max: 0, perTradeLimit: 0 },
          rest: user?.NSE?.shareConfig?.rest || { min: 0, max: 0, perTradeLimit: 0 },
        },
      },
    };

    EQUITY_SETTINGS_LIST.forEach((val) => {
      defaultValuesObj.NSE.shareConfig[val] = user?.NSE?.shareConfig?.[val] || {
        min: 0,
        max: 0,
        perTradeLimit: 0,
      };
    });

    return defaultValuesObj;
  }, [user]);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
  });

  const onSubmit = async (data) => {
    try {
      const response = await axios.put(`/user/update-default-equity-lot`, data);
      await initialize();
      enqueueSnackbar(response.data.message);
    } catch (error) {
      console.error('Error updating config:', error);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <Page title="Equity lot settings">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={'Equity Lot Settings'}
          links={[
            { name: 'Dashboard', href: `/${location.pathname.slice(1).split('/')[0]}` },
            { name: 'Lot Settings' },
          ]}
        />
        <Card sx={{ px: 1, py: 2 }}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <PriceConfigForm EQUITY_SETTINGS_LIST={EQUITY_SETTINGS_LIST} />
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={methods.formState.isSubmitting}
                >
                  Update
                </LoadingButton>
              </Box>
            </form>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
};

export default EquityLotSetting;
