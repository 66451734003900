import * as Yup from 'yup';

const generalSettingsValidator = (role, userType, manager) => {
  console.log('manager', manager);
  let schema = Yup.object().shape({
    isActive: Yup.boolean().required('Account status is required'),
  });

  if (userType !== 'Client') {
    schema = schema.shape({
      profitLossShare: Yup.number().when('profitLossType', {
        is: 'adminwise',
        then: Yup.number()
          .required('Profit/Loss share is required')
          .typeError('Profit/Loss share must be a number')
          .min(0, 'Value must be at least 0')
          .max(100, 'Value must be at most 100'),
      }),
      tradeConfig: Yup.object().shape({
        allowPendingTrade: Yup.boolean().required('Allow pending trades is required'),
      }),
    });

    if (userType === 'Broker') {
      if (manager.profitLossType === 'brokerwise') {
        schema = schema
          .shape({
            superAdminProfitLossShare: Yup.number()
              .required('Super Admin Brokerage is required')
              .typeError('Super Admin Brokerage must be a number')
              .min(0, 'Value must be at least 0')
              .max(100, 'Value must be at most 100'),
            brokerProfitLossShare: Yup.number()
              .required('Broker Brokerage is required')
              .typeError('Broker Brokerage must be a number')
              .min(0, 'Value must be at least 0')
              .max(100, 'Value must be at most 100'),
            profitLossShare: Yup.number()
              .required('Profit/Loss share is required')
              .typeError('Profit/Loss share must be a number')
              .min(0, 'Value must be at least 0')
              .max(100, 'Value must be at most 100'),
          })
          .test(
            'sum-check',
            'The sum of Profit/Loss Share, Super Admin Brokerage, and Broker Brokerage must equal 100',
            (values) => {
              const { profitLossShare, superAdminProfitLossShare, brokerProfitLossShare } = values;
              const total = (profitLossShare || 0) + (superAdminProfitLossShare || 0) + (brokerProfitLossShare || 0);
              return total === 100;
            }
          );
      }
    }
  } else {
    schema = schema.shape({
      tradeConfig: Yup.object().shape({
        closeExistingPosition: Yup.boolean().required('Close Existing Position is required'),
        allowOrderAtLtp: Yup.boolean().required('Allow Order At LTP is required'),
        allowFreshOrder: Yup.boolean().required('Allow Fresh Entry Order Above High & Below Low is required'),
        allowOrderBetweenHighLow: Yup.boolean().required('Allow Order Between High & Low is required'),
        allowOrderBothSide: Yup.boolean().required('Allow Pending Order Both Sides(Buy/Sell) is required'),
        autoCloseIntraday: Yup.boolean().required('Intraday Auto-Close at Configured Limit is required'),
        autoCloseOvernight: Yup.boolean().required('Overnight Auto-Close at Configured Limit is required'),
        autoCloseLossPercentage: Yup.number().when(['autoCloseIntraday', 'autoCloseOvernight'], {
          is: (autoCloseIntraday, autoCloseOvernight) => autoCloseIntraday || autoCloseOvernight,
          then: Yup.number()
            .required('Auto Close Loss Percentage is required when either Intraday or Overnight Auto-Close is enabled')
            .typeError('Auto Close Loss Percentage must be a number')
            .min(0, 'Value must be at least 0')
            .max(100, 'Value must be at most 100'),
        }),
      }),
    });
  }

  return schema;
};

export default generalSettingsValidator;
