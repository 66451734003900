/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Box,
  Card,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
} from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// // hooks
// import useAuth from '../../../../hooks/useAuth';
// // utils
// import { fData } from '../../../../utils/formatNumber';
// // _mock
// import { countries } from '../../../../_mock';
// // components
// import {
//   FormProvider,
//   // RHFSwitch,
//   RHFSelect,
//   RHFTextField,
//   RHFUploadAvatar,
// } from '../../../../components/hook-form';

import ExchangeMargin from './ExchangeMargin';
import useResponsive from '../../../../hooks/useResponsive';

// ----------------------------------------------------------------------

export default function AccountConfig({ user }) {
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');

  const UpdateUserSchema = Yup.object().shape({
    displayName: Yup.string().required('Name is required'),
  });
  const defaultValues = {
    displayName: 'Mosam Gor' || '',
    email: 'mosam@gor.com' || '',
    phoneNumber: '8785002234' || '',
    country: 'India' || '',
    address: 'Isanpur' || '',
    state: 'Gujarat' || '',
    city: 'Ahmedabad' || '',
    zipCode: '380025' || '',
    about: '' || '',
    // isPublic: admin?.isPublic || false,
    // displayName: admin?.displayName || '',
    // email: admin?.email || '',
    // photoURL: admin?.photoURL || '',
    // phoneNumber: admin?.phoneNumber || '',
    // country: admin?.country || '',
    // address: admin?.address || '',
    // state: admin?.state || '',
    // city: admin?.city || '',
    // zipCode: admin?.zipCode || '',
    // about: admin?.about || '',
    // isPublic: admin?.isPublic || false,
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
    }
  };
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'photoURL',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <>
      <Card sx={{ p: 3 }}>
        <Box
        // sx={{
        //   display: 'grid',
        //   rowGap: 3,
        //   columnGap: 2,
        //   gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
        // }}
        >
          {/* <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}> */}
          <TableContainer component={Paper} sx={{ overflowX: 'auto', width: '100%' }}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ textTransform: 'uppercase' }}>
                  <TableCell
                    sx={{
                      backgroundColor: 'transparent',
                      ...theme.typography.body1,
                      fontWeight: theme.typography.fontWeightBold,
                    }}
                  >
                    {isMobile ? 'ex' : 'exchange'}
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: 'transparent',
                      ...theme.typography.body1,
                      fontWeight: theme.typography.fontWeightBold,
                    }}
                  >
                    {isMobile ? 'co' : 'commodity'}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      backgroundColor: 'transparent',
                      ...theme.typography.body1,
                      fontWeight: theme.typography.fontWeightBold,
                    }}
                  >
                    {isMobile ? 'br' : 'brokerage'}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      backgroundColor: 'transparent',
                      ...theme.typography.body1,
                      fontWeight: theme.typography.fontWeightBold,
                    }}
                  >
                    {isMobile ? 'IM' : 'Intraday Margin'}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      backgroundColor: 'transparent',
                      ...theme.typography.body1,
                      fontWeight: theme.typography.fontWeightBold,
                    }}
                  >
                    {isMobile ? 'HM' : 'Holding Margin'}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user?.NSE.allow && (
                  <ExchangeMargin type="NSE" config={user?.NSE} enabled={user?.NSE?.allow || false} />
                )}
                {user?.MCX.allow && (
                  <ExchangeMargin type="MCX" config={user?.MCX} enabled={user?.MCX?.allow || false} />
                )}
                {user?.COMEX.allow && (
                  <ExchangeMargin type="COMEX" config={user?.COMEX} enabled={user?.COMEX?.allow || false} />
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* </Paper> */}
          {/* <RHFTextField name="displayName" label="Name" />
          <RHFTextField name="email" label="Email Address" />

          <RHFTextField name="phoneNumber" label="Phone Number" />
          <RHFTextField name="address" label="Address" />

          <RHFSelect name="country" label="Country" placeholder="Country">
            <option value="" />
            {countries.map((option) => (
              <option key={option.code} value={option.label}>
                {option.label}
              </option>
            ))}
          </RHFSelect>

          <RHFTextField name="state" label="State/Region" />

          <RHFTextField name="city" label="City" />
          <RHFTextField name="zipCode" label="Zip/Code" /> */}
        </Box>
        {/* <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
          <RHFTextField name="about" multiline rows={4} label="About" />
          
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          Save Changes
          </LoadingButton>
        </Stack> */}
      </Card>
      {isMobile && (
        <Typography variant="caption" my={4}>
          Ex-Exchange || CO-Commodity || BR-Brokerage || IM-Intraday Margin || HM-Holding Margin
        </Typography>
      )}
    </>
  );
}
