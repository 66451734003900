/* eslint-disable react/prop-types */
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  ListItemSecondaryAction,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Delete, DragIndicator as DragIcon, DragIndicator } from '@mui/icons-material';
import useResponsive from '../../../../hooks/useResponsive';
import { dispatch } from '../../../../redux/store';
import { addToWatchListSuccess, getWatchlistSuccess } from '../../../../redux/slices/trades';
import modifyScriptName from '../../../../utils/modifyScriptName';

// Styled table components
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: 'none',
  },
}));

const WatchListItem = ({ showDelete, filteredItems, selectedTab }) => {
  const isMobile = useResponsive('down', 'sm');
  const watchlist = useSelector((state) => state?.trade?.watchlist);
  const userTrades = useSelector((state) => state?.trade?.userTrades);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const items = [...watchlist[selectedTab]];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedWatchlist = { ...watchlist, [selectedTab]: items };
    localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
    dispatch(getWatchlistSuccess(updatedWatchlist));
  };

  const handleDeleteItem = (itemId) => {
    const updatedWatchlist = { ...watchlist };
    updatedWatchlist[selectedTab] = updatedWatchlist[selectedTab].filter((item) => item._id !== itemId);
    localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
    dispatch(addToWatchListSuccess(updatedWatchlist));
  };

  // Filter `userTrades.OPEN` by selectedTab
  const openTradesForSelectedTab =
    userTrades?.OPEN?.filter((trade) => trade.script.exchange.name === selectedTab) || [];
  const openTradesSymbols = openTradesForSelectedTab.map((trade) => trade.script.tradingsymbol);

  return (
    <Grid item xs={12}>
      {isMobile ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="watchlist">
            {(provided) => (
              <Grid item xs={12}>
                <List dense {...provided.droppableProps} ref={provided.innerRef}>
                  {filteredItems.map((item, index) => (
                    <Draggable key={item._id} draggableId={item._id} index={index}>
                      {(provided) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          sx={{ borderBottom: '1px solid', top: 'auto !important', left: 'auto !important' }}
                        >
                          <Box
                            sx={{
                              width: '100%',
                              display: 'grid',
                              gridTemplateColumns: '48px 1fr',
                            }}
                            {...provided.dragHandleProps}
                          >
                            <Box
                              sx={{
                                width: '100%',
                                height: '100%',
                                cursor: 'grab',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <DragIndicator />
                            </Box>
                            <Box>
                              <ListItemText
                                sx={{ p: 2 }}
                                primary={<Typography variant="h6">{modifyScriptName(item.tradingsymbol)}</Typography>}
                              />
                            </Box>
                            {showDelete && !openTradesSymbols.includes(item.tradingsymbol) && (
                              <ListItemSecondaryAction>
                                <IconButton
                                  aria-label="delete"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteItem(item._id);
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              </ListItemSecondaryAction>
                            )}
                          </Box>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            {filteredItems.length > 0 && (
              <TableHead sx={{ textTransform: 'uppercase' }}>
                <StyledTableRow>
                  <StyledTableCell />
                  <StyledTableCell>Trading Symbol</StyledTableCell>
                  <StyledTableCell>Open</StyledTableCell>
                  <StyledTableCell>Low</StyledTableCell>
                  <StyledTableCell>Bid</StyledTableCell>
                  <StyledTableCell>Ask</StyledTableCell>
                  {showDelete && <StyledTableCell>Delete</StyledTableCell>}
                </StyledTableRow>
              </TableHead>
            )}
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="watchlist">
                {(provided) => (
                  <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                    {filteredItems.map((item, index) => (
                      <Draggable key={item._id} draggableId={item._id} index={index}>
                        {(provided) => (
                          <StyledTableRow ref={provided.innerRef} {...provided.draggableProps}>
                            <TableCell>
                              <Box
                                {...provided.dragHandleProps}
                                sx={{
                                  width: '100%',
                                  height: '100%',
                                  cursor: 'grab',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <DragIcon />
                              </Box>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {item.tradingsymbol}
                            </TableCell>
                            <TableCell>{item.open || 0}</TableCell>
                            <TableCell>{item.low || 0}</TableCell>
                            <TableCell>{item.bid || 0}</TableCell>
                            <TableCell>{item.ask || 0}</TableCell>
                            {showDelete && !openTradesSymbols.includes(item.tradingsymbol) && (
                              <TableCell>
                                <IconButton
                                  aria-label="delete"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteItem(item._id);
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            )}
                          </StyledTableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};

export default WatchListItem;
