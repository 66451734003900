import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { RHFAutoSelect, RHFTextField, RHFSelect } from '../../../components/hook-form/index';
import { getInstrumentList } from '../../../redux/slices/trades';
import axios from '../../../utils/axios';
import modifyScriptName from '../../../utils/modifyScriptName';

const sideOptions = [
  { label: 'BUY', value: 'BUY' },
  { label: 'SELL', value: 'SELL' },
];

// Validation schema using yup
const schema = yup.object().shape({
  script: yup.object().typeError('Script Selection is Required').required('Script selection is required'),
  side: yup.string().required('Side is required'),
  quantity: yup
    .number()
    .typeError('Quantity is required')
    .required('Quantity is required')
    .min(1, 'Quantity must be at least 1'),
  buyPrice: yup
    .number()
    .typeError('Buy Price is required')
    .required('Buy Price is required')
    .min(0.01, 'Buy Price must be at least 0.01'),
  sellPrice: yup
    .number()
    .typeError('Sell Price is required')
    .required('Sell Price is required')
    .min(0.01, 'Sell Price must be at least 0.01'),
});
const AddManualCloseTrade = ({ open, client, onClose }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  // Fetch instrument data
  const { instrumentData } = useSelector((state) => state.trade);

  useEffect(() => {
    dispatch(getInstrumentList());
  }, [dispatch]);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      script: null, // Initially no script is selected
      side: '',
      quantity: '',
      buyPrice: '',
      sellPrice: '',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  // State to manage the warning popup visibility and messages
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessages, setWarningMessages] = useState([]);

  // Reset form fields when modal closes
  useEffect(() => {
    if (!open) {
      reset({
        script: null,
        side: '',
        quantity: '',
        buyPrice: '',
        sellPrice: '',
      });
    }
  }, [open, reset]);

  // Handle the submission of the form
  const onSubmit = async (data) => {
    const { script, side, quantity, buyPrice, sellPrice } = data;
    try {
      const res = await axios.post('/trade/manual/closed/add', {
        scriptId: script._id,
        side,
        buy: buyPrice,
        sell: sellPrice,
        quantity,
        userId: client._id,
      });
      // Check for a warning in the response
      if (res.data?.error?.hasWarning) {
        setWarningMessages(res.data.error.warnings);
        setShowWarning(true);
      }
      enqueueSnackbar(res.data.message, { variant: 'success' });
      reset();
      // onUserUpdate();
      // onClose();
    } catch (error) {
      if (error?.hasWarning) {
        setWarningMessages(error.warnings);
        setShowWarning(true);
      }
      enqueueSnackbar(`Failed to add trade: ${error.message}`, { variant: 'error' });
    }
  };

  // Handle the confirmation of the warning
  const handleWarningConfirm = async () => {
    const { script, side, quantity, buyPrice, sellPrice } = methods.getValues();
    try {
      setLoading(true);
      const res = await axios.post('/trade/manual/add', {
        scriptId: script._id,
        side,
        buyPrice,
        sellPrice,
        quantity,
        userId: client?._id,
      });
      enqueueSnackbar(res.data.message, { variant: 'success' });
      setShowWarning(false);
      setLoading(false);
      reset();
      // onUserUpdate();
      // onClose();
    } catch (error) {
      enqueueSnackbar(`Failed to add trade: ${error.message}`, { variant: 'error' });
      setLoading(false);
    }
  };

  // Handle cancellation of the warning popup
  const handleWarningCancel = () => {
    setShowWarning(false);
  };

  // Extract and filter instrument data
  const instrumentDocs = instrumentData?.docs || [];

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" textAlign="center" pb={2}>
            Manual Close Trade
          </Typography>

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}>
              <CircularProgress />
            </Box>
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <RHFTextField name="client" label="Client" value={client?.username || ''} disabled fullWidth />
                  </Grid>
                  <Grid item xs={6}>
                    <RHFAutoSelect
                      name="script"
                      control={methods.control}
                      options={instrumentDocs}
                      getOptionLabel={(option) => modifyScriptName(option.tradingsymbol) || ''}
                      label="Script"
                      placeholder="Select Script"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RHFSelect name="side" label="Side" fullWidth>
                      <option value="" />
                      {sideOptions.map((side) => (
                        <option key={side.value} value={side.value}>
                          {side.label}
                        </option>
                      ))}
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={6}>
                    <RHFTextField name="quantity" label="Quantity" type="number" fullWidth />
                  </Grid>
                  <Grid item xs={6}>
                    <RHFTextField name="buyPrice" label="Buy Price" type="number" fullWidth />
                  </Grid>
                  <Grid item xs={6}>
                    <RHFTextField name="sellPrice" label="Sell Price" type="number" fullWidth />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      size="large"
                      sx={{ textTransform: 'uppercase' }}
                      loading={isSubmitting}
                    >
                      Execute Order
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          )}
        </Box>
      </Modal>

      <Dialog open={showWarning}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          {warningMessages.map((message, index) => (
            <Typography key={index} sx={{ mb: 1 }}>
              {message}
            </Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWarningCancel} color="secondary">
            Cancel
          </Button>
          <LoadingButton onClick={handleWarningConfirm} color="primary" loading={loading} variant="contained">
            OK
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

AddManualCloseTrade.propTypes = {
  open: PropTypes.bool.isRequired,
  client: PropTypes.object.isRequired, // Client details from the row
  onClose: PropTypes.func.isRequired,
  onUserUpdate: PropTypes.func,
};

export default AddManualCloseTrade;
