/* eslint-disable react/prop-types */
import React from 'react';
import { Typography, Box, Accordion, AccordionDetails, Grid, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { isNaN } from 'lodash';
import { calculateActiveProfitLoss } from '../../../../utils/modifyScriptName';
import { setShowAccountDetails } from '../../../../redux/slices/trades';
import { dispatch } from '../../../../redux/store';
import { fNumber } from '../../../../utils/formatNumber';

const HeaderItem = ({ label, value, live }) => {
  let color = 'text.primary'; // default color

  if (live) {
    if (value > 0) {
      color = 'success.dark'; // Green for positive values
    } else if (value < 0) {
      color = 'error.main'; // Red for negative values
    }
  }
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Typography variant="body2" color="GrayText">
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color={color} fontWeight={live ? 'bold' : 'regular'}>
          {fNumber(value.toFixed(0))}
        </Typography>
      </Grid>
    </Grid>
  );
};

const PortfolioHeader = ({
  balance,
  bookedPL,
  brokerage,
  blockedMargin,
  activeTrades,
  priceChangeConfig,
  showAccountDetails,
}) => {
  // const dispatch = useDispatch();
  let activeProfitLoss = 0;

  if (priceChangeConfig) {
    const response = calculateActiveProfitLoss(activeTrades, priceChangeConfig);
    activeProfitLoss = response.overallProfitLoss;
  }
  if (isNaN(activeProfitLoss)) {
    activeProfitLoss = 0;
  }

  const ledgerBalance = balance + bookedPL - brokerage;
  const assetsValue = ledgerBalance + activeProfitLoss;
  const freeMargin = assetsValue - blockedMargin;

  const handleAccordionChange = (event, isExpanded) => {
    dispatch(setShowAccountDetails(isExpanded));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Accordion sx={{ mt: -1 }} expanded={showAccountDetails} onChange={handleAccordionChange}>
        <AccordionSummary expandIcon={<ExpandMore />} m={0}>
          <Typography variant="h6">Account Details</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ mt: -1 }}>
          <Grid container spacing={2}>
            {/* First column */}
            <Grid item xs={6} sm={6} md={6}>
              <HeaderItem label="Credit" value={balance} />
              <HeaderItem label="Used Margin" value={blockedMargin} />
              <HeaderItem label="Active P/L" live value={activeProfitLoss} />
              <HeaderItem label="Free Margin" value={freeMargin} />
            </Grid>
            {/* Second column */}
            <Grid item xs={6} sm={6} md={6}>
              <HeaderItem label="Booked P/L" value={bookedPL} />
              <HeaderItem label="Commission" value={brokerage} />
              <HeaderItem label="Net P/L" value={bookedPL - brokerage} />
              <HeaderItem label="Avl. Balance" value={ledgerBalance + activeProfitLoss} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default PortfolioHeader;
