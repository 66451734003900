/* eslint-disable react-hooks/exhaustive-deps */
// import { createContext, useContext, useEffect, useReducer } from 'react';
// import { useDispatch } from 'react-redux';
// import PropTypes from 'prop-types';
// import parseBinary from '../utils/parseBinaryZerotha';
// import { getQuoteSuccess, getTradingViewQuoteSuccess, getUserPortfolio } from '../redux/slices/trades';

// const TradeContext = createContext();

// // const SOCKET_SERVER = process.env.REACT_APP_ZEROTHA_SOCKET_URL;
// // const LOCAL_SERVER = `ws://${process.env.REACT_APP_ORIGIN}`;

// const LOCAL_SERVER = process.env.REACT_APP_SOCKER_URL;
// const initialState = {
//   socket: null,
//   localSocket: null,
// };

// TradeProvider.propTypes = {
//   children: PropTypes.node,
// };

// const handlers = {
//   SET_SOCKET: (state, action) => ({ ...state, socket: action.payload }),
//   SET_LOCAL_SOCKET: (state, action) => ({ ...state, localSocket: action.payload }),
// };
// const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

// function TradeProvider({ children }) {
//   const [state, dispatch] = useReducer(reducer, initialState);
//   const dispatchMessage = useDispatch();

//   useEffect(() => {
//     const token = localStorage.getItem('accessToken');
//     const localSocket = new WebSocket(`${LOCAL_SERVER}?token=${token}`);
//     // dispatch({
//     //   type: 'SET_SOCKET',
//     //   payload: newSocket,
//     // });
//     dispatch({
//       type: 'SET_LOCAL_SOCKET',
//       payload: localSocket,
//     });

//     return () => {
//       // if (newSocket.readyState === WebSocket.OPEN) {
//       //   newSocket.close();
//       // }
//       if (localSocket.readyState === WebSocket.OPEN) {
//         localSocket.close();
//       }
//     };
//   }, []);

//   useEffect(() => {
//     const { socket, localSocket } = state;
//     // if (!socket) return;
//     if (!localSocket) return;

//     const handleOpen = (event) => {
//       console.log('WebSocket connection established.', localSocket.readyState);
//       // if (event.target.onopen) {
//       // event.target.onopen();
//       // }
//     };

//     const handleMessage = (event) => {
//       console.log('EVENT', event);
//       const data = parseBinary(event.data);
//       console.log('DATA', data);
//       if (data.length > 0) {
//         dispatchMessage(getQuoteSuccess(data));
//       }
//     };

//     const handleError = (error) => {
//       console.error('WebSocket error:', error);
//     };

//     const handleClose = () => {
//       console.log('WebSocket connection closed. Attempting to reconnect...');
//       // You can implement reconnection logic here
//     };

//     const handleMessageLocal = (event) => {
//       const data = JSON.parse(event.data);
//       switch (data.event) {
//         case 'subscribe': {
//           dispatchMessage(getQuoteSuccess(data.data));
//           break;
//         }
//         case 'limit_executed': {
//           dispatchMessage(getUserPortfolio());
//           break;
//         }
//         default: {
//           break;
//         }
//       }
//       // dispatchMessage(getTradingViewQuoteSuccess(JSON.parse(event.data)));
//       // dispatchMessage(getQuoteSuccess(JSON.parse(event.data)));
//     };

//     // socket.addEventListener('open', handleOpen);
//     // socket.addEventListener('message', handleMessage);
//     // socket.addEventListener('error', handleError);
//     // socket.addEventListener('close', handleClose);
//     localSocket.addEventListener('open', handleOpen);
//     localSocket.addEventListener('message', handleMessageLocal);

//     return () => {
//       // socket.removeEventListener('open', handleOpen);
//       // socket.removeEventListener('message', handleMessage);
//       // socket.removeEventListener('error', handleError);
//       // socket.removeEventListener('close', handleClose);
//       localSocket.removeEventListener('open', handleOpen);
//       localSocket.removeEventListener('message', handleMessageLocal);
//     };
//   }, [state.socket, state.localSocket]);

//   return <TradeContext.Provider value={state}>{children}</TradeContext.Provider>;
// }

// export { TradeContext, TradeProvider };
import { createContext, useEffect, useReducer, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// import parseBinary from '../utils/parseBinaryZerotha';
import { getQuoteSuccess, getTradeHistory, getUserPortfolio } from '../redux/slices/trades';
import { operatorActions } from '../redux/slices/operator';
import useAuth from '../hooks/useAuth';

// const waitForSocketConnection = (socket) =>
//   new Promise((resolve, reject) => {
//     const maxAttempts = 20; // Maximum number of attempts to check the connection
//     let attempts = 0;

//     const checkConnection = () => {
//       if (socket.readyState === WebSocket.OPEN) {
//         resolve();
//       } else if (attempts < maxAttempts) {
//         attempts += 1;
//         setTimeout(checkConnection, 200); // Check again after 100ms
//       } else {
//         reject(new Error('WebSocket connection failed to open'));
//       }
//     };

//     checkConnection();
//   });

const TradeContext = createContext();

const LOCAL_SERVER = process.env.REACT_APP_SOCKER_URL;

const initialState = {
  localSocket: null,
  reconnectAttempts: 0,
};

TradeProvider.propTypes = {
  children: PropTypes.node,
};

const handlers = {
  SET_LOCAL_SOCKET: (state, action) => ({ ...state, localSocket: action.payload }),
  CLEAR_LOCAL_SOCKET: (state) => ({ ...state, localSocket: null }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

function TradeProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { logout } = useAuth();
  const dispatchMessage = useDispatch();

  // Ref to track reconnect attempts
  const reconnectAttempts = useRef(0);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    const initializeSocket = async () => {
      const localSocket = new WebSocket(`${LOCAL_SERVER}?token=${token}`);
      try {
        // await waitForSocketConnection(localSocket);
        dispatch({
          type: 'SET_LOCAL_SOCKET',
          payload: localSocket,
        });
      } catch (error) {
        console.error('WebSocket connection failed to open:', error);
      }

      localSocket.addEventListener('close', () => {
        console.log('WebSocket connection closed');
        // Retry logic: Limit retries to 3
        if (reconnectAttempts.current < 3) {
          reconnectAttempts.current += 1;
          console.log(`Reconnecting... Attempt ${reconnectAttempts.current}`);
          setTimeout(() => initializeSocket(), 5000); // Retry after 5 seconds
        } else {
          console.log('Max reconnect attempts reached.');
        }
      });
    };

    initializeSocket();

    return () => {
      if (state.localSocket) {
        state.localSocket.close();
        dispatch({ type: 'CLEAR_LOCAL_SOCKET' });
      }
    };
  }, []);

  // useEffect(() => {
  //   const token = localStorage.getItem('accessToken');
  //   const localSocket = new WebSocket(`${LOCAL_SERVER}?token=${token}`);
  //   dispatch({
  //     type: 'SET_LOCAL_SOCKET',
  //     payload: localSocket,
  //   });

  //   localSocket.addEventListener('close', () => {
  //     console.log('WebSocket connection closed. Attempting to reconnect...');
  //     setTimeout(() => {
  //       // Reconnect after a delay
  //       const newLocalSocket = new WebSocket(`${LOCAL_SERVER}?token=${token}`);
  //       dispatch({
  //         type: 'SET_LOCAL_SOCKET',
  //         payload: newLocalSocket,
  //       });
  //     }, 5000); // Reconnect after 5 seconds (adjust the delay as needed)
  //   });

  //   return () => {
  //     if (state.localSocket) {
  //       state.localSocket.close();
  //       dispatch({ type: 'CLEAR_LOCAL_SOCKET' });
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const { localSocket } = state;

    if (!localSocket) return;

    const handleMessageLocal = (event) => {
      const data = JSON.parse(event.data);
      switch (data.event) {
        case 'subscribe':
          dispatchMessage(getQuoteSuccess(data.data));
          break;
        case 'limit_executed':
          dispatchMessage(getUserPortfolio());
          dispatchMessage(getTradeHistory());
          break;
        case 'refresh_trade':
          dispatchMessage(getUserPortfolio());
          dispatchMessage(getTradeHistory());
          break;
        case 'active_positions':
          dispatchMessage(operatorActions.getScriptWiseTradeSuccess(data.data));
          break;
        case 'scriptwise_active_clients':
          dispatchMessage(operatorActions.getcurrentScriptClientTradeSuccess(data.data));
          break;
        case 'dashboard_subscribe':
          dispatchMessage(operatorActions.getUserBalanceSummarySuccess(data?.data?.data));
          dispatchMessage(operatorActions.getUserMoneyDetailSuccess(data?.data?.summary));
          break;
        case 'session_replaced':
          logout();
          break;
        case 'auth_error':
          logout();
          break;
        case 'ping':
          localSocket.send(JSON.stringify({ event: 'pong' })); // Send pong on receiving ping
          break;
        default:
          break;
      }
    };

    localSocket.addEventListener('message', handleMessageLocal);

    // eslint-disable-next-line consistent-return
    return () => localSocket.removeEventListener('message', handleMessageLocal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.localSocket]);

  return <TradeContext.Provider value={state}>{children}</TradeContext.Provider>;
}

export { TradeContext, TradeProvider };
