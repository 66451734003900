import PropTypes from 'prop-types';
// @mui
import { Box, TableRow, TableCell, TableHead, TableSortLabel, Checkbox, Typography } from '@mui/material';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

// ----------------------------------------------------------------------

TableHeadCustom.propTypes = {
  onSort: PropTypes.func,
  orderBy: PropTypes.string,
  headLabel: PropTypes.array,
  order: PropTypes.oneOf(['asc', 'desc']),
  sx: PropTypes.object,
  onSelectAllRows: PropTypes.func,
  rowCount: PropTypes.number,
  numSelected: PropTypes.number,
  headName: PropTypes.string,
  sticky: PropTypes.bool,
};

export default function TableHeadCustom({
  order,
  orderBy,
  headLabel,
  onSort,
  sx,
  rowCount = 0,
  numSelected = 0,
  onSelectAllRows,
  headName,
  sticky,
}) {
  return (
    <TableHead sx={sx}>
      <TableRow>
        {onSelectAllRows && headName && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event) => onSelectAllRows(event.target.checked)}
            />
          </TableCell>
        )}
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.width,
              minWidth: headCell.minWidth,
              whiteSpace: 'nowrap',
              ...(sticky && headCell.id === 'username'
                ? { position: 'sticky', left: 0, backdropFilter: 'blur(100px)' }
                : {}),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: headCell.align || 'left',
                justifyContent: 'center',
                cursor: onSort ? 'pointer' : 'default', // Change cursor if sorting is available
              }}
              onClick={() => onSort && onSort(headCell.id)} // Trigger sorting on click if onSort is provided
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: headCell.align || 'left',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: headCell.align || 'left',
                  }}
                >
                  <Typography
                    variant="h7"
                    sx={{ textTransform: 'capitalize', color: 'text.primary' }}
                    align={headCell.align || 'left'}
                  >
                    {headCell.label}
                  </Typography>
                  {headCell.subLabel && (
                    <Typography
                      variant="body2"
                      sx={{ textTransform: 'capitalize', marginTop: 0.5, color: 'text.gray' }}
                      align={headCell.align || 'left'}
                    >
                      {headCell.subLabel}
                    </Typography>
                  )}
                </Box>
                {onSort && (
                  <TableSortLabel
                    hideSortIcon
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {orderBy === headCell.id ? (
                      <Box sx={{ ...visuallyHidden }}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                )}
              </Box>
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
