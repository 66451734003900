/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { paramCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Card,
  Table,
  Switch,
  TableBody,
  Container,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Tab,
  Tabs,
} from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getInstrumentList } from '../../redux/slices/trades';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import {
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedActions,
} from '../../components/table';
// sections
// import { ProductTableRow, ProductTableToolbar } from '../../sections/@dashboard/e-commerce/product-list';
// import InstumentTableRow from '../../sections/@dashboard/Trade/instrument-list/InstrumentTableRow';
// import InstrumentTableToolBar from '../../sections/@dashboard/Trade/instrument-list/InstrumentToolBar';
import { InstrumentTableRow, InstrumentToolBar } from '../../sections/@dashboard/Trade/instrument-list';
// import Tabs from '../../theme/overrides/Tabs';
import useTabs from '../../hooks/useTabs';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'tradingsymbol', label: 'Trade symbol', align: 'left' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'expiry', label: 'Expiry', align: 'left' },
  { id: 'exchange', label: 'Exchange', align: 'left' },
  { id: 'last_price', label: 'LastPrice', align: 'left' },
];
const TABLE_HEAD_FOR_CLIENT = [
  { id: 'select', label: 'Add to Watchlist', align: 'center' },
  { id: 'tradingsymbol', label: 'Trade symbol', align: 'left' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'expiry', label: 'Expiry', align: 'left' },
  { id: 'exchange', label: 'Exchange', align: 'left' },
  { id: 'last_price', label: 'LastPrice', align: 'left' },
];

const STATUS_OPTIONS = ['NFO', 'MCX'];
// ----------------------------------------------------------------------

export default function InstrumentList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
  } = useTable({
    defaultOrderBy: 'createdAt',
  });

  const { themeStretch } = useSettings();

  const { userType } = useAuth();
  let Dashboardpath;
  if (userType.isSuperAdmin) Dashboardpath = PATH_DASHBOARD.root;
  if (userType.isAdmin) Dashboardpath = PATH_DASHBOARD.insurerRole.root;
  if (userType.isBroker) Dashboardpath = PATH_DASHBOARD.operatorRole.root;
  if (userType.isClient) Dashboardpath = PATH_DASHBOARD.bankerRole.root;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { instrumentData, isLoading } = useSelector((state) => state?.trade);

  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const [filterName, setFilterName] = useState('');

  const { currentTab: filterStatus, setCurrentTab } = useTabs('NFO');
  useEffect(() => {
    dispatch(getInstrumentList(filterStatus, 1, 10, filterName));
  }, [dispatch]);

  useEffect(() => {
    if (instrumentData?.docs) {
      const { docs, ...other } = instrumentData;
      setTableData(instrumentData?.docs);
      setPaginationData(other);
    }
  }, [instrumentData]);

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
    dispatch(getInstrumentList(filterStatus, 0, 10, filterName));
  };

  const handleDeleteRow = (id) => {
    const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleEditRow = (id) => {
    navigate(PATH_DASHBOARD.eCommerce.edit(paramCase(id)));
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const denseHeight = dense ? 60 : 80;

  const isNotFound = (!dataFiltered.length && !!filterName) || (!isLoading && !dataFiltered.length);

  return (
    <Page title="Ecommerce: Product List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Stock List"
          links={[{ name: 'Dashboard', href: Dashboardpath }, { name: 'Stock List' }]}
          //   action={
          //     <Button
          //       variant="contained"
          //       startIcon={<Iconify icon="eva:plus-fill" />}
          //       component={RouterLink}
          //       to={PATH_DASHBOARD.eCommerce.new}
          //     >
          //       New Product
          //     </Button>
          //   }
        />

        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={(e, value) => {
              setCurrentTab(value);
              setFilterName('');
              // setPageCurrent(1);
              // setRowsPerPage(2);
              //   dispatch(getOperator(brokerId || user?._id, rowsPerPage, 1, value, order));
              dispatch(getInstrumentList(value, 1, 10));
            }}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs>
          <InstrumentToolBar filterName={filterName} onFilterName={handleFilterName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 960, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  //   actions={
                  //     <Tooltip title="Delete">
                  //       <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                  //         <Iconify icon={'eva:trash-2-outline'} />
                  //       </IconButton>
                  //     </Tooltip>
                  //   }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={userType.isClient ? TABLE_HEAD_FOR_CLIENT : TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {(isLoading ? [...Array(rowsPerPage)] : dataFiltered).map((row, index) =>
                    row ? (
                      <InstrumentTableRow
                        userType={userType}
                        key={row._id}
                        row={row}
                        selected={selected.includes(row._id)}
                        onSelectRow={() => onSelectRow(row._id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        onEditRow={() => handleEditRow(row.name)}
                      />
                    ) : (
                      !isNotFound && <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    )
                  )}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={paginationData?.totalDocs || 10}
              rowsPerPage={rowsPerPage || 10}
              page={paginationData?.page - 1 || 0}
              onPageChange={(e) => {
                if (e.target.dataset.testid === 'KeyboardArrowRightIcon') {
                  //   setPage((prev) => prev + 1);
                  dispatch(getInstrumentList(filterStatus, paginationData?.page + 1, rowsPerPage, filterName));
                } else {
                  dispatch(getInstrumentList(filterStatus, paginationData?.page - 1, rowsPerPage, filterName));
                }
                // } else {
                //   setCurrentPage(clientAssignedList?.currentPage - 1);
                //   if (filterRole === 'all') {
                //     dispatch(getProjectByID(rowsPerPage, clientAssignedList?.currentPage - 1, filterStatus, order, id));
                //   } else {
                //     dispatch(
                //       getArchivedProjects(rowsPerPage, clientAssignedList?.currentPage + 1, filterStatus, order)
                //     );
                //   }
                // }
              }}
              onRowsPerPageChange={(e) => {
                setRowsPerPage(e.target.value);
                setPage(0);
                dispatch(getInstrumentList(filterStatus, 0, e.target.value, filterName));
              }}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  //   if (filterName) {
  //     tableData = tableData.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  //   }
  return tableData;
}
