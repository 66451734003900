// @mui
import { styled, useTheme } from '@mui/material/styles';
import { TableRow, TableCell } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 2),
}));

export default function TableLoader() {
  const theme = useTheme();
  return (
    <TableRow>
      <TableCell colSpan={12}>
        <RootStyle
          sx={{
            '& span.MuiBox-root': { height: 100 },
          }}
        >
          <LoadingButton
            loading
            size="large"
            sx={{
              '.MuiCircularProgress-root': {
                width: '30px !important',
                height: '30px !important',
                color: theme.palette.primary.main,
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.palette.primary.main,
            }}
          />
        </RootStyle>
      </TableCell>
    </TableRow>
  );
}
