import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

const RHFAutoSelect = ({ name, options, getOptionLabel, ...textFieldProps }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: 'Please select a value',
      }}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          options={options}
          getOptionLabel={getOptionLabel}
          onChange={(event, value) => field.onChange(value)}
          error={fieldState.error ? fieldState.error : undefined}
          isOptionEqualToValue={(option, value) => option?._id === value?._id}
          renderInput={(params) => (
            <TextField
              {...params}
              {...textFieldProps}
              error={!!fieldState.error}
              helperText={fieldState.error ? fieldState.error.message : ''}
              SelectProps={{ native: true }}
            />
          )}
        />
      )}
    />
  );
};

RHFAutoSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
};

export default RHFAutoSelect;
