import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

RHFToggleButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  label: PropTypes.string,
};

export default function RHFToggleButtonGroup({ name, options, label, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <ToggleButtonGroup
          value={field.value}
          exclusive
          onChange={(event, newValue) => {
            if (newValue !== null) field.onChange(newValue);
          }}
          aria-label={label || 'Toggle Button Group'}
          {...other}
        >
          {options.map((option) => (
            <ToggleButton key={option.value} value={option.value} sx={{ flexGrow: 1, textTransform: 'uppercase' }}>
              {option.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    />
  );
}
