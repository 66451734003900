/* eslint-disable react/jsx-no-undef */
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { Stack, Card, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import axios from '../../../../utils/axios';

// ----------------------------------------------------------------------

export default function AccountChangePassword() {
  const { enqueueSnackbar } = useSnackbar();

  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('OLD PASSWORD IS REQUIRED'),
    newPassword: Yup.string().min(6, 'PASSWORD MUST BE AT LEAST 6 CHARACTERS').required('NEW PASSWORD IS REQUIRED'),
    confirmNewPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      'CONFIRM PASSWORD AND NEW PASSWORD MUST MATCH'
    ).required('CONFIRM PASSWORD IS REQUIRED'),
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (payload) => {
    try {
      console.log('PAYLOAD', payload);
      const response = await axios.put('/user/change-password', payload);
      reset();
      enqueueSnackbar(response?.data?.message.toUpperCase());
    } catch (error) {
      enqueueSnackbar(error.message.toUpperCase(), { variant: 'error' }); // Displaying error message
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        p: 2,
      }}
    >
      <Card sx={{ p: 4, maxWidth: 500, width: '100%' }}>
        <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', textTransform: 'uppercase' }}>
          Change Admin Password
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <RHFTextField name="oldPassword" type="password" label="Old Password" 
              InputLabelProps={{ style: { textTransform: 'uppercase' } }} />

            <RHFTextField name="newPassword" type="password" label="New Password" 
              InputLabelProps={{ style: { textTransform: 'uppercase' } }} />

            <RHFTextField name="confirmNewPassword" type="password" label="Confirm New Password" 
              InputLabelProps={{ style: { textTransform: 'uppercase' } }} />

            <LoadingButton 
              type="submit" 
              variant="contained" 
              loading={isSubmitting} 
              sx={{ mt: 2, alignSelf: 'center', textTransform: 'uppercase' }}
            >
              Update Password
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Card>
    </Box>
  );
}
