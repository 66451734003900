import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  insurerData: [],
  clientApplicationsData: [],
  clientProfile: {},
  currentPage: null,
  totalPages: null,
  totalResults: null,
  insurerProfile: {},
};

const slice = createSlice({
  name: 'insurer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET INSURERS
    getInsurerListSuccess(state, action) {
      state.isLoading = false;
      state.insurerData = action?.payload?.users;
      state.totalPage = action?.payload?.totalPages;
      state.currentPage = action?.payload?.page;
      state.totalResults = action?.payload?.totalResults;
    },

    // GET CLIENT APPLICATIONS
    getClientApplicationsSuccess(state, action) {
      state.isLoading = false;
      state.clientApplicationsData = action?.payload?.clientApplications;
      state.totalPage = action?.payload?.totalPages;
      state.currentPage = action?.payload?.page;
      state.totalResults = action?.payload?.totalResults;
    },

    // GET CLIENT PROFILE
    getClientProfileSuccess(state, action) {
      state.isLoading = false;
      state.clientProfile = action?.payload?.clientApplicationProfile;
    },

    // POST INSURER
    postInsurerSuccess(state) {
      state.isLoading = false;
    },

    // PUT INSURER
    updateInsurerListSuccess(state, action) {
      state.isLoading = false;
      const { insurerID, filterStatus, newStatus } = action.payload;
      if (filterStatus === 'all') {
        const findIndex = state.insurerData.findIndex((data) => data._id === insurerID);
        state.insurerData[findIndex].status = newStatus;
      } else {
        state.insurerData = state.insurerData.filter((data) => data._id !== insurerID);
      }
    },

    // GET INSURER PROFILE
    getInsurerProfileSuccess(state, action) {
      state.isLoading = false;
      state.insurerProfile = action?.payload?.user;
    },

    // REMOVE USER REQUEST
    getRemoveUserSuccess(state) {
      state.isLoading = false;
    },

    // ASSIGN USER REQUEST
    getAssignUserSuccess(state) {
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const insurerActions = slice.actions;

// ----------------------------------------------------------------------

// GET INSURER DETAILS
export function getInsurer(pageSize, pageNumber, statusData, order, searchWord, cancelToken) {
  return async () => {
    let response;
    try {
      if (searchWord?.length > 0) {
        response = await axios.get(
          `/admin/user/?type=insurer&status=${statusData}&order=${order}&pageSize=${pageSize}&page=${pageNumber}&search=${searchWord}`,
          { cancelToken: cancelToken.token }
        );
      } else {
        await dispatch(slice.actions.startLoading());
        response = await axios.get(
          `/admin/user/?type=insurer&status=${statusData}&order=${order}&pageSize=${pageSize}&page=${pageNumber}`
        );
      }
      const { data, status, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.getInsurerListSuccess(data));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
      if (axios.isCancel(error) || error) {
        // console.log(error);
      }
    }
  };
}

// PUT INSURERS

export function putInsurer(insurerID, statusTab, notes, filterStatus) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/admin/user/${insurerID}?${statusTab}=true`);
      const { status, newStatus, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.updateInsurerListSuccess({ insurerID, statusTab, filterStatus, newStatus }));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}

// POST INSURER

export function postInsurer(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/admin/insurer/add', { ...payload });
      const { status, message } = response?.data;
      if (status === 201) {
        dispatch(slice.actions.postInsurerSuccess());
      } else {
        dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// GET INSURER PROFILE

export function getInsurerProfile(insurerID) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/admin/user/${insurerID}`);
      const { status, data, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.getInsurerProfileSuccess(data));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}

// GET CLIENT APPLICATIONS

export function getClientApplications(pageSize, pageNumber, type, order, searchWord, cancelToken) {
  return async () => {
    let response;
    try {
      if (searchWord?.length > 0) {
        response = await axios.get(
          `/admin/client-app?type=${type}&order=${order}&pageSize=${pageSize}&page=${pageNumber}&search=${searchWord}`,
          { cancelToken: cancelToken.token }
        );
      } else {
        await dispatch(slice.actions.startLoading());
        response = await axios.get(
          `/admin/client-app?type=${type}&order=${order}&pageSize=${pageSize}&page=${pageNumber}`
        );
      }
      const { data, status, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.getClientApplicationsSuccess(data));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
      if (axios.isCancel(error) || error) {
        // console.log(error);
      }
    }
  };
}

// GET CLIENT APP PROFILE

export function getClientProfile(clientProfileId) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/admin/client-app/${clientProfileId}`);
      const { status, data, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.getClientProfileSuccess(data));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}

// PUT REMOVE USER

export function putRemoveUser(payload) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/admin/client-app/request/remove`, { ...payload });
      const { status, data, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.getRemoveUserSuccess(data));
        await dispatch(getClientProfile(payload._id));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}

// PUT ASSIGN USER

export function putAssignUser(payload) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/admin/client-app/request`, { ...payload });
      const { status, data, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.getAssignUserSuccess(data));
        await dispatch(getClientProfile(payload._id));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}
