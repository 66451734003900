import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { RHFTextField } from '../../../components/hook-form/index';
import { getInstrumentList } from '../../../redux/slices/trades';
import axios from '../../../utils/axios';
import modifyScriptName from '../../../utils/modifyScriptName';
import { Wrapper } from '../../../sections/@dashboard/Trade/watchlist/BuyScriptDrawer';

// const sideOptions = [
//   { label: 'BUY', value: 'BUY' },
//   { label: 'SELL', value: 'SELL' },
// ];

// Validation schema using yup
const schema = yup.object().shape({
  side: yup.string().required('Side is required'),
  quantity: yup
    .number()
    .typeError('Quantity is required')
    .required('Quantity is required')
    .min(1, 'Quantity must be at least 1'),
  buy: yup
    .number()
    .typeError('Buy is required')
    .when('side', {
      is: 'BUY',
      then: yup.number().required('Buy is required').min(0.01, 'Buy must be at least 0.01'),
    }),
  sell: yup
    .number()
    .typeError('Sell is required')
    .when('side', {
      is: 'SELL',
      then: yup.number().required('Sell is required').min(0.01, 'Sell must be at least 0.01'),
    }),
});

const EditTradeModal = ({ open, trade, onClose, onUserUpdate }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getInstrumentList());
  }, [dispatch]);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      side: trade.side || '',
      quantity: trade.quantity || '',
      buy: trade.buy || '',
      sell: trade.sell || '',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const sideValue = useWatch({
    control: methods.control,
    name: 'side',
    defaultValue: trade.side || '',
  });

  // State to manage the warning popup visibility and messages
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessages, setWarningMessages] = useState([]);

  useEffect(() => {
    // if (!open) {
    reset({
      side: trade.side || '',
      quantity: trade.quantity || '',
      buy: trade.buy || trade.sell || '',
      sell: trade.sell || trade.buy || '',
    });
    // }
  }, [open, reset, trade]);

  const onSubmit = async (data) => {
    const { side, quantity, buy, sell } = data;
    try {
      const res = await axios.put(`/trade/manual/edit/${trade._id}`, {
        side,
        quantity,
        buy,
        sell,
      });
      // Check for a warning in the response
      if (res.data?.error?.hasWarning) {
        setWarningMessages(res.data.error.warnings);
        setShowWarning(true);
      } else {
        enqueueSnackbar(res.data.message, { variant: 'success' });
        onUserUpdate();
        onClose();
      }
    } catch (error) {
      if (error?.hasWarning) {
        setWarningMessages(error.warnings);
        setShowWarning(true);
      } else {
        enqueueSnackbar(`Failed to edit trade: ${error.message}`, { variant: 'error' });
      }
    }
  };

  const handleWarningConfirm = async () => {
    const { side, quantity, buy, sell } = methods.getValues();
    try {
      setLoading(true);
      const res = await axios.put(`/trade/manual/edit/${trade._id}`, {
        side,
        quantity,
        buy,
        sell,
        force: true, // Include the force flag to override warnings
      });
      enqueueSnackbar(res.data.message, { variant: 'success' });
      setShowWarning(false);
      setLoading(false);
      onUserUpdate();
      onClose();
    } catch (error) {
      enqueueSnackbar(`Failed to edit trade: ${error.message}`, { variant: 'error' });
      setLoading(false);
    }
  };

  const handleWarningCancel = () => {
    setShowWarning(false);
  };

  const scriptName = modifyScriptName(trade.script?.tradingsymbol);

  return (
    <>
      <Wrapper open={open} onClose={onClose}>
        <Box width={{ xs: '100%', sm: '350px' }} marginX={'auto'}>
          <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" textAlign="center" pb={2}>
            Edit Trade - {scriptName}
          </Typography>

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                {/* <Grid item xs={6}>
                  <RHFSelect name="side" label="Side" fullWidth disabled={trade.type === 'ORDER'}>
                    {sideOptions.map((side) => (
                      <option key={side.value} value={side.value}>
                        {side.label}
                      </option>
                    ))}
                  </RHFSelect>
                </Grid> */}
                <Grid item xs={12}>
                  <RHFTextField name="quantity" label="Quantity" type="number" fullWidth />
                </Grid>
                {(sideValue === 'BUY' || trade.type === 'ORDER') && (
                  <Grid item xs={12}>
                    <RHFTextField name="buy" label="Buy" type="number" fullWidth />
                  </Grid>
                )}
                {(sideValue === 'SELL' || trade.type === 'ORDER') && (
                  <Grid item xs={12}>
                    <RHFTextField name="sell" label="Sell" type="number" fullWidth />
                  </Grid>
                )}
              </Grid>

              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    size="large"
                    sx={{ textTransform: 'uppercase' }}
                    loading={isSubmitting}
                  >
                    Update
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Wrapper>

      {/* Warning Dialog */}
      <Dialog open={showWarning}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          {warningMessages.map((message, index) => (
            <Typography key={index} sx={{ mb: 1 }}>
              {message}
            </Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWarningCancel} color="secondary">
            Cancel
          </Button>
          <LoadingButton onClick={handleWarningConfirm} color="primary" loading={loading} variant="contained">
            OK
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

EditTradeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  trade: PropTypes.object.isRequired, // Trade details from the row
  onClose: PropTypes.func.isRequired,
  onUserUpdate: PropTypes.func,
};

export default EditTradeModal;
