/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
  Divider,
  Typography,
  Box,
} from '@mui/material';
import {
  Add,
  AddBox,
  Assessment,
  AttachMoney,
  Autorenew,
  DeleteForever,
  Edit,
  ExpandMore,
  FileCopy,
  MoreVert,
  Refresh,
  Restore,
} from '@mui/icons-material';
import AddBalanceModal from '../../../../pages/dashboard/operator/AddBalanceModal';
import AddManualTradeModal from '../../../../pages/dashboard/operator/AddManualTradeModal';
import useAuth from '../../../../hooks/useAuth';
import RefreshMarginModal from '../../../../pages/dashboard/operator/RefreshMarginModal';
import RefreshBrokerageModal from '../../../../pages/dashboard/operator/RefreshBrokerageModal';
import AddManualCloseTrade from '../../../../pages/dashboard/operator/AddManualCloseTrade';
import ResetAccountModal from '../../../../pages/dashboard/operator/ResetAccountModal';
import GenerateReportModal from '../../../../pages/dashboard/operator/GenerateReportModal';
import UserConfigInfo from '../../../../pages/dashboard/operator/UserConfigInfo';
import DeleteUserModal from '../../../../pages/dashboard/operator/DeleteUserModal';

const ActionTableCell = ({ row, onEditRow, onDuplicateRow, onUserUpdateSuccess, showFullButton = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [openUserSettings, setOpenUserSettings] = useState(false);
  const [openTradeSettings, setOpenTradeSettings] = useState(false);
  const { user } = useAuth();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    setCurrentAction(action);
    setOpenModal(true);
    handleCloseMenu(); // Close the menu after setting the action
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentAction(null); // Reset current action after closing modal
    if (currentAction && ['manualTrade', 'manualCloseTrade'].includes(currentAction)) {
      onUserUpdateSuccess();
    }
  };

  // Toggle User Settings submenu
  const toggleUserSettings = () => {
    setOpenUserSettings((prev) => !prev);
  };

  // Toggle Trade Settings submenu
  const toggleTradeSettings = () => {
    setOpenTradeSettings((prev) => !prev);
  };

  // Function to handle updating based on the current action
  const handleUpdateBasedOnAction = () => {
    switch (currentAction) {
      case 'edit':
        onEditRow();
        break;
      case 'adjustBalance':
        onUserUpdateSuccess();
        handleCloseModal();
        break;
      case 'manualTrade':
        // onUserUpdateSuccess();
        handleCloseModal();
        break;
      case 'manualCloseTrade':
        // onUserUpdateSuccess();
        handleCloseModal();
        break;
      case 'resetAccount':
        onUserUpdateSuccess();
        handleCloseModal();
        break;
      case 'deleteAccount':
        onUserUpdateSuccess();
        handleCloseModal();
        break;
      case 'generateReport':
        onUserUpdateSuccess();
        handleCloseModal();
        break;
      case 'refreshBrokerage':
        // onUserUpdateSuccess();
        handleCloseModal();
        break;
      case 'refreshMargin':
        // onUserUpdateSuccess();
        handleCloseModal();
        break;
      case 'duplicate':
        onDuplicateRow();
        break;
      default:
        break;
    }
  };

  // Group User Settings and Trade Settings options
  const renderUserSettings = (role) => (
    <>
      <MenuItem onClick={toggleUserSettings}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography variant="subtitle1" fontWeight="bold">
            User Settings
          </Typography>
          <ExpandMore />
        </Box>
      </MenuItem>
      <Divider />
      {openUserSettings && (
        <>
          <MenuItem onClick={onEditRow}>
            <ListItemIcon>{getMenuItemIcon('edit')}</ListItemIcon>
            <ListItemText primary="Edit" />
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('adjustBalance')}>
            <ListItemIcon>{getMenuItemIcon('adjustBalance')}</ListItemIcon>
            <ListItemText primary="Adjust Balance" />
          </MenuItem>
          {role === 'admin' && (
            <MenuItem onClick={onDuplicateRow}>
              <ListItemIcon>{getMenuItemIcon('duplicate')}</ListItemIcon>
              <ListItemText primary="Duplicate" />
            </MenuItem>
          )}
          {role === 'admin' && (
            <MenuItem onClick={() => handleMenuItemClick('resetAccount')}>
              <ListItemIcon>{getMenuItemIcon('resetAccount')}</ListItemIcon>
              <ListItemText primary="Reset Account" />
            </MenuItem>
          )}
          {role === 'admin' && (
            <MenuItem onClick={() => handleMenuItemClick('deleteAccount')}>
              <ListItemIcon>{getMenuItemIcon('deleteAccount')}</ListItemIcon>
              <ListItemText primary="Delete Account" />
            </MenuItem>
          )}
        </>
      )}
    </>
  );

  const renderTradeSettings = () => (
    <>
      <MenuItem onClick={toggleTradeSettings}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography variant="subtitle1" fontWeight="bold">
            Trade Settings
          </Typography>
          <ExpandMore />
        </Box>
      </MenuItem>
      {/* <Divider /> */}
      {openTradeSettings && (
        <>
          <MenuItem onClick={() => handleMenuItemClick('manualTrade')}>
            <ListItemIcon>{getMenuItemIcon('manualTrade')}</ListItemIcon>
            <ListItemText primary="Manual Position" />
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('manualCloseTrade')}>
            <ListItemIcon>{getMenuItemIcon('manualCloseTrade')}</ListItemIcon>
            <ListItemText primary="Manual Trade" />
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('refreshBrokerage')}>
            <ListItemIcon>{getMenuItemIcon('refreshBrokerage')}</ListItemIcon>
            <ListItemText primary="Refresh Brokerage" />
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('refreshMargin')}>
            <ListItemIcon>{getMenuItemIcon('refreshMargin')}</ListItemIcon>
            <ListItemText primary="Refresh Margin" />
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('generateReport')}>
            <ListItemIcon>{getMenuItemIcon('generateReport')}</ListItemIcon>
            <ListItemText primary="Generate Report" />
          </MenuItem>
        </>
      )}
    </>
  );

  const getMenuItemIcon = (action) => {
    switch (action) {
      case 'edit':
        return <Edit color="primary" />;
      case 'adjustBalance':
        return <AttachMoney color="primary" />;
      case 'manualTrade':
        return <AddBox color="primary" />;
      case 'manualCloseTrade':
        return <Add color="primary" />;
      case 'resetAccount':
        return <Restore color="primary" />;
      case 'deleteAccount':
        return <DeleteForever color="primary" />;
      case 'refreshBrokerage':
        return <Autorenew color="primary" />;
      case 'refreshMargin':
        return <Refresh color="primary" />;
      case 'duplicate':
        return <FileCopy color="primary" />;
      case 'generateReport':
        return <Assessment color="primary" />;
      default:
        return null;
    }
  };

  if (user.role.rolename === 'superAdmin') return <TableCell />;

  const [openInfoModal, setOpenInfoModal] = useState(false);

  const handleOpenInfoModal = () => setOpenInfoModal(true);
  const handleCloseInfoModal = () => setOpenInfoModal(false);

  return (
    <TableCell align="left">
      {!showFullButton && (
        <IconButton onClick={handleOpenMenu}>
          <MoreVert />
        </IconButton>
      )}
      {showFullButton && (
        <>
          <Button onClick={handleOpenInfoModal} variant="contained" sx={{ textTransform: 'uppercase', marginRight: 2 }}>
            Details
          </Button>
          <UserConfigInfo
            open={openInfoModal}
            onClose={handleCloseInfoModal}
            userName={row?.username}
            traderConfigs={row}
            role="broker"
          />
          {console.log('get row obj ==>', row)}
          {console.log('ROLE ==>', row?.role === 'client' ? 'broker' : '')}
          <Button onClick={handleOpenMenu} variant="contained" sx={{ textTransform: 'uppercase', marginRight: 2 }}>
            Actionss
          </Button>
        </>
      )}

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {renderUserSettings(user.role.rolename)}

        {/* Conditionally render Trade Settings if user is an admin */}
        {user.role.rolename === 'admin' && renderTradeSettings()}
      </Menu>

      {/* Render modals based on the current action */}
      {openModal && currentAction === 'adjustBalance' && (
        <AddBalanceModal
          open={openModal}
          onClose={handleCloseModal}
          onUserUpdate={handleUpdateBasedOnAction}
          balanceSummary={{
            ...row.balanceDetail,
            balance: row.balance,
            trader: { username: row.username, _id: row._id },
          }}
        />
      )}

      {openModal && currentAction === 'manualTrade' && (
        <AddManualTradeModal
          open={openModal}
          onClose={handleCloseModal}
          onUserUpdate={handleUpdateBasedOnAction}
          client={row}
        />
      )}

      {openModal && currentAction === 'manualCloseTrade' && (
        <AddManualCloseTrade
          open={openModal}
          onClose={handleCloseModal}
          onUserUpdate={handleUpdateBasedOnAction}
          client={row}
        />
      )}

      {openModal && currentAction === 'refreshMargin' && (
        <RefreshMarginModal
          open={openModal}
          onClose={handleCloseModal}
          onUserUpdate={handleUpdateBasedOnAction}
          client={row}
        />
      )}

      {openModal && currentAction === 'refreshBrokerage' && (
        <RefreshBrokerageModal
          open={openModal}
          onClose={handleCloseModal}
          onUserUpdate={handleCloseModal}
          client={row}
        />
      )}
      {openModal && currentAction === 'resetAccount' && (
        <ResetAccountModal
          open={openModal}
          onClose={handleCloseModal}
          onUserUpdate={handleUpdateBasedOnAction}
          client={row}
        />
      )}
      {openModal && currentAction === 'deleteAccount' && (
        <DeleteUserModal
          open={openModal}
          onClose={handleCloseModal}
          onUserUpdate={handleUpdateBasedOnAction}
          client={row}
        />
      )}
      {openModal && currentAction === 'generateReport' && (
        <GenerateReportModal
          open={openModal}
          onClose={handleCloseModal}
          onUserUpdate={handleUpdateBasedOnAction}
          client={row}
        />
      )}
    </TableCell>
  );
};

ActionTableCell.propTypes = {
  row: PropTypes.object.isRequired,
  onEditRow: PropTypes.func,
  onDuplicateRow: PropTypes.func,
  onUserUpdateSuccess: PropTypes.func,
  showFullButton: PropTypes.bool,
};

export default ActionTableCell;
