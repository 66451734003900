import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

function PDFUpload({ ...other }) {
  const theme = useTheme();

  // const PRIMARY_LIGHTER = theme.palette.primary.lighter;

  const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;

  // const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 319.22 319.37">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              fill={PRIMARY_MAIN}
              d="M319,149.79c.08,1.36.21,2.72.21,4.09q0,57.41,0,114.81c0,10.54-2.2,20.48-8.39,29.21-8.65,12.2-20,20.15-35.27,21.16-6.2.42-12.45.29-18.68.29q-103.77,0-207.54-.1c-20.73,0-35.65-9.45-45-27.78A40.87,40.87,0,0,1,0,272.72q0-26.55,0-53.11a21.1,21.1,0,0,1,.42-2.93c6.92-6.45,12.17-14.35,18.45-21.37,10-11.21,19.7-22.75,29.37-34.28,6.38-7.6,13-15.05,19.61-22.49,13.08-14.74,28.89-22.65,49-20.82,13.52,1.22,24.73,7.06,34.4,16.22,4.79,4.53,8.6,10,12.89,15,7.34,8.55,15,16.87,22.28,25.45,13.21,15.49,29.93,20.14,49.36,16,9.11-2,16.71-7.57,25-11.55C280.3,169.37,299.61,159.48,319,149.79Z"
            />
            <path
              fill={'#95cbf8'}
              d="M319,149.79c-19.39,9.69-38.7,19.58-58.22,29-8.26,4-15.86,9.6-25,11.55-19.43,4.15-36.15-.5-49.36-16-7.31-8.58-14.94-16.9-22.28-25.45-4.29-5-8.1-10.43-12.89-15-9.67-9.16-20.88-15-34.4-16.22-20.14-1.83-35.95,6.08-49,20.82C61.25,146,54.62,153.43,48.24,161c-9.67,11.53-19.34,23.07-29.37,34.28-6.28,7-11.53,14.92-18.45,21.37-.08-1.37-.23-2.73-.23-4.09C.18,157.85.35,103.12.1,48.38A48,48,0,0,1,41.67.46,46.28,46.28,0,0,1,48,.29c74,0,148.11.13,222.16-.29,23.16-.13,45.4,17.24,48.61,41.33a29,29,0,0,1,.22,3.73Q319,97.43,319,149.79Zm-47.83-54c1.09-25.85-22.62-47.92-47.39-47.91-25.51,0-48.41,20-48.31,47.91.1,27.33,21.11,47.77,48,48S271.77,121.69,271.17,95.83Z"
            />
            <path
              fill={PRIMARY_MAIN}
              d="M271.17,95.83c.6,25.86-20.88,48.14-47.74,48s-47.86-20.63-48-48c-.1-27.92,22.8-47.91,48.31-47.91C248.55,47.91,272.26,70,271.17,95.83Z"
            />
          </g>
        </g>
      </svg>
    </Box>
  );
}

export default memo(PDFUpload);
