import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useLocation } from 'react-router';
// import PropTypes from 'prop-types';

// @mui
import {
  Box,
  Card,
  Table,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Switch,
  // FormControlLabel,
} from '@mui/material';

// routes
// import { PATH_DASHBOARD } from '../../../routes/paths';

// hooks
import useTabs from '../../../hooks/useTabs';
import useSettings from '../../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../../hooks/useTable';

// redux-slice
import { dispatch } from '../../../redux/store';
import { getClientLineTrades } from '../../../redux/slices/operator';

// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../../components/table';
import TableLoader from '../../../components/table/TableLoader';

// sections
import OperatorProjectTableRow from './OperatorProjectTableRow';
// import { UserTableToolbar } from '../../../sections/@dashboard/user/list';
import ClientLineTradesToolbar from './ClientLineTradesToolbar';

// ----------------------------------------------------------------------

// const STATUS_OPTIONS = ['all', 'active', 'pending', 'rejected'];

// const ROLE_OPTIONS = ['all', 'archived'];

const TIME_OPTIONS = [1, 5, 10, 15, 20, 25, 30];

// const TABLE_HEAD = [
//   { id: 'projectName', label: 'Project Name', align: 'left' },
//   { id: 'address', label: 'Address', align: 'left' },
//   { id: 'images', label: 'Project Images', align: 'left' },
//   { id: 'salesKit', label: 'Project Sales Kit', align: 'left' },
//   { id: 'createdDate', label: 'Created At', align: 'left' },
//   { id: 'isVerified', label: 'Verified', align: 'center' },
//   { id: 'status', label: 'Status', align: 'left' },
//   { id: '' },
// ];
// const TABLE_HEAD_EXECUTED = [
//   { id: 'trader.username', label: 'Trader', align: 'left' },
//   { id: 'script.tradingsymbol', label: 'ScriptName', align: 'left' },
//   { id: 'script.exchange.name', label: 'Exchange', align: 'left' },
//   { id: 'buy', label: 'Buy', align: 'left' },
//   { id: 'sell', label: 'Sell', align: 'left' },
//   { id: 'quantity', label: 'Quantity', align: 'left' },
//   { id: 'boughtAt', label: 'BoughtAt', align: 'left' },
//   { id: 'soldAt', label: 'SoldAt', align: 'left' },
//   { id: 'boughtDeviceInfo', label: 'BoughtDevice', align: 'left' },
//   { id: 'soldDeviceInfo', label: 'SoldDevice', align: 'left' },
// ];

const TABLE_HEAD_LINETRADES = [
  { id: 'trader.username', label: 'Trader', align: 'left' },
  { id: 'trader.firstname', label: 'FullName', align: 'left' },
  { id: 'script.tradingsymbol', label: 'ScriptName', align: 'left' },
  { id: 'script.exchange.name', label: 'Exchange', align: 'left' },
  { id: 'buy', label: 'Buy', align: 'left' },
  { id: 'sell', label: 'Sell', align: 'left' },
  { id: 'quantity', label: 'Qty', align: 'left' },
  { id: 'profit', label: 'NetP/L', align: 'left' },
  { id: 'timeDifference', label: 'Duration(Min)', align: 'left' },
  { id: 'boughtAt', label: 'BoughtAt', align: 'left' },
  { id: 'soldAt', label: 'SoldAt', align: 'left' },
  { id: 'boughtDeviceInfo', label: 'BoughtDevice', align: 'left' },
  { id: 'soldDeviceInfo', label: 'SoldDevice', align: 'left' },
];
// const TABLE_HEAD_PENDING = [
//   { id: 'script.tradingsymbol', label: 'Script Name', align: 'left' },
//   { id: 'trader.username', label: 'Trader', align: 'left' },
//   { id: 'script.exchange.name', label: 'Exchange', align: 'left' },
//   { id: 'side', label: 'Side', align: 'left' },
//   { id: 'rate', label: 'Rate', align: 'left' },
//   { id: 'quantity', label: 'Quantity', align: 'left' },
//   { id: 'executionSide', label: 'Direction', align: 'left' },
//   { id: 'type', label: 'Status', align: 'left' },
//   { id: 'createdAt', label: 'Created At', align: 'left' },
//   { id: 'updatedAt', label: 'Updated At', align: 'left' },
// ];

// ----------------------------------------------------------------------

export default function ClientLineTradeList() {
  const {
    dense,
    page,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
  } = useTable({
    defaultRowsPerPage: 25,
  });

  const { themeStretch } = useSettings();

  //   const { enqueueSnackbar } = useSnackbar();

  const [filterName, setFilterName] = useState(null);

  const [filterRole] = useState('all');

  const [timeDifference, setTimeDifference] = useState(5);

  const [order, setOrder] = useState('desc');

  const ClientsData = useSelector((state) => state?.operator);

  // const { clientTrades } = useSelector((state) => state?.operator);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);

  let cancelToken;

  const tableData = ClientsData?.clientTrades?.docs || [];

  const { currentTab: filterStatus } = useTabs('all');

  useEffect(() => {
    // dispatch(getProjectList(10, 1, filterStatus, order));
    dispatch(getClientLineTrades(25, 1, 'createdAt', 'desc', null, timeDifference));
  }, [dispatch, timeDifference]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterName = (searchObj) => {
    if (cancelToken) {
      cancelToken.cancel();
    }
    cancelToken = axios.CancelToken.source();
    console.log('SEARCH', searchObj);
    try {
      // if (filterRole === 'all') {
      // dispatch(getProjectList(rowsPerPage, currentPage, filterStatus, order, filterName, cancelToken));
      dispatch(getClientLineTrades(rowsPerPage, 1, orderBy, order, searchObj, timeDifference));
      setFilterName(searchObj);
      // } else {
      //   dispatch(getArchivedProjects(rowsPerPage, currentPage, filterStatus, order, filterName, cancelToken));
      // }
    } catch (error) {
      if (axios.isCancel(error) || error) {
        console.log(error);
      }
    }
  };

  //   const handleFilterRole = (name) => {
  //     setFilterRole(name);
  //     try {
  //       dispatch(getClientLineTrades(rowsPerPage, currentPage, orderBy, order, filterName, timeDifference));
  //       // if (name === 'all') {
  //       //   // dispatch(getProjectList(10, 1, filterStatus, order));
  //       //   getClientLineTrades(rowsPerPage, currentPage, null);
  //       // } else {
  //       //   dispatch(getArchivedProjects(10, 1, filterStatus, order));
  //       // }
  //     } catch (error) {
  //       enqueueSnackbar(error.message, { variant: 'error' });
  //     }
  //   };

  const handleDeleteRows = () => {
    // const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    // setTableData(deleteRows);
  };

  const handleTimeDiffereneChange = (value) => {
    // dispatch(getClientLineTrades(rowsPerPage, currentPage, orderBy, order, filterName, value));
    setTimeDifference(value);
  };

  // const handleViewRow = async (id) => {
  //   navigate(PATH_DASHBOARD.project.view(id));
  // };

  // const handleApproveUser = (id, type, notes, filterStatus) => {
  //   try {
  //     dispatch(putProjectList(id, type, notes, filterStatus));
  //     enqueueSnackbar('Banker Approved Successfully');
  //   } catch (error) {
  //     enqueueSnackbar(error.message);
  //   }
  // };
  // const handleRejectUser = (id, type, notes, filterStatus) => {
  //   try {
  //     dispatch(putProjectList(id, type, notes, filterStatus));
  //     enqueueSnackbar('Banker Rejected Successfully');
  //   } catch (error) {
  //     enqueueSnackbar(error.message);
  //   }
  // };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
    rowsPerPage,
    currentPage,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  return (
    <Page title="Trades">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Clients Trade"
          links={[
            { name: 'Dashboard', href: `/${location.pathname.slice(1).split('/')[0]}` },
            {
              name: 'Trades',
            },
          ]}
        />
        <ClientLineTradesToolbar
          filterName={filterName}
          filterRole={filterRole}
          onFilterName={(searcObj) => {
            handleFilterName(searcObj);
          }}
          timeDifference={timeDifference}
          onTimeDifferenceChange={(value) => handleTimeDiffereneChange(value)}
          timeOptions={TIME_OPTIONS}
          searchPlaceholder="Search Trade"
        />
        <Card>
          {/* <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={(e, value) => {
              setCurrentTab(value);
              // setPageCurrent(1);
              // setRowsPerPage(2);
              // if (filterRole === 'all') {
              //   dispatch(getProjectList(rowsPerPage, 1, value, order));
              // } else {
              //   dispatch(getArchivedProjects(rowsPerPage, 1, value, order));
              // }
            }}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs>

          <Divider /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD_LINETRADES}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={(id) => {
                    onSort(id);
                    dispatch(
                      getClientLineTrades(
                        rowsPerPage,
                        1,
                        id,
                        order === 'asc' ? 'desc' : 'asc',
                        filterName,
                        timeDifference
                      )
                    );
                    setOrder(order === 'asc' ? 'desc' : 'asc');
                  }}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {ClientsData?.isLoading && <TableLoader />}
                  {/* {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => ( */}
                  {dataFiltered?.length > 0 &&
                    !ClientsData?.isLoading &&
                    dataFiltered.map((row) => (
                      <OperatorProjectTableRow
                        key={row?._id}
                        row={row}
                        filterStatus={filterStatus}
                        filterRole={filterRole}
                        selected={selected.includes(row?._id)}
                        onSelectRow={() => onSelectRow(row?._id)}
                        type={'LINETRADES'}
                        // onViewRow={() => handleViewRow(row?._id)}
                        // approveUser={() =>

                        //   handleApproveUser(row?._id, 'approve', 'You are Approved by Admin', filterStatus)
                        // }
                        // rejectUser={() =>
                        //   handleRejectUser(
                        //     row?._id,
                        //     'reject',
                        //     'Your Profile has some illegal data that is the reason to reject you',
                        //     filterStatus
                        //   )
                        // }
                      />
                    ))}

                  {/* {dataFiltered?.length === 0 && ClientsData?.isLoading && <TableLoader />} */}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                  {dataFiltered?.length === 0 && !ClientsData?.isLoading && <TableNoData isNotFound={isNotFound} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={ClientsData?.clientTrades?.totalDocs || 25}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={false}
              page={ClientsData?.clientTrades?.page !== null ? ClientsData?.clientTrades?.page - 1 : 0}
              onPageChange={(e) => {
                if (e.target.dataset.testid === 'KeyboardArrowRightIcon') {
                  setCurrentPage(ClientsData?.clientTrades?.page + 1);
                  if (filterRole === 'all') {
                    dispatch(
                      getClientLineTrades(
                        rowsPerPage,
                        ClientsData?.clientTrades?.page + 1,
                        orderBy,
                        order,
                        filterName,
                        timeDifference
                      )
                    );
                  } else {
                    dispatch(
                      getClientLineTrades(
                        rowsPerPage,
                        ClientsData?.clientTrades?.page + 1,
                        orderBy,
                        order,
                        filterName,
                        timeDifference
                      )
                    );
                  }
                } else {
                  setCurrentPage(ClientsData?.page - 1);
                  if (filterRole === 'all') {
                    dispatch(
                      getClientLineTrades(
                        rowsPerPage,
                        ClientsData?.clientTrades?.page - 1,
                        orderBy,
                        order,
                        filterName,
                        timeDifference
                      )
                    );
                  } else {
                    dispatch(
                      getClientLineTrades(
                        rowsPerPage,
                        ClientsData?.clientTrades?.page - 1,
                        orderBy,
                        order,
                        filterName,
                        timeDifference
                      )
                    );
                  }
                }
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(event.target.value);
                dispatch(getClientLineTrades(event.target.value, 1, orderBy, order, filterName, timeDifference));
              }}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName, filterStatus }) {
  // const stabilizedThis = tableData.map((el, index) => [el, index]);

  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });

  // tableData = stabilizedThis.map((el) => el[0]);

  // if (filterName) {
  //   tableData = tableData.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  // }

  // if (filterStatus !== 'all') {
  //   tableData = tableData.filter((item) => item.status === filterStatus);
  // }

  // if (filterRole !== 'all') {
  //   tableData = tableData.filter((item) => item.role === filterRole);
  // }
  console.log('TABLE DTA:', tableData, comparator, filterName, filterStatus);
  return tableData;
}
