import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import axios from '../../../utils/axios';
import Page from '../../../components/Page';
import useSettings from '../../../hooks/useSettings';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import LoadingScreen from '../../../components/LoadingScreen';
import ActionTableCell from '../../../sections/@dashboard/user/list/ActionTableCell';
import useAuth from '../../../hooks/useAuth';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { fColorNumber, fNumber } from '../../../utils/formatNumber';
import TradesTable from './TradesTable';
import Label from '../../../components/Label';

const ClientProfileWithTrade = () => {
  const { clientId } = useParams();
  const [profileData, setProfileData] = useState(null);
  const { user } = useAuth();
  // const theme = useTheme();
  const navigate = useNavigate();
  const { themeStretch } = useSettings();
  const location = useLocation();

  const handleUpdateSuccess = () => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(`/user/clients/profile/${clientId}`);
        setProfileData(response.data.data);
      } catch (error) {
        console.error('Error fetching client profile data:', error);
      }
    };

    fetchProfileData();
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(`/user/clients/profile/${clientId}`);
        setProfileData(response.data.data);
      } catch (error) {
        console.error('Error fetching client profile data:', error);
      }
    };

    fetchProfileData();
  }, [clientId]);

  const handleDuplicateRow = () => {
    const id = profileData.user._id;
    if (user.role.rolename === 'broker') {
      navigate(PATH_DASHBOARD.operatorRole.clients.edit(id));
    }
    if (user.role.rolename === 'admin') {
      navigate(PATH_DASHBOARD.insurerRole.broker.client.duplicate(id));
    }
  };

  const handleEditRow = () => {
    const id = profileData.user._id;
    if (user.role.rolename === 'broker') {
      navigate(PATH_DASHBOARD.operatorRole.clients.edit(id));
    }
    if (user.role.rolename === 'admin') {
      navigate(PATH_DASHBOARD.insurerRole.client.edit(id));
    }
  };

  if (!profileData) {
    return <LoadingScreen />;
  }

  const grossPl =
    profileData.accountSummary.totalProfit +
    profileData.accountSummary.activeProfitLoss -
    profileData.accountSummary.totalBrokerage;

  const detailFields = [
    {
      label: 'Total Brokerage',
      value: profileData.accountSummary?.totalBrokerage || 0,
      color: 'default',
    },
    {
      label: 'Refund Brokerage',
      value: profileData.accountSummary?.brokerage?.refund || 0,
      color: 'default',
    },
    {
      label: 'Booked Profit/Loss',
      value: profileData.accountSummary?.totalProfit || 0,
      color: 'defult',
    },
    {
      label: 'Active Profit/Loss',
      value: profileData.accountSummary?.activeProfitLoss || 0,
      color: 'default',
    },
    {
      label: 'Available Balance',
      value: profileData.accountSummary?.availableBalance || 0,
      color: 'default',
    },
    {
      label: 'Used Margin',
      value: profileData.accountSummary?.blockedMargin || 0,
      color: 'default',
    },
  ];

  return (
    <Page title="Profile">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={
            profileData?.user?.username
              ? `${profileData?.user?.username} ${
                  profileData?.user?.firstname || profileData?.user?.lastname
                    ? ` ( ${profileData?.user?.firstname ?? ''} ${profileData?.user?.lastname ?? ''})`
                    : ''
                }`
              : 'user'
          }
          links={[
            { name: 'Dashboard', href: `/${location.pathname.slice(1).split('/')[0]}` },
            ...(clientId ? [{ name: profileData.user.username }] : []),
          ]}
          action={
            <ActionTableCell
              key={1}
              row={profileData.user}
              onDuplicateRow={handleDuplicateRow}
              onEditRow={handleEditRow}
              onUserUpdateSuccess={handleUpdateSuccess}
              showFullButton
            />
          }
        />

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', gap: 1 }}>
              <Label color="default" variant="ghost" sx={{ color: 'text.primary' }}>
                <Typography variant="h7"> Credit: {fNumber(profileData.user.balance)}</Typography>
              </Label>
              <Label
                variant="ghost"
                color={grossPl > 0 ? 'default' : 'default'}
                sx={{
                  color: fColorNumber(grossPl),
                }}
              >
                <Typography variant="h7">Gross PL: {fNumber(grossPl)}</Typography>
              </Label>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {detailFields.map((field) => (
              <Box
                key={field.label}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 1,
                }}
              >
                <Typography variant="body2">{field.label}:</Typography>
                <Label
                  color={'default'}
                  variant="ghost"
                  sx={{
                    color: ['Total Brokerage', 'Refund Brokerage'].includes(field.label)
                      ? 'text.primary'
                      : fColorNumber(field.value),
                  }}
                >
                  <Typography variant="h7">{fNumber(field.value)}</Typography>
                </Label>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>

        <TradesTable
          trades={profileData.trades}
          onUpdateSuccess={handleUpdateSuccess}
          portfolio={profileData.portfolio}
          balanceDetail={profileData.accountSummary}
        />
      </Container>
    </Page>
  );
};

export default ClientProfileWithTrade;
