import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, Grid, Typography, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { Wrapper } from '../../../sections/@dashboard/Trade/watchlist/BuyScriptDrawer';
import axios from '../../../utils/axios';
// import { fNumber } from '../../../utils/formatNumber';

const AddBalanceModal = ({ open, onClose, balanceSummary, onUserUpdate }) => {
  const [amount, setAmount] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [creditloading, setCreditloading] = useState(false);
  const [debitloading, setDebitloading] = useState(false);

  const numberFormatter = new Intl.NumberFormat('en-IN', {
    maximumFractionDigits: 0,
  });

  const credit = Number(balanceSummary?.balance ?? 0);
  const availableBalance =
    credit +
    Number(balanceSummary?.activeProfitLoss ?? 0) +
    Number(balanceSummary?.totalProfit ?? 0) -
    Number(balanceSummary?.totalBrokerage ?? 0);
  const availableMargin =
    credit +
    Number(balanceSummary?.activeProfitLoss ?? 0) +
    Number(balanceSummary?.totalProfit ?? 0) -
    Number(balanceSummary?.blockedMargin ?? 0) -
    Number(balanceSummary?.totalBrokerage ?? 0);

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleCredit = async () => {
    try {
      //   const response = await dispatch(updateUserBalance(balanceSummary?.trader?._id, amount, 'credit'));
      setCreditloading(true);
      const response = await axios.put(`/user/balance/update`, {
        userId: balanceSummary?.trader?._id,
        amount,
        operation: 'credit',
      });
      enqueueSnackbar(response.data.message);
      setCreditloading(false);
      onUserUpdate();
      onClose();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
      setCreditloading(false);
    }
  };

  const handleDebit = async () => {
    try {
      //   const response = await dispatch(updateUserBalance(balanceSummary?.trader?._id, amount, 'credit'));
      setDebitloading(true);
      const response = await axios.put(`/user/balance/update`, {
        userId: balanceSummary?.trader?._id,
        amount,
        operation: 'debit',
      });
      enqueueSnackbar(response.data.message);
      setDebitloading(false);
      onUserUpdate();
      onClose();
    } catch (err) {
      setDebitloading(false);
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <Wrapper open={open} onClose={onClose}>
      <Box width={{ xs: '100%', sm: '350px' }} marginX={'auto'}>
        <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" gutterBottom textTransform="uppercase">
          {balanceSummary?.trader?.username}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Credit: {numberFormatter.format(credit)}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Available Balance: {numberFormatter.format(availableBalance)}
        </Typography>
        <Typography variant="subtitle1" gutterBottom style={{ color: availableMargin >= 0 ? 'green' : 'red' }}>
          Available Margin: {numberFormatter.format(availableMargin)}
        </Typography>

        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Amount"
              variant="outlined"
              value={amount}
              onChange={handleAmountChange}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              variant="contained"
              size="large"
              color="success"
              onClick={handleCredit}
              loading={creditloading}
              sx={{ textTransform: 'uppercase', color: 'white' }}
            >
              Deposit
            </LoadingButton>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              variant="contained"
              color="error"
              size="large"
              onClick={handleDebit}
              loading={debitloading}
              sx={{ textTransform: 'uppercase' }}
            >
              Withdrawal
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
};
AddBalanceModal.propTypes = {
  balanceSummary: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUserUpdate: PropTypes.func,
};
export default AddBalanceModal;
