import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';

// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
// import UserAddForm from '../../sections/@dashboard/user/UserAddForm';
// import BrokerAddForm from '../../sections/@dashboard/user/BrokerAddForm';
// import GeneralUserAddForm from '../../sections/@dashboard/user/GeneralUserAddForm';
import ClientAddForm from '../../sections/@dashboard/user/ClientAddForm';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

UserCreate.propTypes = {
  userType: PropTypes.string,
  path: PropTypes.string,
  postSubmitData: PropTypes.func,
  userList: PropTypes.arrayOf(PropTypes.object),
};

export default function UserCreate({ userType, path, postSubmitData, userList }) {
  const { themeStretch } = useSettings();

  const { pathname } = useLocation();

  const { userId } = useParams();
  const location = useLocation();

  const isEdit = pathname.includes('edit');
  const isDuplicate = pathname.includes('duplicate');
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    if (isEdit || isDuplicate) {
      // Fetch user data using the userId
      const fetchUserData = async () => {
        try {
          // Make an API call to get user data using the userId available in params
          const response = await axios.get(`/user/profile/${userId}`); // Replace '/user/profile/${userId}' with your API endpoint

          const { user } = response.data; // Assuming response.data contains user data
          setCurrentUser(user);
        } catch (error) {
          // console.error('Error fetching user data:', error.message);
          // Handle error if needed
        }
      };
      fetchUserData();
    }
  }, [isEdit, userId, isDuplicate]);

  return (
    <Page title={`User: Create ${userType}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? `Create ${userType}` : `Edit ${userType}`}
          links={[
            { name: 'Dashboard', href: `/${location.pathname.slice(1).split('/')[0]}` },
            { name: userType, href: path },
            { name: isEdit ? currentUser?.username : `New ${userType}` },
          ]}
        />
        {/* <UserAddForm
          submitForm={postSubmitData}
          userType={userType}
          navigatePath={path}
          isEdit={isEdit}
          currentUser={currentUser}
          userList={userList}
        /> */}
        {/* {userType === 'Client' ? (
          <ClientAddForm
            submitForm={postSubmitData}
            userType={userType}
            navigatePath={path}
            isEdit={isEdit}
            currentUser={currentUser}
            userList={userList}
          />
        ) : (
          <BrokerAddForm
            submitForm={postSubmitData}
            userType={userType}
            navigatePath={path}
            isEdit={isEdit}
            currentUser={currentUser}
            userList={userList}
          />
        )} */}
        <ClientAddForm
          submitForm={postSubmitData}
          userType={userType}
          navigatePath={path}
          isEdit={isEdit}
          currentUser={currentUser}
          userList={userList}
          isDuplicate={isDuplicate}
        />
      </Container>
    </Page>
  );
}
