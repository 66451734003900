import React, { useState } from 'react';
import { Typography, Box, IconButton, Grid, Button } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
// import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import axiosInstance from '../../../utils/axios';
import { Wrapper } from '../../../sections/@dashboard/Trade/watchlist/BuyScriptDrawer';

const RefreshBrokerageModal = ({ client, open, onClose, onUserUpdate }) => {
  const [loading, setLoading] = useState(false);
  //   const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleRefreshMargin = async () => {
    try {
      setLoading(true);
      const url =
        client.role.rolename === 'client'
          ? `trade/refresh-brokerage/${client._id}`
          : `trade/refresh-brokerage/all-clients/${client._id}`;
      const response = await axiosInstance.put(url);
      // const response = await axiosInstance.put(`/trade/refresh-brokerage/${client._id}`);
      enqueueSnackbar(response.data.message, { variant: 'success' });
      setLoading(false);
      onUserUpdate();
      onClose();
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <Wrapper
      open={open}
      onClose={onClose}
      children={
        <Box width={{ xs: '100%', sm: '350px' }} marginX={'auto'}>
          <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" textAlign="center" pb={2}>
            Refresh Brokerage
          </Typography>
          <Typography variant="body1" textAlign="center" pb={2}>
            Are you sure you want to Refresh Brokerage?
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <LoadingButton
                variant="contained"
                fullWidth
                size="large"
                onClick={handleRefreshMargin}
                color="primary"
                loading={loading}
              >
                Yes
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <Button variant="outlined" fullWidth size="large" onClick={onClose}>
                No
              </Button>
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
};

RefreshBrokerageModal.propTypes = {
  client: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUserUpdate: PropTypes.func,
};

export default RefreshBrokerageModal;
