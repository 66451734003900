import { useSelector } from 'react-redux';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// redux-slice
import { dispatch } from '../../../redux/store';
import { postOperator } from '../../../redux/slices/operator';

// components
import UserCreate from '../UserCreate';

// eslint-disable-next-line react/prop-types
export default function ClientNew({ path }) {
  const operatorData = useSelector((state) => state?.operator?.operatorData);

  async function addOperator(payload, isEdit, userId) {
    await dispatch(postOperator(payload, isEdit, userId));
  }
  return (
    <>
      <UserCreate
        postSubmitData={(payload, isEdit, userId) => addOperator(payload, isEdit, userId)}
        userType="Client"
        path={path || PATH_DASHBOARD.insurerRole.broker.root}
        userList={operatorData}
      />
    </>
  );
}
