import axios from 'axios';

// config
import { HOST_API } from '../config';
import { setSession } from './jwt';

axios.defaults.baseURL = HOST_API;

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: HOST_API,
});
let cancelTokenSource;
let lastRequestBaseUrl = '';

const getBaseUrl = (url) => {
  const urlObject = new URL(url, window.location.origin); // Use window.location.origin to handle relative URLs
  return urlObject.pathname;
  // return url;
};

// Request interceptor to handle request cancellations
axiosInstance.interceptors.request.use(
  (config) => {
    // Extract the base URL of the current request
    // const currentRequestBaseUrl = config.url;
    const currentRequestBaseUrl = getBaseUrl(config.url);

    // Check if there is an existing cancel token and the base URL matches the last request base URL
    if (cancelTokenSource && lastRequestBaseUrl === currentRequestBaseUrl) {
      cancelTokenSource.cancel('Canceling previous request to the same base URL');
    }

    // Create a new cancel token for the current request
    cancelTokenSource = axios.CancelToken.source();

    // Attach the cancel token to the request config
    config.cancelToken = cancelTokenSource.token;

    // Update the last request base URL
    // lastRequestBaseUrl = '';
    lastRequestBaseUrl = currentRequestBaseUrl;

    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('ERROR', error);
    if (error.response && error.response.data.logout) {
      // Log out the user and clear the session
      setSession(null);
      // Redirect the user to the login page or perform any other actions
      window.location.href = '/'; // Example: Redirect to the login page
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
