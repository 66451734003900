import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// import AdminImage from '../assets/images/admin.jpg';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  userType: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, userType } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      userType,
    };
  },
  LOGIN: (state, action) => {
    const { user, userType } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      userType,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    userType: null,
  }),
  REGISTER: (state, action) => {
    const { user, userType } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      userType,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  initialize: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // const initialize = async () => {
    //   try {
    //     const accessToken = localStorage.getItem('accessToken');

    //     if (accessToken && isValidToken(accessToken)) {
    //       setSession(accessToken);
    //       const response = await axios.get('/user/profile');
    //       const { user, userType } = response.data;

    //       dispatch({
    //         type: 'INITIALIZE',
    //         payload: {
    //           isAuthenticated: true,
    //           user,
    //           userType,
    //         },
    //       });
    //     } else {
    //       dispatch({
    //         type: 'INITIALIZE',
    //         payload: {
    //           isAuthenticated: false,
    //           user: null,
    //         },
    //       });
    //     }
    //   } catch (err) {
    //     console.error(err);
    //     dispatch({
    //       type: 'INITIALIZE',
    //       payload: {
    //         isAuthenticated: false,
    //         user: null,
    //       },
    //     });
    //   }
    // };

    initialize();
  }, []);

  const initialize = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axios.get('/user/profile');
        const { user, userType } = response.data;

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user,
            userType,
          },
        });
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  const login = async (username, password) => {
    const response = await axios.post('/user/login', {
      username,
      password,
    });

    const { token, user, userType } = response.data;
    setSession(token);

    localStorage.setItem('userType', JSON.stringify(userType));
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        userType,
      },
    });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        initialize,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
