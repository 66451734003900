// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets
import { PDFUpload, ImageUpload } from '../../assets';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function BlockContent({ typeUpload }) {
  return (
    <Stack
      spacing={1}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      {/* <UploadIllustration sx={{ width: 220 }} /> */}
      <Box sx={{ p: 3, display: 'flex', rowGap: '5px', flexDirection: 'column', alignItems: 'center' }}>
        {typeUpload === 'image' ? <ImageUpload height="40px" width="40px" /> : <PDFUpload height="40px" width="40px" />}
        <Typography variant="h6">
          {`Drop your ${typeUpload === 'image' ? 'Images' : 'PDFs'} here, or `}
          <Typography variant="body1" component="span" sx={{ color: 'primary.main' }}>
            browse
          </Typography>
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'center' }}>
          Support {typeUpload === 'image' ? 'JPG, JPEG, PNG' : 'PDFs'}
        </Typography>
      </Box>
    </Stack>
  );
}
