import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

// @mui
import {
  Box,
  // Tab,
  // Tabs,
  Card,
  Table,
  // Switch,
  // Button,
  Tooltip,
  // Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  Button,
  FormControlLabel,
  Switch,
  // FormControlLabel,
} from '@mui/material';

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// hooks
import useTabs from '../../../hooks/useTabs';
import useSettings from '../../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../../hooks/useTable';

// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../../components/table';
import TableLoader from '../../../components/table/TableLoader';

// sections
import { UserTableToolbar, UserTableRow } from '../../../sections/@dashboard/user/list';

// redux-slice
import { dispatch } from '../../../redux/store';
import { getClients, getOperatorProfile, putOperator } from '../../../redux/slices/operator';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

// const STATUS_OPTIONS = ['all', 'active', 'pending', 'banned', 'rejected'];

const ROLE_OPTIONS = [
  'all',
  'ux designer',
  'full stack designer',
  'backend developer',
  'project manager',
  'leader',
  'ui designer',
  'ui/ux designer',
  'front end developer',
  'full stack developer',
];

// const TABLE_HEAD = [
//   { id: 'name', label: 'Name', align: 'left' },
//   { id: 'organization', label: 'Organization', align: 'left' },
//   { id: 'mobileNumber', label: 'Contact Number', align: 'left' },
//   { id: 'role', label: 'Role', align: 'left' },
//   { id: 'createdDate', label: 'Created At', align: 'left' },
//   { id: 'isVerified', label: 'Verified', align: 'center' },
//   { id: 'status', label: 'Status', align: 'left' },
//   { id: '' },
// ];

const TABLE_HEAD = [
  { id: '' },
  { id: 'username', label: 'Username', align: 'left' },
  { id: 'firstname', label: 'Full Name', align: 'left' },
  { id: 'manager.username', label: 'Broker', align: 'left' },
  { id: 'balance', label: 'balance', align: 'left' },
  { id: 'bookedPl', label: 'bookedPl', align: 'left' },
  { id: 'brokerage', label: 'brokerage', align: 'left' },
  { id: 'netPl', label: 'netPl', align: 'left' },
  { id: 'freeMargin', label: 'Free margin', align: 'left' },
  { id: 'isDemoAccount', label: 'Demo A/C', align: 'left' },
  { id: 'isActive', label: 'Status', align: 'left' },
  { id: 'createdAt', label: 'Created At', align: 'left' },
];
const BROKER_TABLE_HEAD = [
  { id: '' },
  { id: 'username', label: 'Username', align: 'left' },
  { id: 'firstname', label: 'Full Name', align: 'left' },
  // { id: 'manager.username', label: 'Broker', align: 'left' },
  { id: 'balance', label: 'balance', align: 'left' },
  { id: 'bookedPl', label: 'bookedPl', align: 'left' },
  { id: 'brokerage', label: 'brokerage', align: 'left' },
  { id: 'netPl', label: 'netPl', align: 'left' },
  { id: 'freeMargin', label: 'Free margin', align: 'left' },
  { id: 'isDemoAccount', label: 'Demo A/C', align: 'left' },
  { id: 'isActive', label: 'Status', align: 'left' },
  { id: 'createdAt', label: 'Created At', align: 'left' },
];

// ----------------------------------------------------------------------

export default function ClientList() {
  const {
    dense,
    page,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
  } = useTable({
    defaultRowsPerPage: 25,
    defaultOrderBy: 'createdAt',
    defaultOrder: 'desc',
  });
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [order, setOrder] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const { currentTab: filterStatus } = useTabs('all');

  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams();
  const operatorData = useSelector((state) => state?.operator);

  const tableData = operatorData?.clientsList;

  useEffect(() => {
    dispatch(
      getClients({
        userId: id,
        pageSize: 25,
        pageNumber: 1,
        filterStatus,
        sortOrder: order,
        sortBy: 'createdAt',
        searchWord: filterName,
      })
    );
    if (id) {
      dispatch(getOperatorProfile(id, false));
      //   breadcrumList.push({
      //     name: operatorData?.operatorProfile?.manager.username,
      //     href: PATH_DASHBOARD.insurerRole.broker.list(operatorData?.operatorProfile?.manager._id),
      //   });
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterName = (filterName) => {
    // if (cancelToken) {
    //   console.log('CANSSSSSSSSSSSS', cancelToken);
    //   cancelToken.cancel('CAncelling clients toekln');
    // }
    // const source = axios.CancelToken.source();
    // setCancelToken(source);
    try {
      dispatch(
        getClients({
          userId: id,
          pageSize: 25,
          pageNumber: 1,
          filterStatus,
          sortOrder: order,
          sortBy: orderBy,
          searchWord: filterName,
        })
      );
      setFilterName(filterName);
    } catch (error) {
      if (axios.isCancel(error) || error) {
        console.log(error);
      }
    }
  };
  const handleUpdateUserSuccess = () => {
    dispatch(
      getClients({
        userId: id,
        pageSize: 25,
        pageNumber: 1,
        filterStatus,
        sortOrder: order,
        sortBy: orderBy,
        searchWord: filterName,
      })
    );
  };
  const handleFilterRole = (name) => {
    setFilterRole(name);
    try {
      dispatch(
        getClients({ userId: id, pageSize: 25, pageNumber: 1, filterStatus, sortOrder: orderBy, sortBy: order })
      );
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  const handleDeleteRow = () => {
    // const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    // setTableData(deleteRow);
  };

  const handleDeleteRows = () => {
    // const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    // setTableData(deleteRows);
  };

  const handleEditRow = (id) => {
    // navigate(PATH_DASHBOARD.operatorRole.clients.edit(id));
    if (user.role.rolename === 'broker') {
      navigate(PATH_DASHBOARD.operatorRole.clients.edit(id));
    }
    if (user.role.rolename === 'admin') {
      // if (location.pathname.includes('broker')) {
      //   navigate(PATH_DASHBOARD.insurerRole.broker.client.edit(id));
      // } else {
      navigate(PATH_DASHBOARD.insurerRole.client.edit(id));
      // }
    }
  };
  const handleDuplicateRow = (id) => {
    // navigate(PATH_DASHBOARD.operatorRole.clients.edit(id));
    if (user.role.rolename === 'broker') {
      navigate(PATH_DASHBOARD.operatorRole.clients.edit(id));
    }
    if (user.role.rolename === 'admin') {
      navigate(PATH_DASHBOARD.insurerRole.broker.client.duplicate(id));
    }
  };
  const handleClickRow = (id) => {
    navigate(PATH_DASHBOARD.operatorRole.clients.view(id));
  };
  const handleViewRow = (id) => {
    dispatch(getOperatorProfile(id));
    // dispatch(getProjectByID(10, 1, 'all', 'des', id));
    if (user.role.rolename === 'broker') {
      navigate(PATH_DASHBOARD.operatorRole.clients.view(id));
    }
    if (user.role.rolename === 'admin') {
      if (location.pathname.includes('broker')) {
        navigate(PATH_DASHBOARD.insurerRole.broker.client.view(id));
      } else {
        navigate(PATH_DASHBOARD.insurerRole.client.view(id));
      }
    }
  };

  const handleBanUser = (id, type, notes, filterStatus) => {
    try {
      dispatch(putOperator(id, type, notes, filterStatus));
      enqueueSnackbar('Operator Banned Successfully');
    } catch (error) {
      enqueueSnackbar(error.message);
    }
  };
  const handleApproveUser = (id, type, notes, filterStatus) => {
    try {
      dispatch(putOperator(id, type, notes, filterStatus));
      enqueueSnackbar('Operator Approved Successfully');
    } catch (error) {
      enqueueSnackbar(error.message);
    }
  };
  const handleRejectUser = (id, type, notes, filterStatus) => {
    try {
      dispatch(putOperator(id, type, notes, filterStatus));
      enqueueSnackbar('Operator Rejected Successfully');
    } catch (error) {
      enqueueSnackbar(error.message);
    }
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
    rowsPerPage,
    currentPage,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);
  return (
    <Page title="Operator">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Client List"
          links={[
            { name: 'Dashboard', href: `/${location.pathname.slice(1).split('/')[0]}` },
            {
              name: 'client',
              href: PATH_DASHBOARD.operatorRole.clients.new,
            },
          ]}
          action={
            <>
              <Button
                variant="contained"
                component={RouterLink}
                to={
                  user.role.rolename === 'broker'
                    ? PATH_DASHBOARD.operatorRole.clients.new
                    : PATH_DASHBOARD.insurerRole.broker.client.new
                }
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                sx={{ mx: 2, textTransform: 'uppercase' }}
              >
                Create Client
              </Button>
            </>
          }
        />

        <Card>
          {/* <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={(e, value) => {
              setCurrentTab(value);
              // setPageCurrent(1);
              // setRowsPerPage(2);
              dispatch(getOperator(id || user?._id, rowsPerPage, 1, value, order));
            }}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs>

          <Divider /> */}

          <UserTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={(name) => {
              handleFilterName(name);
            }}
            onFilterRole={handleFilterRole}
            optionsRole={ROLE_OPTIONS}
            searchPlaceholder="Search Client"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={user.role.rolename === 'broker' ? BROKER_TABLE_HEAD : TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={(col) => {
                    onSort(col);
                    dispatch(
                      getClients({
                        userId: id,
                        pageSize: rowsPerPage,
                        pageNumber: 1,
                        searchWord: filterName,
                        filterName,
                        sortOrder: order === 'asc' ? 'desc' : 'asc',
                        sortBy: col,
                      })
                    );
                    setOrder(order === 'asc' ? 'desc' : 'asc');
                  }}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {/* {dataFiltered.(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => ( */}
                  {dataFiltered?.length > 0 &&
                    !operatorData?.isLoading &&
                    dataFiltered.map((row) => (
                      <UserTableRow
                        key={row?._id}
                        role={user.role.rolename}
                        row={row}
                        showEdit
                        filterStatus={filterStatus}
                        selected={selected.includes(row?._id)}
                        onSelectRow={() => onSelectRow(row?._id)}
                        onClick={() => handleClickRow(row?._id)}
                        onDeleteRow={() => handleDeleteRow(row?._id)}
                        onViewRow={() => handleViewRow(row?._id)}
                        onEditRow={() => handleEditRow(row?._id)}
                        onDuplicateRow={() => handleDuplicateRow(row?._id)}
                        onUserUpdateSuccess={() => handleUpdateUserSuccess()}
                        banUser={() =>
                          handleBanUser(
                            row?._id,
                            'ban',
                            'Your Profile has some illegal data that is the reason to ban you',
                            filterStatus
                          )
                        }
                        approveUser={() =>
                          handleApproveUser(row?._id, 'approve', 'You are Approved by Admin', filterStatus)
                        }
                        rejectUser={() =>
                          handleRejectUser(
                            row?._id,
                            'reject',
                            'Your Profile has some illegal data that is the reason to reject you',
                            filterStatus
                          )
                        }
                      />
                    ))}
                  {operatorData?.isLoading && <TableLoader />}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                  {dataFiltered?.length === 0 && !operatorData?.isLoading && <TableNoData isNotFound={isNotFound} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 75]}
              component="div"
              count={operatorData?.totalResults || 25}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={false}
              page={operatorData?.currentPage !== null ? operatorData?.currentPage - 1 : 0}
              onPageChange={(e) => {
                if (e.target.dataset.testid === 'KeyboardArrowRightIcon') {
                  setCurrentPage(operatorData?.currentPage + 1);
                  dispatch(
                    getClients({
                      userId: id,
                      pageSize: rowsPerPage,
                      pageNumber: +operatorData?.currentPage + 1,
                      searchWord: filterName,
                      filterName,
                      sortOrder: order,
                      sortBy: orderBy,
                    })
                  );
                } else {
                  if (currentPage === 1) return;
                  setCurrentPage(operatorData?.currentPage - 1);
                  dispatch(
                    getClients({
                      userId: id,
                      pageSize: rowsPerPage,
                      pageNumber: +operatorData?.currentPage - 1,
                      searchWord: filterName,
                      filterName,
                      sortOrder: order,
                      sortBy: orderBy,
                    })
                  );
                }
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setCurrentPage(1);
                dispatch(
                  getClients({
                    userId: id,
                    pageSize: parseInt(event.target.value, 10),
                    pageNumber: 1,
                    searchWord: filterName,
                    filterName,
                    sortOrder: order,
                    sortBy: orderBy,
                  })
                );
              }}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData }) {
  // Assuming the backend already handles sorting/filtering
  return tableData;
}
// function applySortFilter({ tableData, comparator, filterName, filterStatus, filterRole }) {
//   const stabilizedThis = tableData.map((el, index) => [el, index]);

//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });

//   tableData = stabilizedThis.map((el) => el[0]);

//   if (filterName) {
//     tableData = tableData.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
//   }

//   if (filterStatus !== 'all') {
//     tableData = tableData.filter((item) => item.status === filterStatus);
//   }

//   if (filterRole !== 'all') {
//     tableData = tableData.filter((item) => item.role === filterRole);
//   }

//   return tableData;
// }
