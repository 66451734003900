import React, { useEffect, useState } from 'react';
import { Tab, Tabs, AppBar, Box, Typography } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { useSelector } from 'react-redux';
import Page from '../../../../components/Page';
import { dispatch } from '../../../../redux/store';
import { getTradeHistory } from '../../../../redux/slices/trades';
import TradesRow from './TradesRow';
import TradeCard from '../portfolio/TradeCard';
import useTrade from '../../../../hooks/useTrade';
import PortfolioHeader from '../portfolio/PortFolioHeader';

const TradesPage = () => {
  const {
    tradeHistory,
    quotesData,
    balance,
    bookedPl,
    blockedMargin,
    brokerage,
    priceChangeConfig,
    showAccountDetails,
  } = useSelector((state) => state?.trade);
  const [tabValue, setTabValue] = useState(0); // Use numeric index for tab management
  const { localSocket } = useTrade();

  // Update tabValue when the user clicks on a tab
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  // Update tabValue when the user swipes to a new tab
  const handleSwipeChangeIndex = (index) => {
    setTabValue(index);
  };

  // Filter trade history based on the selected tab value
  const filteredTradeHistory = (() => {
    switch (tabValue) {
      case 0: // ACTIVE
        return tradeHistory
          .filter((trade) => trade.type === 'OPEN')
          .map((trade) => ({
            ...trade,
            script: { ...trade.script, ...quotesData[trade?.script?.instrument_token] },
          }));

      case 1: // LIMITS
        return tradeHistory
          .filter((trade) => ['EXECUTED', 'CANCELLED', 'PENDING'].includes(trade.type))
          .sort((a, b) => {
            // Define priority for each type
            const priority = { PENDING: 1, EXECUTED: 2, CANCELLED: 3 };
            // Compare based on the defined priority
            return priority[a.type] - priority[b.type];
          });

      case 2: // CLOSE
        return tradeHistory.filter((trade) => trade.type === 'ORDER');

      default:
        return tradeHistory; // Default case if none of the tabs match
    }
  })();

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getTradeHistory());
      } catch (error) {
        console.error('Error fetching trades:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const tokenArray =
      tradeHistory && tradeHistory.length > 0
        ? [
            ...tradeHistory
              .filter((trade) => ['OPEN', 'PENDING'].includes(trade.type))
              .map((trade) => +trade?.script?.instrument_token),
          ]
        : [];
    if (tokenArray.length) {
      const payload = tokenArray.map((token) => +token);

      const sendSubscribeMessage = () => {
        console.log('Sending subscribe message:', payload);
        localSocket.send(JSON.stringify({ event: 'subscribe', data: payload }));
      };

      if (localSocket) {
        if (localSocket.readyState === WebSocket.OPEN) {
          sendSubscribeMessage();
        } else {
          localSocket.onopen = () => {
            console.log('WebSocket connection opened');
            sendSubscribeMessage();
          };
        }
      } else {
        console.log('localSocket is not defined');
      }
    }

    return () => {
      if (localSocket && localSocket.readyState === WebSocket.OPEN) {
        if (tokenArray.length > 0) {
          console.log('Sending unsubscribe message:', tokenArray);
          localSocket.send(JSON.stringify({ event: 'unsubscribe', data: tokenArray.map((token) => +token) }));
        }
      }
    };
  }, [tradeHistory, localSocket, localSocket?.readyState]);

  return (
    <Page title="Portfolio" sx={{ px: 2 }}>
      <Box sx={{ width: '100%' }} mt={-1}>
        <PortfolioHeader
          balance={balance}
          bookedPL={bookedPl}
          blockedMargin={blockedMargin}
          brokerage={brokerage}
          activeTrades={tradeHistory
            .filter((trade) => trade.type === 'OPEN')
            .map((trade) => ({
              ...trade,
              script: { ...trade.script, ...quotesData[trade?.script?.instrument_token] },
            }))}
          priceChangeConfig={priceChangeConfig}
          showAccountDetails={showAccountDetails}
        />
      </Box>

      {/* Tab navigation */}
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          sx={{ width: '100%', display: 'flex' }}
        >
          <Tab label="ACTIVE" value={0} sx={{ flexGrow: 1, maxWidth: 'none' }} />
          <Tab label="LIMITS" value={1} sx={{ flexGrow: 1, maxWidth: 'none' }} />
          <Tab label="CLOSE" value={2} sx={{ flexGrow: 1, maxWidth: 'none' }} />
        </Tabs>
      </AppBar>

      {/* Swipeable views for tab content */}
      <SwipeableViews
        index={tabValue}
        onChangeIndex={handleSwipeChangeIndex}
        style={{ minHeight: '100vh' }} // Ensure minimum height for swipeable content
      >
        {/* Active Trades */}
        <Box role="tabpanel" hidden={tabValue !== 0} sx={{ minHeight: '100vh' }}>
          {filteredTradeHistory.length > 0 ? (
            filteredTradeHistory.map((trade) =>
              trade.type === 'OPEN' ? (
                <TradeCard
                  trade={{
                    ...trade,
                    script: { ...trade.script, ...quotesData[trade?.script?.instrument_token] },
                  }}
                  priceChangeConfig={priceChangeConfig}
                  key={trade._id}
                  closeFromTrade
                />
              ) : null
            )
          ) : (
            <Typography variant="h6" mt={2} ml={2}>
              No Active Trades
            </Typography>
          )}
        </Box>

        {/* Limits */}
        <Box role="tabpanel" hidden={tabValue !== 1} sx={{ minHeight: '100vh' }}>
          {filteredTradeHistory.length > 0 ? (
            filteredTradeHistory.map((trade) => {
              if (trade.type === 'PENDING') {
                return (
                  <TradeCard
                    key={trade._id}
                    trade={{
                      ...trade,
                      script: { ...trade.script, ...quotesData[trade?.script?.instrument_token] },
                    }}
                    priceChangeConfig={priceChangeConfig}
                    closeFromTrade
                  />
                );
              }
              if (['EXECUTED', 'CANCELLED'].includes(trade.type)) {
                return <TradesRow key={trade._id} trade={trade} showTime />;
              }
              return null;
            })
          ) : (
            <Typography variant="h6" mt={2} ml={2}>
              No Limits
            </Typography>
          )}
        </Box>

        {/* Closed Trades */}
        <Box role="tabpanel" hidden={tabValue !== 2} sx={{ minHeight: '100vh' }}>
          {filteredTradeHistory.length > 0 ? (
            filteredTradeHistory.map((trade) =>
              trade.type === 'ORDER' ? <TradesRow trade={trade} key={trade._id} showTime /> : null
            )
          ) : (
            <Typography variant="h6" mt={2} ml={2}>
              No Closed Trades
            </Typography>
          )}
        </Box>
      </SwipeableViews>
    </Page>
  );
};

export default TradesPage;
