// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
          },
          '&:nth-of-type(even)': {
            backgroundColor: theme.palette.background.newneutral,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
          '&[role="row"]:has(th.MuiTableCell-footer)': {
            // Target footer row
            backgroundColor: theme.palette.background.newneutral,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
        head: {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.neutral,
          borderCollapse: 'separate',
          '&:first-of-type': {
            // paddingLeft: theme.spacing(3),
            // borderTopLeftRadius: theme.shape.borderRadius,
            // borderBottomLeftRadius: theme.shape.borderRadius,
            // boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`,
          },
          '&:last-of-type': {
            // paddingRight: theme.spacing(3),
            // borderTopRightRadius: theme.shape.borderRadius,
            // borderBottomRightRadius: theme.shape.borderRadius,
            // boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`,
          },
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.newneutral,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.newneutral} 0%, ${theme.palette.background.newneutral} 100%)`,
        },
        body: {
          '&:first-of-type': {
            paddingLeft: theme.spacing(1),
          },
          '&:last-of-type': {
            // paddingRight: theme.spacing(3),
          },
          // '&:nth-of-type(odd)': {
          //   backgroundColor: theme.palette.background.default,
          // },
          // '&:nth-of-type(even)': {
          //   backgroundColor: theme.palette.background.newneutral,
          // },
        },
        footer: {
          backgroundColor: theme.palette.background.newneutral, // Apply to footer cells
          '&:first-of-type': {
            paddingLeft: theme.spacing(1),
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderBottom: `solid 1px ${theme.palette.divider}`,
          borderTop: `solid 1px ${theme.palette.divider}`,
          borderBottomLeftRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
        },
        toolbar: {
          height: 64,
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius,
          },
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: -4,
        },
      },
    },
    // MuiTable: {
    //   styleOverrides: {
    //     root: {
    //       borderTop: `solid 1px ${theme.palette.divider}`,
    //       borderTopLeftRadius: `1px solid ${theme.palette.divider}`,
    //       borderTopRightRadius: `1px solid ${theme.palette.divider}`,
    //       borderCollapse: 'separate',
    //     },
    //   },
    // },
  };
}
