import { createSlice } from '@reduxjs/toolkit';
import { symbol } from 'prop-types';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  bankerData: [],
  currentPage: null,
  totalPages: null,
  totalResults: null,
  bankerProfile: {},
};

const slice = createSlice({
  name: 'banker',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BANKERS
    getBankerListSuccess(state, action) {
      state.isLoading = false;
      state.bankerData = action?.payload?.users;
      state.totalPage = action?.payload?.totalPages;
      state.currentPage = action?.payload?.page;
      state.totalResults = action?.payload?.totalResults;
    },

    // POST BANKER
    postBankerSuccess(state) {
      state.isLoading = false;
    },

    // PUT BANKER
    updateBankerListSuccess(state, action) {
      state.isLoading = false;
      const { bankerID, filterStatus, newStatus } = action.payload;
      if (filterStatus === 'all') {
        const findIndex = state.bankerData.findIndex((data) => data._id === bankerID);
        state.bankerData[findIndex].status = newStatus;
      } else {
        state.bankerData = state.bankerData.filter((data) => data._id !== bankerID);
      }
    },

    // GET BANKER PROFILE
    getBankerProfileSuccess(state, action) {
      symbol.isLoading = false;
      state.bankerProfile = action?.payload?.user;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const bankerActions = slice.actions;

// ----------------------------------------------------------------------

// GET BANKER DETAILS
export function getBankers(pageSize, pageNumber, statusData, order, searchWord, cancelToken) {
  return async () => {
    let response;
    try {
      if (searchWord?.length > 0) {
        response = await axios.get(
          `/admin/user/?type=banker&status=${statusData}&order=${order}&pageSize=${pageSize}&page=${pageNumber}&search=${searchWord}`,
          { cancelToken: cancelToken.token }
        );
      } else {
        await dispatch(slice.actions.startLoading());
        response = await axios.get(
          `/admin/user/?type=banker&status=${statusData}&order=${order}&pageSize=${pageSize}&page=${pageNumber}`
        );
      }
      const { data, status, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.getBankerListSuccess(data));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
      if (axios.isCancel(error) || error) {
        // console.log(error);
      }
    }
  };
}

// PUT BANKERS

export function putBanker(bankerID, statusTab, notes, filterStatus) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/admin/user/${bankerID}?${statusTab}=true`);
      const { status, newStatus, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.updateBankerListSuccess({ bankerID, statusTab, filterStatus, newStatus }));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}

// POST BANKER

export function postBanker(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/admin/banker/add', { ...payload });
      const { status, message } = response?.data;
      if (status === 201) {
        dispatch(slice.actions.postBankerSuccess());
      } else {
        dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// GET BANKER PROFILE

export function getBankerProfile(bankerID) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/admin/user/${bankerID}`);
      const { status, data, message } = response?.data;
      if (status === 200) {
        dispatch(slice.actions.getBankerProfileSuccess(data));
      } else {
        dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
