/* eslint-disable react/prop-types */
import React, { useState } from 'react';

// import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Grid,
  Typography,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
} from '@mui/material';

import PriceWithColor from './PriceWithColor';
import useResponsive from '../../../../hooks/useResponsive';
import BuyScriptDrawer from './BuyScriptDrawer';
import modifyScriptName from '../../../../utils/modifyScriptName';
import { fNumber } from '../../../../utils/formatNumber';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: 'none',
  },
}));

const StaticWatchListItem = ({ filteredItems, user }) => {
  const isMobile = useResponsive('down', 'sm');
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedScript, setSelectedScript] = useState(null);
  const toggleDrawer = (script) => {
    if (user.role.rolename === 'client') {
      setSelectedScript(script);
      setShowDrawer((prevShowDrawer) => !prevShowDrawer); // Toggles the drawer state
    }
  };
  if (filteredItems.length === 0) {
    return (
      <Typography sx={{ minHeight: '100vh' }} textAlign={'center'}>
        {`No Script Added`}
      </Typography>
    );
  }

  return (
    <Grid item xs={12} sx={{ minHeight: '100vh' }}>
      {/* Wrap the list inside Droppable */}
      {isMobile ? (
        <Box sx={{ maxWidth: '100%' }}>
          {/* Header */}
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: '2fr 1fr 1fr',
              gap: 1,
              fontWeight: 'bold',
              marginBottom: '8px',
            }}
          >
            <Typography variant="body2" align="left">
              Symbol
            </Typography>
            <Typography variant="body2" align="right">
              Bid
            </Typography>
            <Typography variant="body2" align="right">
              Ask
            </Typography>
          </Box>

          {/* List */}
          <Grid container>
            {filteredItems.map((item) => (
              <Box key={item._id} sx={{ borderBottom: '0.5px solid grey', width: '100%' }}>
                <Grid item xs={12} key={item._id} onClick={() => toggleDrawer(item)}>
                  <Box sx={{ width: '100%' }}>
                    <Grid container pt={1}>
                      {/* Trading Symbol */}
                      <Grid item xs={6} sx={{ flex: '2' }}>
                        <Typography variant="body2" align="left" noWrap fontWeight={900}>
                          {modifyScriptName(item.tradingsymbol)}
                        </Typography>
                        {/* <Typography variant="body2" align="left" noWrap>
                          {item.tradingsymbol.slice(0, -8)} {item.tradingsymbol.slice(-6, -5)}
                          {item.tradingsymbol.slice(-5, -3).toLowerCase()}
                        </Typography> */}
                        {/* <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                          <Typography variant="caption"  align="left">
                            h: {item?.ohlc?.high || 0}
                          </Typography>
                          <Typography variant="caption"  align="left">
                            L: {item?.ohlc?.low || 0}
                          </Typography>
                        </Box> */}
                      </Grid>

                      {/* Bid */}
                      <Grid item xs={3} sx={{ flex: '1' }} textAlign={'end'}>
                        {/* <PriceWithColor price={+item?.last_price} variant={'h6'} /> */}
                        <PriceWithColor
                          price={
                            +item?.depth?.buy[0]?.price.toFixed(2) ||
                            (+item?.ohlc?.high ? +item?.ohlc?.close.toFixed(2) : 0)
                          }
                          variant={'h6'}
                        />
                      </Grid>

                      {/* Ask */}
                      <Grid item xs={3} sx={{ flex: '1' }} textAlign={'end'}>
                        <PriceWithColor
                          price={
                            +item?.depth?.sell[0]?.price.toFixed(2) ||
                            (+item?.ohlc?.high ? +item?.ohlc?.close.toFixed(2) : 0)
                          }
                          variant={'h6'}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sx={{ pb: 0.5 }}>
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4,1fr)' }}>
                    <Typography variant="caption" align="left">
                      Chg :
                      <Typography variant="caption" align="left" sx={{ color: `${+item?.chg < 0 ? 'red' : 'green'}` }}>
                        {fNumber(item?.chg?.toFixed(2) || 0)}
                      </Typography>
                    </Typography>
                    <Typography variant="caption" align="left">
                      LTP: {fNumber(item?.last_price || 0)}
                    </Typography>
                    <Typography variant="caption" align="right">
                      L: {fNumber(item?.ohlc?.low || 0)}
                    </Typography>
                    <Typography variant="caption" align="right">
                      H: {fNumber(item?.ohlc?.high || 0)}
                    </Typography>
                  </Box>
                </Grid>
              </Box>
            ))}
          </Grid>
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            {filteredItems.length > 0 && (
              <TableHead sx={{ textTransform: 'uppercase' }}>
                <TableRow>
                  <StyledTableCell> Symbol</StyledTableCell>
                  <StyledTableCell align="right"> Bid</StyledTableCell>
                  <StyledTableCell align="right">Ask</StyledTableCell>
                  <StyledTableCell align="right">LTP</StyledTableCell>
                  <StyledTableCell align="right">Low</StyledTableCell>
                  <StyledTableCell align="right">High</StyledTableCell>
                  <StyledTableCell align="right">CHG</StyledTableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {filteredItems.map((item) => (
                <StyledTableRow key={item._id} onClick={() => toggleDrawer(item)} sx={{ cursor: 'pointer' }}>
                  <TableCell component="th" scope="row">
                    {item.tradingsymbol.slice(0, -8)} {item.tradingsymbol.slice(-6, -5)}
                    {item.tradingsymbol.slice(-5, -3).toLowerCase()}
                  </TableCell>
                  <TableCell align="right" width="15%">
                    <PriceWithColor
                      price={
                        +item?.depth?.buy[0]?.price.toFixed(2) ||
                        (+item?.ohlc?.high ? +item?.ohlc?.close.toFixed(2) : 0)
                      }
                      variant={'h6'}
                    />
                  </TableCell>
                  <TableCell align="right" width="15%">
                    <PriceWithColor
                      price={
                        +item?.depth?.sell[0]?.price.toFixed(2) ||
                        (+item?.ohlc?.high ? +item?.ohlc?.close.toFixed(2) : 0)
                      }
                      variant={'h6'}
                    />
                  </TableCell>
                  <TableCell align="right">{fNumber(item?.last_price || 0)}</TableCell>
                  <TableCell align="right">{fNumber(item?.ohlc?.low || 0)}</TableCell>
                  <TableCell align="right">{fNumber(item?.ohlc?.high || 0)}</TableCell>
                  <TableCell align="right" sx={{ color: `${+item?.chg < 0 ? 'red' : 'green'}` }}>
                    {fNumber(item?.chg?.toFixed(2) || 0)}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {showDrawer && (
        <BuyScriptDrawer open={showDrawer} onClose={toggleDrawer} script={selectedScript} isMobile={isMobile} />
      )}
    </Grid>
  );
};

export default StaticWatchListItem;
