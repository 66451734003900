import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { MoreVert, Close, Delete, Edit, Restore } from '@mui/icons-material';
import modifyScriptName from '../../../utils/modifyScriptName';
import { fDateTime } from '../../../utils/formatTime';
import { fColorNumber, fNumber } from '../../../utils/formatNumber';

import DeleteTradeModal from './DeleteTradeModal';
import EditTradeModal from './EditTradeModal';
import CloseTradeModal from '../../../sections/@dashboard/Trade/portfolio/CloseTradeModal';
import RestoreTradeodal from './RestoreTradeodal';

OperatorProjectTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  type: PropTypes.string,
  role: PropTypes.string,
  onUpdateSuccess: PropTypes.func,
};

export default function OperatorProjectTableRow({ row, selected, type, role, onUpdateSuccess }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTrade, setSelectedTrade] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    script,
    trader,
    buy = '-',
    sell = '-',
    quantity,
    boughtAt,
    soldAt,
    boughtDeviceInfo = '-',
    soldDeviceInfo = '-',
    side,
    rate,
    executionSide,
    type: tradeType,
    createdAt,
    updatedAt,
    profit,
    brokerage,
    timeDifference,
  } = row;

  const handleMenuOpen = (event, trade) => {
    setAnchorEl(event.currentTarget);
    setSelectedTrade(trade);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    setModalType(action);
    setIsModalOpen(true);
    handleMenuClose();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalType(null);
  };

  if (type === 'PENDING') {
    return (
      <TableRow hover selected={selected}>
        <TableCell align="left" style={{ width: '10%', whiteSpace: 'nowrap' }}>
          {modifyScriptName(script.tradingsymbol)}
        </TableCell>
        <TableCell align="left" style={{ width: '10%' }}>
          {trader.username}
        </TableCell>
        <TableCell align="left" style={{ width: '10%' }}>
          {`${trader?.firstname || trader?.lastname ? ` ${trader?.firstname ?? ''} ${trader?.lastname ?? ''}` : '-'}`}
        </TableCell>
        <TableCell align="left" style={{ width: '10%' }}>
          {script.exchange.name}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', color: side?.toUpperCase() === 'SELL' ? 'red' : 'green' }}>
          {side?.toUpperCase()}
        </TableCell>
        <TableCell align="left" style={{ width: '10%' }}>
          {fNumber(rate)}
        </TableCell>
        <TableCell align="left" style={{ width: '10%' }}>
          {fNumber(quantity)}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', whiteSpace: 'nowrap' }}>
          {executionSide}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', color: tradeType === 'CANCELLED' ? 'red' : 'green' }}>
          {tradeType}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', whiteSpace: 'nowrap' }}>
          {createdAt ? fDateTime(createdAt) : '-'}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', whiteSpace: 'nowrap' }}>
          {updatedAt ? fDateTime(updatedAt) : '-'}
        </TableCell>
      </TableRow>
    );
  }

  if (type === 'LINETRADES') {
    return (
      <TableRow hover selected={selected}>
        <TableCell align="left" style={{ width: '10%' }}>
          {trader.username}
        </TableCell>
        <TableCell align="left" style={{ width: '10%' }}>
          {`${trader?.firstname || trader?.lastname ? ` ${trader?.firstname ?? ''} ${trader?.lastname ?? ''}` : '-'}`}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', whiteSpace: 'nowrap' }}>
          {modifyScriptName(script.tradingsymbol)}
        </TableCell>
        <TableCell align="left" style={{ width: '10%' }}>
          {script.exchange.name}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', color: 'green' }}>
          {fNumber(buy)}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', color: 'red' }}>
          {fNumber(sell)}
        </TableCell>
        <TableCell align="left" style={{ width: '10%' }}>
          {fNumber(quantity)}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', color: fColorNumber(profit - brokerage) }}>
          {fNumber(profit - brokerage)}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', whiteSpace: 'nowrap' }}>
          {boughtAt && soldAt ? fNumber(timeDifference) : '-'}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', whiteSpace: 'nowrap' }}>
          {boughtAt ? fDateTime(boughtAt) : '-'}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', whiteSpace: 'nowrap' }}>
          {soldAt ? fDateTime(soldAt) : '-'}
        </TableCell>
        <TableCell align="left" style={{ width: '10%' }}>
          {boughtDeviceInfo}
        </TableCell>
        <TableCell align="left" style={{ width: '10%' }}>
          {soldDeviceInfo}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <TableRow hover selected={selected}>
        {role === 'admin' && (
          <TableCell>
            <IconButton onClick={(event) => handleMenuOpen(event, row)}>
              <MoreVert />
            </IconButton>
          </TableCell>
        )}
        <TableCell align="left" style={{ width: '10%' }}>
          {trader.username}
        </TableCell>
        <TableCell align="left" style={{ width: '10%' }}>
          {`${trader?.firstname || trader?.lastname ? ` ${trader?.firstname ?? ''} ${trader?.lastname ?? ''}` : '-'}`}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', whiteSpace: 'nowrap' }}>
          {modifyScriptName(script.tradingsymbol)}
        </TableCell>
        <TableCell align="left" style={{ width: '10%' }}>
          {script.exchange.name}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', color: 'green' }}>
          {fNumber(buy)}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', color: 'red' }}>
          {fNumber(sell)}
        </TableCell>
        <TableCell align="left" style={{ width: '10%' }}>
          {fNumber(quantity)}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', whiteSpace: 'nowrap' }}>
          {boughtAt ? fDateTime(boughtAt) : '-'}
        </TableCell>
        <TableCell align="left" style={{ width: '10%', whiteSpace: 'nowrap' }}>
          {soldAt ? fDateTime(soldAt) : '-'}
        </TableCell>
        <TableCell align="left" style={{ width: '10%' }}>
          {boughtDeviceInfo}
        </TableCell>
        <TableCell align="left" style={{ width: '10%' }}>
          {soldDeviceInfo}
        </TableCell>
      </TableRow>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {tradeType === 'OPEN' && (
          <>
            <MenuItem onClick={() => handleMenuItemClick('close')}>
              <ListItemIcon>
                <Close color="primary" />
              </ListItemIcon>
              <ListItemText primary="Close" />
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('delete')}>
              <ListItemIcon>
                <Delete color="primary" />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('edit')}>
              <ListItemIcon>
                <Edit color="primary" />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </MenuItem>
          </>
        )}

        {tradeType === 'ORDER' && (
          <>
            <MenuItem onClick={() => handleMenuItemClick('delete')}>
              <ListItemIcon>
                <Delete color="primary" />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('edit')}>
              <ListItemIcon>
                <Edit color="primary" />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('restore')}>
              <ListItemIcon>
                <Restore color="primary" />
              </ListItemIcon>
              <ListItemText primary="Restore" />
            </MenuItem>
          </>
        )}
      </Menu>

      {isModalOpen && (
        <>
          {modalType === 'close' && (
            <CloseTradeModal
              isOpen={isModalOpen}
              onClose={handleModalClose}
              trade={selectedTrade}
              onUpdateSuccess={onUpdateSuccess}
              isManualTrade
              closeFromTrade
            />
          )}
          {modalType === 'delete' && (
            <DeleteTradeModal
              isOpen={isModalOpen}
              onClose={handleModalClose}
              trade={selectedTrade}
              onUpdateSuccess={onUpdateSuccess}
            />
          )}
          {modalType === 'edit' && (
            <EditTradeModal
              open={isModalOpen}
              onClose={handleModalClose}
              trade={selectedTrade}
              onUserUpdate={onUpdateSuccess}
            />
          )}
          {modalType === 'restore' && (
            <RestoreTradeodal
              isOpen={isModalOpen}
              onClose={handleModalClose}
              trade={selectedTrade}
              onUpdateSuccess={onUpdateSuccess}
            />
          )}
        </>
      )}
    </>
  );
}
