import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

RHFAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default function RHFAutoComplete({ name, options, label, placeholder, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) => {
            field.onChange(newValue);
          }}
          multiple
          options={options}
          getOptionLabel={(options) => options.name} // Use the 'name' field as the label
          // getOptionSelected={(options, value) => options._id === value._id} // Use '_id' field for comparison
          isOptionEqualToValue={(options, value) => options.name.toString() === value.name.toString()}
          disableCloseOnSelect
          value={field.value || []}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error?.message}
              variant="outlined"
              label={label || 'Multiple Autocomplete'}
              placeholder={placeholder || 'Multiple Autocomplete'}
              {...other}
            />
          )}
        />
      )}
    />
  );
}
