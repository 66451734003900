import { useContext } from 'react';
//
import { TradeContext } from '../contexts/TradeContext';

// ----------------------------------------------------------------------

const useTrade = () => {
  const context = useContext(TradeContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export default useTrade;
