// import PropTypes from 'prop-types';
// import { useState } from 'react';
// import { m } from 'framer-motion';

// // @mui
// import { useTheme } from '@mui/material/styles';
// import {
//   Avatar,
//   // Checkbox,
//   TableRow,
//   TableCell,
//   Typography,
//   MenuItem,
//   ListItem,
//   Box,
// } from '@mui/material';

// // assets
// import pdfUpload from '../../../assets/images/pdf.png';

// // components
// import Label from '../../../components/Label';
// import Image from '../../../components/Image';
// import Iconify from '../../../components/Iconify';
// import { TableMoreMenu } from '../../../components/table';

// // ----------------------------------------------------------------------

// OperatorProjectTableRow.propTypes = {
//   row: PropTypes.object,
//   selected: PropTypes.bool,
//   onViewRow: PropTypes.func,
//   approveUser: PropTypes.func,
//   rejectUser: PropTypes.func,
// };

// export default function OperatorProjectTableRow({ row, selected, onViewRow, approveUser, rejectUser }) {
//   const theme = useTheme();

//   const {
//     title,
//     address,
//     images,
//     saleskit,
//     avatarUrl,
//     created_at: createdAt,
//     // isVerified,
//     status,
//   } = row;

//   const [openMenu, setOpenMenuActions] = useState(null);

//   const handleOpenMenu = (event) => {
//     setOpenMenuActions(event.currentTarget);
//   };

//   const handleCloseMenu = () => {
//     setOpenMenuActions(null);
//   };

//   return (
//     <TableRow hover selected={selected}>
//       <TableCell>
//         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
//           <Avatar alt="" src={avatarUrl} sx={{ mr: 2 }} />
//           <Typography variant="subtitle2" noWrap>
//             {(title && title) || 'NA'}
//           </Typography>
//         </div>
//       </TableCell>

//       <TableCell align="right">{(address && address) || 'NA'}</TableCell>
//       <TableCell align="right">
//         {(images?.length > 0 && (
//           <Box sx={{ width: '100%' }}>
//             {images.map((data, index) => (
//               <ListItem
//                 key={index}
//                 component={m.div}
//                 // {...varFade().inRight}
//                 sx={{
//                   p: 0,
//                   m: 0.5,
//                   width: 80,
//                   height: 80,
//                   borderRadius: 1.25,
//                   overflow: 'hidden',
//                   position: 'relative',
//                   display: 'inline-flex',
//                   border: (theme) => `solid 1px ${theme.palette.divider}`,
//                 }}
//               >
//                 <Image alt="preview" src={`http://localhost:8080/${data}`} ratio="1/1" />
//               </ListItem>
//             ))}
//           </Box>
//         )) || (
//           <Typography variant="subtitle2" noWrap>
//             {'NA'}
//           </Typography>
//         )}
//       </TableCell>

//       <TableCell align="right" sx={{ textTransform: 'capitalize' }}>
//         {(saleskit?.length > 0 && (
//           <Box sx={{ width: '100%' }}>
//             <ListItem
//               component={m.div}
//               // {...varFade().inRight}
//               sx={{
//                 p: 0,
//                 m: 0.5,
//                 width: 80,
//                 height: 80,
//                 borderRadius: 1.25,
//                 overflow: 'hidden',
//                 position: 'relative',
//                 display: 'inline-flex',
//                 border: (theme) => `solid 1px ${theme.palette.divider}`,
//               }}
//             >
//               {/* <Image alt="preview" src={`http://localhost:8080/${saleskit}`} ratio="1/1" /> */}
//               <Image alt="preview" src={saleskit && pdfUpload} ratio="1/1" />
//             </ListItem>
//           </Box>
//         )) || (
//           <Typography variant="subtitle2" noWrap>
//             NA
//           </Typography>
//         )}
//       </TableCell>
//       <TableCell align="right">{(createdAt && new Date(createdAt).toLocaleDateString('en-GB')) || 'NA'}</TableCell>
//       <TableCell align="center">
//         <Iconify
//           // icon={isVerified ? 'eva:checkmark-circle-fill' : 'eva:clock-outline'}
//           icon={
//             (status === 'active' && 'eva:checkmark-circle-fill') ||
//             (status === 'pending' && 'eva:clock-outline') ||
//             (status === 'rejected' && 'eva:close-circle-outline') ||
//             (status === 'banned' && 'eva:alert-circle-outline')
//           }
//           sx={{
//             width: 20,
//             height: 20,
//             color: 'success.main',
//             // ...(!isVerified && { color: 'warning.main' }),
//             ...((status === 'pending' && { color: 'warning.main' }) ||
//               (status === 'rejected' && { color: 'reject.main' }) ||
//               (status === 'banned' && { color: 'error.main' })),
//           }}
//         />
//       </TableCell>

//       <TableCell align="right">
//         <Label
//           variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
//           color={
//             (status === 'banned' && 'error') ||
//             (status === 'pending' && 'warning') ||
//             (status === 'rejected' && 'reject') ||
//             'success'
//           }
//           sx={{
//             textTransform: 'capitalize',
//             // color: 'success.main',
//             // ...((status === 'banned' && { color: 'error.main' }) ||
//             //   (status === 'rejected' && { color: '#f57005' }) ||
//             //   (status === 'pending' && { color: 'warning.main' })),
//           }}
//         >
//           {status}
//         </Label>
//       </TableCell>

//       <TableCell align="right">
//         <TableMoreMenu
//           open={openMenu}
//           onOpen={handleOpenMenu}
//           onClose={handleCloseMenu}
//           actions={
//             <>
//               <MenuItem
//                 onClick={() => {
//                   onViewRow();
//                   handleCloseMenu();
//                 }}
//               >
//                 <Iconify icon={'eva:eye-fill'} />
//                 View
//               </MenuItem>
//               {status === 'pending' && (
//                 <MenuItem
//                   onClick={async () => {
//                     approveUser();
//                     handleCloseMenu();
//                   }}
//                   sx={{ color: `${status === 'active' ? 'error.main' : 'secondary.main'}` }}
//                 >
//                   <Iconify icon={status === 'active' ? 'eva:alert-circle-outline' : 'eva:checkmark-circle-2-outline'} />
//                   Approve
//                 </MenuItem>
//               )}
//               {status === 'pending' && (
//                 <MenuItem
//                   onClick={async () => {
//                     rejectUser();
//                     handleCloseMenu();
//                   }}
//                   sx={{ color: 'error.main' }}
//                 >
//                   <Iconify icon={'eva:close-circle-outline'} />
//                   Reject
//                 </MenuItem>
//               )}
//             </>
//           }
//         />
//       </TableCell>
//     </TableRow>
//   );
// }

import PropTypes from 'prop-types';
import { TableRow, TableCell, Typography, Button } from '@mui/material';
import modifyScriptName from '../../../utils/modifyScriptName';
// import { fDateTime } from '../../../utils/formatTime';
import { fColorNumber, fNumber } from '../../../utils/formatNumber';

ScriptWiseTradeTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  type: PropTypes.string,
  onViewRow: PropTypes.func,
};

export default function ScriptWiseTradeTableRow({ row, selected, type, onViewRow }) {
  const {
    script,
    buy = '-',
    sell = '-',
    profit,
    brokerage,
    quantity,
    totalBuyQuantity,
    totalSellQuantity,
    total,
    net,
    last_price: lastPrice,
    activeProfitLoss,
  } = row;
  if (type === 'ORDER') {
    return (
      <TableRow hover selected={selected}>
        <TableCell
          align="left"
          style={{ width: '10%', whiteSpace: 'nowrap', cursor: 'pointer', textTransform: 'uppercase' }}
          onClick={onViewRow}
        >
          <Button variant="contained" color="primary">
            <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>
              {modifyScriptName(script.tradingsymbol)}
            </Typography>
          </Button>
        </TableCell>
        <TableCell align="right" style={{ width: '10%' }}>
          <Typography color={fColorNumber(profit - brokerage)} variant="h6">
            {fNumber(profit - brokerage)}
          </Typography>
        </TableCell>
        <TableCell align="right" style={{ width: '10%' }}>
          {fNumber(buy)}
        </TableCell>
        <TableCell align="right" style={{ width: '10%' }}>
          {fNumber(sell)}
        </TableCell>
        <TableCell align="right" style={{ width: '10%' }}>
          <Typography color={fColorNumber(profit)}>{fNumber(profit)}</Typography>
        </TableCell>
        <TableCell align="right" style={{ width: '10%' }}>
          {fNumber(brokerage)}
        </TableCell>
        <TableCell align="right" style={{ width: '10%' }}>
          {fNumber(quantity)}
        </TableCell>
      </TableRow>
    );
  }

  if (type === 'OPEN') {
    return (
      <TableRow hover selected={selected}>
        {/* <TableCell align="right" style={{ width: '10%', whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={onViewRow}>
          {modifyScriptName(script.tradingsymbol)}
        </TableCell> */}
        <TableCell
          align="left"
          style={{ width: '10%', whiteSpace: 'nowrap', cursor: 'pointer', textTransform: 'uppercase' }}
          onClick={onViewRow}
        >
          <Button variant="contained" color="primary">
            <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>
              {modifyScriptName(script.tradingsymbol)}
            </Typography>
          </Button>
        </TableCell>
        <TableCell align="right" style={{ width: '10%', color: fColorNumber(net) }}>
          {fNumber(net)}
        </TableCell>
        <TableCell align="right" style={{ width: '10%' }}>
          {fNumber(totalBuyQuantity)}
        </TableCell>
        <TableCell align="right" style={{ width: '10%' }}>
          {fNumber(totalSellQuantity)}
        </TableCell>

        <TableCell align="right" style={{ width: '10%' }}>
          {fNumber(buy === 0 ? '-' : buy)}
        </TableCell>
        <TableCell align="right" style={{ width: '10%' }}>
          {fNumber(sell === 0 ? '-' : sell)}
        </TableCell>

        <TableCell align="right" style={{ width: '10%' }}>
          {fNumber(total)}
        </TableCell>
        <TableCell align="right" style={{ width: '10%' }}>
          {fNumber(lastPrice)}
        </TableCell>
        <TableCell align="right" style={{ width: '10%' }}>
          <Typography color={fColorNumber(activeProfitLoss)} variant="h6">
            {fNumber(activeProfitLoss)}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }
}
