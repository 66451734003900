import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Typography, AppBar, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import Page from '../../../../components/Page';
import { dispatch } from '../../../../redux/store';
import { getTradeHistory } from '../../../../redux/slices/trades';
import TradeHistoryRow from './TradeHistoryRow';

// eslint-disable-next-line react/prop-types
const HeaderItem = ({ label, value }) => (
  <Box display="flex" flexDirection={{ xs: 'row', sm: 'column' }} justifyContent={'space-between'} flexGrow={1}>
    <Typography variant="body1" color={'GrayText'}>
      {label}
    </Typography>
    <Typography variant="body1">{value}</Typography>
  </Box>
);

const TradeHistory = () => {
  const { tradeHistory, bookedPl, brokerage } = useSelector((state) => state?.trade);
  const [tabValue, setTabValue] = useState('OPEN'); // State for managing tab value

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  // Filter trade history based on the selected tab value
  const filteredTradeHistory =
    tabValue === 'OPEN'
      ? tradeHistory.filter((trade) => trade.type === 'OPEN')
      : tradeHistory.filter((trade) => ['ORDER', 'EXECUTED', 'CANCELLED'].includes(trade.type));

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getTradeHistory());
      } catch (error) {
        console.error('Error fetching trades:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Page title="Portfolio" sx={{ px: 2 }}>
      <Box sx={{ width: '100%', py: 2 }}>
        {/* <Typography variant="h2">TRADE HISTORY</Typography> */}
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, width: '100%' }}>
          <HeaderItem label={'Booked P/L'} value={bookedPl?.toFixed(2)} />
          <HeaderItem label={'Total Brokerage'} value={brokerage?.toFixed(2)} />
          <HeaderItem label={'Gross P/L'} value={(bookedPl - brokerage)?.toFixed(2)} />
        </Box>
      </Box>
      {/* Tab navigation */}
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          sx={{ width: '100%', display: 'flex' }}
        >
          <Tab label="OPEN" value="OPEN" sx={{ flexGrow: 1, maxWidth: 'none' }} />
          <Tab label="CLOSE" value="CLOSE" sx={{ flexGrow: 1, maxWidth: 'none' }} />
        </Tabs>
      </AppBar>
      {/* Trade history content based on the selected tab */}
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1,1fr)' }}>
        {filteredTradeHistory.length > 0 &&
          filteredTradeHistory.map((trade) => <TradeHistoryRow trade={trade} key={trade._id} />)}
      </Box>
    </Page>
  );
};

export default TradeHistory;
