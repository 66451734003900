/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Modal,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

const UserConfigInfo = ({ open, onClose, userName, traderConfigs, role }) => {
  const { user } = useAuth();

  const roleName =
    user.role.rolename === 'admin' ? 'ADMIN' : user.role.rolename === 'superAdmin' ? 'SUPER-ADMIN' : 'BROKER';
  const { profitLossType, profitLossShare, brokerProfitLossShare, superAdminProfitLossShare, NSE, MCX, tradeConfig } =
    traderConfigs || {};
  const [expandedShareInfo, setExpandedShareInfo] = useState(false);
  const [expandedNSE, setExpandedNSE] = useState(false);
  const [expandedMCX, setExpandedMCX] = useState(false);
  const [expandedUserConfigs, setExpandedUserConfigs] = useState(false);

  const handleExpandedShareInfo = () => setExpandedShareInfo(!expandedShareInfo);
  const handleExpandNSE = () => setExpandedNSE(!expandedNSE);
  const handleExpandMCX = () => setExpandedMCX(!expandedMCX);
  const handleExpandUserConfigs = () => setExpandedUserConfigs(!expandedUserConfigs);
  const formatNumber = (number) => number?.toLocaleString();

  const navigate = useNavigate();
  let navigateToURL = '';
  if (user.role.rolename === 'admin') {
    navigateToURL = role === 'non-broker' ? `/admin/broker/edit/` : `/admin/client/edit/`;
  } else if (user.role.rolename === 'superAdmin') {
    navigateToURL = `/superadmin/admin/edit/`;
  } else {
    navigateToURL = `/broker/client/edit/`;
  }
  const handleEdit = () => {
    navigate(`${navigateToURL}${traderConfigs?.user}`);
  };
  let ProfileViewColumn = '';
  if (user.role.rolename === 'superAdmin') {
    ProfileViewColumn = role === 'non-broker' ? `ADMIN: ${userName}` : `BROKER: ${userName}`;
  } else {
    ProfileViewColumn = role === 'non-broker' ? `BROKER: ${userName}` : `CLIENT: ${userName}`;
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          width: { xs: '90%', sm: '80%', md: '70%', lg: '60%', xl: '50%' }, // Dynamic width based on screen size
          maxWidth: { xs: '500px', md: '800px' }, // Adjust max width for larger screens
          padding: '24px',
          margin: 'auto',
          top: '10%',
          position: 'relative',
          overflowY: 'auto',
          maxHeight: '80vh',
        }}
      >
        <Typography variant="h6" align="center" gutterBottom style={{ textTransform: 'uppercase' }}>
          {ProfileViewColumn}
        </Typography>

        {/* SECTION FOR BROKER SETTINGS INFO */}
        {role === 'non-broker' && (
          <>
            {/* --------- SUPER-ADMIN VIEW --------- */}
            {user?.role?.rolename === 'superAdmin' && (
              <TableContainer>
                {/* TABLE FOR SAND-ADMIN P/L TYPE  SHARE */}
                <Table>
                  <TableRow>
                    <TableCell style={{ textTransform: 'uppercase' }}>
                      SUPER-ADMIN SHARING TYPE: {profitLossType === 'adminwise' ? 'adminwise' : 'brokerwise'}
                    </TableCell>
                    {profitLossType === 'adminwise' && (
                      <>
                        <TableCell style={{ textTransform: 'uppercase' }}>
                          S-ADMIN P/L SHARE: {profitLossShare === 0 ? 'NA' : `${profitLossShare}%`}
                        </TableCell>
                        <TableCell style={{ textTransform: 'uppercase' }}>
                          ADMIN P/L SHARE: {profitLossShare === 0 ? '100%' : `${100 - +profitLossShare}%`}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </Table>

                {/* ACCORDION FOR S-ADMIN BROKERAGE SHARE INFO */}
                {profitLossType === 'adminwise' && (
                  <Accordion
                    expanded={expandedShareInfo}
                    onChange={handleExpandedShareInfo}
                    sx={{ marginTop: '16px', alignSelf: 'center' }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography style={{ fontWeight: 'bold' }}>{`${roleName} BROKERAGE SHARE`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {+NSE?.brokerageValue === 0 &&
                      +MCX?.brokerageValue === 0 &&
                      +MCX?.brokerage?.metal === 0 &&
                      +MCX?.brokerage?.baseMetal === 0 &&
                      +MCX?.brokerage?.energy === 0 &&
                      +MCX?.brokerage?.miniMetal === 0 ? (
                        <Table>
                          <TableRow>
                            <TableCell
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                              }}
                            >
                              NA
                            </TableCell>
                          </TableRow>
                        </Table>
                      ) : (
                        <Table>
                          {NSE?.allow === true && (
                            <TableRow>
                              <TableCell style={{ textTransform: 'uppercase' }}>
                                NSE BROKERAGE TYPE: {NSE?.brokerageType}
                              </TableCell>
                              {NSE?.brokerageType === 'percentage' && (
                                <>
                                  <TableCell>
                                    SHARE: {`${+NSE?.brokerageValue === 0 ? 'NA' : `${NSE?.brokerageValue}%`}`}
                                  </TableCell>
                                </>
                              )}
                              {NSE?.brokerageType === 'crorebase' && (
                                <>
                                  <TableCell>
                                    SHARE: {`${+NSE?.brokerageValue === 0 ? 'NA' : `${NSE?.brokerageValue}/1CR`}`}
                                  </TableCell>
                                </>
                              )}
                            </TableRow>
                          )}
                          {MCX?.allow === true && (
                            <>
                              <TableRow>
                                <TableCell style={{ textTransform: 'uppercase' }}>
                                  MCX BROKERAGE TYPE: {MCX?.brokerageType}
                                </TableCell>
                                {MCX?.brokerageType === 'percentage' ? (
                                  <TableCell>
                                    SHARE: {`${+MCX?.brokerageValue === 0 ? 'NA' : `${MCX?.brokerageValue}%`}`}
                                  </TableCell>
                                ) : (
                                  <TableCell />
                                )}
                              </TableRow>
                              {MCX?.brokerageType === 'lotwise' && (
                                <>
                                  <TableRow>
                                    <TableCell>
                                      METAL: {+MCX?.brokerage?.metal === 0 ? 'NA' : MCX?.brokerage?.metal}
                                    </TableCell>
                                    <TableCell>
                                      MINI METAL: {+MCX?.brokerage?.miniMetal === 0 ? 'NA' : MCX?.brokerage?.miniMetal}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      ENERGY: {+MCX?.brokerage?.energy === 0 ? 'NA' : MCX?.brokerage?.energy}
                                    </TableCell>
                                    <TableCell>
                                      BASE METAL: {+MCX?.brokerage?.baseMetal === 0 ? 'NA' : MCX?.brokerage?.baseMetal}
                                    </TableCell>
                                  </TableRow>
                                </>
                              )}
                            </>
                          )}
                        </Table>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}

                {/* ACCORDION FOR S-ADMIN SET CONFIG INFO */}
                <Accordion
                  expanded={expandedUserConfigs}
                  onChange={handleExpandUserConfigs}
                  sx={{ marginTop: '16px', alignSelf: 'center' }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontWeight: 'bold' }}>SUPER-ADMIN CONFIGURATIONS</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>ALLOW-NSE:</TableCell>
                            <TableCell style={{ color: NSE?.allow === true ? 'green' : 'red', fontWeight: 'bold' }}>
                              {NSE?.allow === true ? 'YES' : 'NO'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>ALLOW-MCX:</TableCell>
                            <TableCell style={{ color: MCX?.allow === true ? 'green' : 'red', fontWeight: 'bold' }}>
                              {MCX?.allow === true ? 'YES' : 'NO'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>ALLOW PENDING-TRADES</TableCell>
                            <TableCell
                              style={{
                                color: tradeConfig?.allowPendingTrade === true ? 'green' : 'red',
                                fontWeight: 'bold',
                              }}
                            >
                              {tradeConfig?.allowPendingTrade === true ? 'YES' : 'NO'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </TableContainer>
            )}

            {/* --------- ADMIN VIEW --------- */}
            {user?.role?.rolename === 'admin' && (
              <TableContainer>
                {/* TABLE FOR ADMIN P/L TYPE AND SHARE */}
                <Table>
                  {user?.profitLossType === 'brokerwise' ? (
                    <Table>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>PROFIT/LOSS SHARE:</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{`ADMIN: ${+profitLossShare === 0 ? 'NA' : `${profitLossShare}%`}`}</TableCell>
                        <TableCell>{`SUPER-ADMIN: ${
                          +superAdminProfitLossShare === 0 ? 'NA' : `${superAdminProfitLossShare}%`
                        }`}</TableCell>
                        <TableCell>{`BROKER: ${
                          +brokerProfitLossShare === 0 ? 'NA' : `${brokerProfitLossShare}%`
                        }`}</TableCell>
                      </TableRow>
                    </Table>
                  ) : (
                    <Table>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>PROFIT/LOSS SHARE:</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ textTransform: 'uppercase' }}>
                          ADMIN: {profitLossShare === 0 ? 'NA' : `${profitLossShare}%`}
                        </TableCell>
                        <TableCell style={{ textTransform: 'uppercase' }}>
                          BROKER: {profitLossShare === 0 ? '100%' : `${100 - +profitLossShare}%`}
                        </TableCell>
                      </TableRow>
                    </Table>
                  )}
                </Table>

                {/* ACCORDION FOR ADMIN BROKERAGE SHARE */}
                {user?.profitLossType === 'brokerwise' ? (
                  <Accordion
                    expanded={expandedShareInfo}
                    onChange={handleExpandedShareInfo}
                    sx={{ marginTop: '16px', alignSelf: 'center' }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography style={{ fontWeight: 'bold' }}>{`${roleName} BROKERAGE SHARE`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        width: '100%',
                        maxWidth: '100%',
                        overflowX: 'auto',
                        '@media (max-width: 600px)': {
                          padding: '8px',
                        },
                      }}
                    >
                      {NSE?.allow === true && (
                        <Table>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                              NSE BROKERAGE INFO:
                            </TableCell>
                          </TableRow>
                          {NSE?.brokerageType === 'percentage' && (
                            <TableRow>
                              <TableCell style={{ textTransform: 'uppercase' }}>TYPE: {NSE?.brokerageType}</TableCell>
                              <TableCell>
                                ADMIN: {`${+NSE?.brokerageValue === 0 ? 'NA' : `${NSE?.brokerageValue}%`}`}
                              </TableCell>
                              <TableCell>
                                S-ADMIN:
                                {`${+NSE?.superAdminBrokerageValue === 0 ? 'NA' : `${NSE?.superAdminBrokerageValue}%`}`}
                              </TableCell>
                              <TableCell>
                                BROKER:
                                {`${+NSE?.brokerBrokerageValue === 0 ? 'NA' : `${NSE?.brokerBrokerageValue}%`}`}
                              </TableCell>
                            </TableRow>
                          )}
                          {NSE?.brokerageType === 'crorebase' && (
                            <TableRow>
                              <TableCell style={{ textTransform: 'uppercase' }}>TYPE: {NSE?.brokerageType}</TableCell>
                              <TableCell>
                                ADMIN: {`${+NSE?.brokerageValue === 0 ? 'NA' : `${NSE?.brokerageValue}/CR`}`}
                              </TableCell>
                              <TableCell>
                                S-ADMIN:
                                {`${
                                  +NSE?.superAdminBrokerageValue === 0 ? 'NA' : `${NSE?.superAdminBrokerageValue}/CR`
                                }`}
                              </TableCell>
                            </TableRow>
                          )}
                        </Table>
                      )}
                      {MCX?.allow === true && (
                        <Table>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                              MCX BROKERAGE INFO:
                            </TableCell>
                            {MCX?.brokerageType === 'lotwise' && (
                              <TableCell style={{ textTransform: 'uppercase' }}>TYPE: {MCX?.brokerageType}</TableCell>
                            )}
                          </TableRow>
                          {MCX?.brokerageType === 'percentage' && (
                            <TableRow>
                              <TableCell style={{ textTransform: 'uppercase' }}>TYPE: {MCX?.brokerageType}</TableCell>
                              <TableCell>
                                ADMIN: {`${+MCX?.brokerageValue === 0 ? 'NA' : `${MCX?.brokerageValue}%`}`}
                              </TableCell>
                              <TableCell>
                                S-ADMIN:
                                {`${
                                  +MCX?.superAdmin?.brokerageValue === 0 ? 'NA' : `${MCX?.superAdmin?.brokerageValue}%`
                                }`}
                              </TableCell>
                              <TableCell>
                                BROKER:
                                {`${+MCX?.broker?.brokerageValue === 0 ? 'NA' : `${MCX?.broker?.brokerageValue}%`}`}
                              </TableCell>
                            </TableRow>
                          )}
                          {MCX?.brokerageType === 'lotwise' && (
                            <>
                              <TableRow>
                                <TableCell> ADMIN BROKERAGE SHARE: </TableCell>
                                <TableCell />
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  METAL: {+MCX?.brokerage?.metal === 0 ? 'NA' : MCX?.brokerage?.metal}
                                </TableCell>
                                <TableCell>
                                  MINI METAL: {+MCX?.brokerage?.miniMetal === 0 ? 'NA' : MCX?.brokerage?.miniMetal}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  ENERGY: {+MCX?.brokerage?.energy === 0 ? 'NA' : MCX?.brokerage?.energy}
                                </TableCell>
                                <TableCell>
                                  BASE METAL: {+MCX?.brokerage?.baseMetal === 0 ? 'NA' : MCX?.brokerage?.baseMetal}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell> SUPER-ADMIN BROKERAGE SHARE: </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  METAL:{' '}
                                  {+MCX?.superAdmin?.brokerage?.metal === 0 ? 'NA' : MCX?.superAdmin?.brokerage?.metal}
                                </TableCell>
                                <TableCell>
                                  MINI METAL:{' '}
                                  {+MCX?.superAdmin?.brokerage?.miniMetal === 0
                                    ? 'NA'
                                    : MCX?.superAdmin?.brokerage?.miniMetal}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  ENERGY:{' '}
                                  {+MCX?.superAdmin?.brokerage?.energy === 0
                                    ? 'NA'
                                    : MCX?.superAdmin?.brokerage?.energy}
                                </TableCell>
                                <TableCell>
                                  BASE METAL:{' '}
                                  {+MCX?.superAdmin?.brokerage?.baseMetal === 0
                                    ? 'NA'
                                    : MCX?.superAdmin?.brokerage?.baseMetal}
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                        </Table>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <Accordion
                    expanded={expandedShareInfo}
                    onChange={handleExpandedShareInfo}
                    sx={{ marginTop: '16px', alignSelf: 'center' }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography style={{ fontWeight: 'bold' }}>{`${roleName} BROKERAGE SHARE`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {+NSE?.brokerageValue === 0 &&
                      +MCX?.brokerageValue === 0 &&
                      +MCX?.brokerage?.metal === 0 &&
                      +MCX?.brokerage?.baseMetal === 0 &&
                      +MCX?.brokerage?.energy === 0 &&
                      +MCX?.brokerage?.miniMetal === 0 ? (
                        <Table>
                          <TableRow>
                            <TableCell
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                              }}
                            >
                              NA
                            </TableCell>
                          </TableRow>
                        </Table>
                      ) : (
                        <Table>
                          {NSE?.allow === true && (
                            <TableRow>
                              <TableCell style={{ textTransform: 'uppercase' }}>
                                NSE BROKERAGE TYPE: {NSE?.brokerageType}
                              </TableCell>
                              {NSE?.brokerageType === 'percentage' && (
                                <>
                                  <TableCell>
                                    SHARE: {`${+NSE?.brokerageValue === 0 ? 'NA' : `${NSE?.brokerageValue}%`}`}
                                  </TableCell>
                                </>
                              )}
                              {NSE?.brokerageType === 'crorebase' && (
                                <>
                                  <TableCell>
                                    SHARE: {`${+NSE?.brokerageValue === 0 ? 'NA' : `${NSE?.brokerageValue}/1CR`}`}
                                  </TableCell>
                                </>
                              )}
                            </TableRow>
                          )}
                          {MCX?.allow === true && (
                            <>
                              <TableRow>
                                <TableCell style={{ textTransform: 'uppercase' }}>
                                  MCX BROKERAGE TYPE: {MCX?.brokerageType}
                                </TableCell>
                                {MCX?.brokerageType === 'percentage' ? (
                                  <TableCell>
                                    SHARE: {`${+MCX?.brokerageValue === 0 ? 'NA' : `${MCX?.brokerageValue}%`}`}
                                  </TableCell>
                                ) : (
                                  <TableCell />
                                )}
                              </TableRow>
                              {MCX?.brokerageType === 'lotwise' && (
                                <>
                                  <TableRow>
                                    <TableCell>
                                      METAL: {+MCX?.brokerage?.metal === 0 ? 'NA' : MCX?.brokerage?.metal}
                                    </TableCell>
                                    <TableCell>
                                      MINI METAL: {+MCX?.brokerage?.miniMetal === 0 ? 'NA' : MCX?.brokerage?.miniMetal}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      ENERGY: {+MCX?.brokerage?.energy === 0 ? 'NA' : MCX?.brokerage?.energy}
                                    </TableCell>
                                    <TableCell>
                                      BASE METAL: {+MCX?.brokerage?.baseMetal === 0 ? 'NA' : MCX?.brokerage?.baseMetal}
                                    </TableCell>
                                  </TableRow>
                                </>
                              )}
                            </>
                          )}
                        </Table>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}

                <Accordion
                  expanded={expandedUserConfigs}
                  onChange={handleExpandUserConfigs}
                  sx={{ marginTop: '16px', alignSelf: 'center' }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontWeight: 'bold' }}>BROKER CONFIGURATIONS</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>ALLOW-NSE:</TableCell>
                            <TableCell style={{ color: NSE?.allow === true ? 'green' : 'red', fontWeight: 'bold' }}>
                              {NSE?.allow === true ? 'YES' : 'NO'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>ALLOW-MCX:</TableCell>
                            <TableCell style={{ color: MCX?.allow === true ? 'green' : 'red', fontWeight: 'bold' }}>
                              {MCX?.allow === true ? 'YES' : 'NO'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>ALLOW PENDING-TRADES</TableCell>
                            <TableCell
                              style={{
                                color: tradeConfig?.allowPendingTrade === true ? 'green' : 'red',
                                fontWeight: 'bold',
                              }}
                            >
                              {tradeConfig?.allowPendingTrade === true ? 'YES' : 'NO'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </TableContainer>
            )}
          </>
        )}

        {/* SECTION FOR CLIENT SETTINGS INFO */}
        {role === 'broker' && (
          <>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    ALLOW-NSE:{' '}
                    <span style={{ color: NSE?.allow === true ? 'green' : 'red', fontWeight: 'bold' }}>
                      {NSE?.allow === true ? 'YES' : 'NO'}
                    </span>
                  </TableCell>
                  <TableCell>
                    ALLOW-MCX:{' '}
                    <span style={{ color: MCX?.allow === true ? 'green' : 'red', fontWeight: 'bold' }}>
                      {MCX?.allow === true ? 'YES' : 'NO'}
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {/* Accordion for client brokerage information */}
            <Accordion
              expanded={expandedShareInfo}
              onChange={handleExpandedShareInfo}
              sx={{ marginTop: '16px', alignSelf: 'center' }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold' }}>CLIENT BROKERAGE INFO</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {NSE?.allow === true && (
                        <TableRow>
                          <TableCell>NSE:</TableCell>
                          <TableCell>{`${+NSE?.brokerageValue === 0 ? 'NA' : `${NSE?.brokerageValue}/1CR`}`}</TableCell>
                        </TableRow>
                      )}

                      {MCX?.allow === true && (
                        <>
                          <TableRow>
                            <TableCell>
                              GOLD <span style={{ fontSize: '12px' }}>(MCX)</span>:
                            </TableCell>
                            <TableCell>
                              {MCX?.GOLD?.brokerageValue === undefined || null
                                ? +MCX?.metal?.brokerageValue === 0
                                  ? 'NA'
                                  : MCX?.metal?.brokerageValue
                                : MCX?.GOLD?.brokerageValue}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              SILVER <span style={{ fontSize: '12px' }}>(MCX)</span>:
                            </TableCell>
                            <TableCell>
                              {MCX?.SILVER?.brokerageValue === undefined || null
                                ? +MCX?.metal?.brokerageValue === 0
                                  ? 'NA'
                                  : MCX?.metal?.brokerageValue
                                : MCX?.SILVER?.brokerageValue}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              GOLDM <span style={{ fontSize: '12px' }}>(MCX)</span>:{' '}
                            </TableCell>
                            <TableCell>
                              {MCX?.GOLDM?.brokerageValue === undefined || null
                                ? +MCX?.miniMetal?.brokerageValue === 0
                                  ? 'NA'
                                  : MCX?.miniMetal?.brokerageValue
                                : MCX?.GOLDM?.brokerageValue}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              MGOLD <span style={{ fontSize: '12px' }}>(MCX)</span>:{' '}
                            </TableCell>
                            <TableCell>
                              {MCX?.MGOLD?.brokerageValue === undefined || null
                                ? +MCX?.miniMetal?.brokerageValue === 0
                                  ? 'NA'
                                  : MCX?.miniMetal?.brokerageValue
                                : MCX?.MGOLD?.brokerageValue}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              MSILVER <span style={{ fontSize: '12px' }}>(MCX)</span>:{' '}
                            </TableCell>
                            <TableCell>
                              {MCX?.MSILVER?.brokerageValue === undefined || null
                                ? +MCX?.miniMetal?.brokerageValue === 0
                                  ? 'NA'
                                  : MCX?.miniMetal?.brokerageValue
                                : MCX?.MSILVER?.brokerageValue}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              SILVERM <span style={{ fontSize: '12px' }}>(MCX)</span>:{' '}
                            </TableCell>
                            <TableCell>
                              {MCX?.SILVERM?.brokerageValue === undefined || null
                                ? +MCX?.miniMetal?.brokerageValue === 0
                                  ? 'NA'
                                  : MCX?.miniMetal?.brokerageValue
                                : MCX?.SILVERM?.brokerageValue}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              CRUDEOIL <span style={{ fontSize: '12px' }}>(MCX)</span>:
                            </TableCell>
                            <TableCell>
                              {MCX?.CRUDEOIL?.brokerageValue === undefined || null
                                ? +MCX?.energy?.brokerageValue === 0
                                  ? 'NA'
                                  : MCX?.energy?.brokerageValue
                                : MCX?.CRUDEOIL?.brokerageValue}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              NATURALGAS <span style={{ fontSize: '12px' }}>(MCX)</span>:
                            </TableCell>
                            <TableCell>
                              {MCX?.NATURALGAS?.brokerageValue === undefined || null
                                ? +MCX?.energy?.brokerageValue === 0
                                  ? 'NA'
                                  : MCX?.energy?.brokerageValue
                                : MCX?.NATURALGAS?.brokerageValue}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              ALUMINIUM <span style={{ fontSize: '12px' }}>(MCX)</span>:
                            </TableCell>
                            <TableCell>
                              {MCX?.ALUMINIUM?.brokerageValue === undefined || null
                                ? +MCX?.baseMetal?.brokerageValue === 0
                                  ? 'NA'
                                  : MCX?.baseMetal?.brokerageValue
                                : MCX?.ALUMINIUM?.brokerageValue}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              COPPER <span style={{ fontSize: '12px' }}>(MCX)</span>:
                            </TableCell>
                            <TableCell>
                              {MCX?.COPPER?.brokerageValue === undefined || null
                                ? +MCX?.baseMetal?.brokerageValue === 0
                                  ? 'NA'
                                  : MCX?.baseMetal?.brokerageValue
                                : MCX?.COPPER?.brokerageValue}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              LEAD <span style={{ fontSize: '12px' }}>(MCX)</span>:
                            </TableCell>
                            <TableCell>
                              {MCX?.LEAD?.brokerageValue === undefined || null
                                ? +MCX?.baseMetal?.brokerageValue === 0
                                  ? 'NA'
                                  : MCX?.baseMetal?.brokerageValue
                                : MCX?.LEAD?.brokerageValue}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              ZINC <span style={{ fontSize: '12px' }}>(MCX)</span>:
                            </TableCell>
                            <TableCell>
                              {MCX?.ZINC?.brokerageValue === undefined || null
                                ? +MCX?.baseMetal?.brokerageValue === 0
                                  ? 'NA'
                                  : MCX?.baseMetal?.brokerageValue
                                : MCX?.ZINC?.brokerageValue}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            {/* Accordion for client config settings */}
            <Accordion
              expanded={expandedUserConfigs}
              onChange={handleExpandUserConfigs}
              sx={{ marginTop: '16px', alignSelf: 'center' }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold' }}>CLIENT CONFIG SETTINGS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>ALLOW PENDING-TRADES</TableCell>
                        <TableCell
                          style={{
                            color: tradeConfig?.allowPendingTrade === true ? 'green' : 'red',
                            fontWeight: 'bold',
                          }}
                        >
                          {tradeConfig?.allowPendingTrade === true ? 'YES' : 'NO'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>CLOSE EXISTING-POSISITION</TableCell>
                        <TableCell
                          style={{
                            color: tradeConfig?.closeExistingPosition === true ? 'green' : 'red',
                            fontWeight: 'bold',
                          }}
                        >
                          {tradeConfig?.closeExistingPosition === true ? 'YES' : 'NO'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>ALLOW ORDER-AT-LTP</TableCell>
                        <TableCell
                          style={{
                            color: tradeConfig?.allowOrderAtLtp === true ? 'green' : 'red',
                            fontWeight: 'bold',
                          }}
                        >
                          {tradeConfig?.allowOrderAtLtp === true ? 'YES' : 'NO'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>ALLOW FRESH-ORDER</TableCell>
                        <TableCell
                          style={{
                            color: tradeConfig?.allowFreshOrder === true ? 'green' : 'red',
                            fontWeight: 'bold',
                          }}
                        >
                          {tradeConfig?.allowFreshOrder === true ? 'YES' : 'NO'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>ALLOW ORDER-BETWEEN-HIGH-LOW</TableCell>
                        <TableCell
                          style={{
                            color: tradeConfig?.allowOrderBetweenHighLow === true ? 'green' : 'red',
                            fontWeight: 'bold',
                          }}
                        >
                          {tradeConfig?.allowOrderBetweenHighLow === true ? 'YES' : 'NO'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>ALLOW ORDER-BOTH-SIDE</TableCell>
                        <TableCell
                          style={{
                            color: tradeConfig?.allowOrderBothSide === true ? 'green' : 'red',
                            fontWeight: 'bold',
                          }}
                        >
                          {tradeConfig?.allowOrderBothSide === true ? 'YES' : 'NO'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>AUTO-CLOSE-INTRADAY</TableCell>
                        <TableCell
                          style={{
                            color: tradeConfig?.autoCloseIntraday === true ? 'green' : 'red',
                            fontWeight: 'bold',
                          }}
                        >
                          {tradeConfig?.autoCloseIntraday === true ? 'YES' : 'NO'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>AUTO-CLOSE-OVERNIGHT</TableCell>
                        <TableCell
                          style={{
                            color: tradeConfig?.autoCloseOvernight === true ? 'green' : 'red',
                            fontWeight: 'bold',
                          }}
                        >
                          {tradeConfig?.autoCloseOvernight === true ? 'YES' : 'NO'}
                        </TableCell>
                      </TableRow>
                      {(tradeConfig?.autoCloseOvernight === true || tradeConfig?.autoCloseIntraday === true) && (
                        <>
                          <TableRow>
                            <TableCell>CONFIGURED-PERCENTAGE</TableCell>
                            <TableCell>
                              {+tradeConfig?.autoCloseLossPercentage === 0
                                ? 'NA'
                                : tradeConfig?.autoCloseLossPercentage}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            {/* Accordion fro client NSE scripts setting preview  */}
            {NSE?.allow === true && (
              <Accordion
                expanded={expandedNSE}
                onChange={handleExpandNSE}
                sx={{ marginTop: '16px', alignSelf: 'center' }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{ fontWeight: 'bold' }}>NSE SCRIPT SETTINGS PREVIEW</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Scripts Price Range</TableCell>
                          <TableCell>Min</TableCell>
                          <TableCell>Per Trade</TableCell>
                          <TableCell>Max</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {NSE?.shareConfig &&
                          (() => {
                            const shareConfigEntries = Object.entries(NSE.shareConfig);
                            let prevKey = 0;

                            // eslint-disable-next-line no-unused-vars
                            return shareConfigEntries.map(([key, value], index) => {
                              const currentKey = +key;
                              const range = isNaN(currentKey)
                                ? key === 'rest'
                                  ? '> 30,000'
                                  : key
                                : `${prevKey.toLocaleString()}-${currentKey.toLocaleString()}`;
                              prevKey = currentKey;

                              return (
                                <TableRow key={key}>
                                  <TableCell>{range}</TableCell>
                                  <TableCell>{value.min.toLocaleString()}</TableCell>
                                  <TableCell>{value.perTradeLimit.toLocaleString()}</TableCell>
                                  <TableCell>{value.max.toLocaleString()}</TableCell>
                                </TableRow>
                              );
                            });
                          })()}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            )}

            {/* Accordion fro client MCX scripts setting preview  */}
            {MCX?.allow === true && (
              <Accordion
                expanded={expandedMCX}
                onChange={handleExpandMCX}
                sx={{ marginTop: '16px', alignSelf: 'center' }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{ fontWeight: 'bold' }}>MCX SCRIPT SETTINGS PREVIEW</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Script Name</TableCell>
                          <TableCell>Per Trade</TableCell>
                          <TableCell>Max</TableCell>
                          <TableCell>HoldingExposure</TableCell>
                          <TableCell>IntradayExposure</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(MCX)
                          .filter(
                            (script) =>
                              script !== 'allow' &&
                              script !== 'maximumOpenLotAllowed' &&
                              script !== 'maximumOpenLotPerScript' &&
                              script !== 'maximumLotforSingleTrade' &&
                              script !== 'blockedScripts'
                          )
                          .map((script) => {
                            const data = MCX[script];
                            return (
                              <TableRow key={script}>
                                <TableCell>{script}</TableCell>
                                <TableCell>{data.perTradeLimit}</TableCell>
                                <TableCell>{data.perScriptLimit}</TableCell>
                                <TableCell>{formatNumber(data?.holdingExposure)}</TableCell>
                                <TableCell>{formatNumber(data?.intradayExposure)}</TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            )}
          </>
        )}

        {/* Centered Buttons */}
        <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', marginTop: '24px' }}>
          {user.role.rolename !== 'superAdmin' && (
            <Button sx={{ flex: '0 1 auto' }} onClick={handleEdit}>
              Edit
            </Button>
          )}
          <Button onClick={onClose} variant="contained" color="primary" sx={{ flex: '0 1 auto' }}>
            Close
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default UserConfigInfo;
