import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { RHFSwitch, RHFTextField, RHFToggleButtonGroup } from '../../../../../components/hook-form';

const GeneralSettingsForm = ({ userType, methods, manager }) => {
  const { setValue, watch } = methods;
  const profitLossShare = watch('profitLossShare'); // Watch for changes in profitLossShare
  const superAdminProfitLossShare = watch('superAdminProfitLossShare'); // Watch for changes in Super Admin Profit/Loss Share

  // Update brokerProfitLossShare when either profitLossShare or superAdminProfitLossShare changes
  useEffect(() => {
    console.log('changed UserType', { profitLossShare, superAdminProfitLossShare });
    if (userType === 'Broker' && manager.profitLossType === 'brokerwise') {
      const newBrokerBrokerage = 100 - Number(profitLossShare) - Number(superAdminProfitLossShare);
      setValue('brokerProfitLossShare', newBrokerBrokerage);
    }
  }, [profitLossShare, superAdminProfitLossShare, userType, manager.profitLossType, setValue]);

  let formContent = null;

  if (userType !== 'Client') {
    const isAdmin = userType === 'Admin';
    const isBroker = userType === 'Broker';
    const profitLossType = methods.watch('profitLossType'); // Watch for changes in profitLossType
    formContent = (
      <Box
        sx={{
          display: 'grid',
          columnGap: 2,
          rowGap: 3,
          gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
        }}
      >
        <RHFSwitch name="isActive" label="Activate Account" labelPlacement="start" sx={{ justifyContent: 'start' }} />
        <RHFSwitch
          name="tradeConfig.allowPendingTrade"
          label="Allow Pending Trades"
          labelPlacement="start"
          sx={{ justifyContent: 'start' }}
        />

        {/* Render Profit/Loss Type Toggle Button Group for Admin */}
        {isAdmin && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography sx={{ mr: 2 }}>P/L Share Type</Typography>
              <RHFToggleButtonGroup
                name="profitLossType"
                label="Profit/Loss Type"
                color="primary"
                defaultValue={'adminwise'}
                sx={{ width: '100%' }}
                options={[
                  { label: 'Brokerwise', value: 'brokerwise' },
                  { label: 'Adminwise', value: 'adminwise' },
                ]}
              />
            </Box>
            {profitLossType === 'adminwise' && <RHFTextField name="profitLossShare" label="Profit/Loss Share (%)" />}
          </>
        )}

        {/* Additional fields for Broker */}
        {isBroker && (
          <>
            {manager.profitLossType === 'brokerwise' ? (
              <>
                {/* Conditionally render fields based on Manager's Profit/Loss Type */}
                <RHFTextField name="superAdminProfitLossShare" label="Super Admin P/L share (%)" />
                <RHFTextField name="profitLossShare" label="Profit/Loss Share (%)" />
                <RHFTextField name="brokerProfitLossShare" label="Broker Brokerage (%)" disabled />
              </>
            ) : (
              <RHFTextField name="profitLossShare" label="Profit/Loss Share (%)" />
            )}
          </>
        )}

        {/* Conditionally render Profit/Loss Share field based on Profit/Loss Type */}
      </Box>
    );
  } else {
    // For Client user type
    formContent = (
      <Box
        sx={{
          display: 'grid',
          columnGap: 2,
          rowGap: 2,
          gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
        }}
      >
        <Box
          sx={{
            display: 'grid',
            columnGap: 2,
            rowGap: 2,
            gridTemplateColumns: 'repeat(1, 1fr)',
            gridTemplateRows: 'repeat(6, auto)',
          }}
        >
          <RHFSwitch name="isActive" label="Activate Account" sx={{ justifyContent: 'start' }} />
          <RHFSwitch name="tradeConfig.closeExistingPosition" label="Close Existing Positions Only" />
          <RHFSwitch name="tradeConfig.allowOrderAtLtp" label="Allow Order At LTP" />
          <RHFSwitch name="tradeConfig.allowFreshOrder" label="Allow Fresh Entry Order Above High & Below Low" />
          <RHFSwitch name="tradeConfig.allowOrderBetweenHighLow" label="Allow Order Between High & Low" />
          <RHFSwitch name="tradeConfig.allowOrderBothSide" label="Allow Pending Order Both Sides(Buy/Sell)" />
        </Box>
        <Box sx={{ display: 'flex', columnGap: 2, rowGap: 2, flexDirection: 'column' }}>
          <RHFSwitch name="tradeConfig.autoCloseIntraday" label="Intraday Auto-Close at Configured Limit" />
          <RHFSwitch name="tradeConfig.autoCloseOvernight" label="Overnight Auto-Close at Configured Limit" />
          {(methods.getValues().tradeConfig?.autoCloseIntraday ||
            methods.getValues().tradeConfig?.autoCloseOvernight) && (
            <RHFTextField name="tradeConfig.autoCloseLossPercentage" label="% of Loss to Trigger AutoClose" />
          )}
        </Box>
      </Box>
    );
  }

  return formContent;
};

export default GeneralSettingsForm;
