import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  entriesData: [],
  currentPage: null,
  totalPages: null,
  totalResults: null,
  matchesData: [],
};

const slice = createSlice({
  name: 'entries',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET FORWARDERS
    getEntriesDataSuccess(state, action) {
      state.isLoading = false;
      state.entriesData = action?.payload?.entries;
      state.totalPage = action?.payload?.totalPages;
      state.currentPage = action?.payload?.page;
      state.totalResults = action?.payload?.totalResults;
    },

    // GET MATCHES
    getMatchesDataSuccess(state, action) {
      state.isLoading = false;
      state.matchesData = action?.payload?.matches;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const entriesActions = slice.actions;

// ----------------------------------------------------------------------

// GET ENTRIES DATA
export function getEntriesData(pageSize, pageNumber, order, searchWord, cancelToken) {
  return async () => {
    let response;
    try {
      if (searchWord?.length > 0) {
        response = await axios.get(
          `/admin/entry/?order=${order}&pageSize=${pageSize}&page=${pageNumber}&search=${searchWord}`,
          {
            cancelToken: cancelToken.token,
          }
        );
      } else {
        await dispatch(slice.actions.startLoading());
        response = await axios.get(`/admin/entry/?order=${order}&pageSize=${pageSize}&page=${pageNumber}`);
      }
      const { data, status, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.getEntriesDataSuccess(data));
      } else {
        dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// GET MATCHES DATA
export function getMatchesData(id) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/admin/entry/matches/${id}`);
      const { data } = response?.data;
      await dispatch(slice.actions.getMatchesDataSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
