// @mui
import { enUS, frFR, zhCN, viVN, arSD, hiIN } from '@mui/material/locale';
// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

export const MCXSCRIPTOPTIONS = [
  { name: 'GOLD' },
  { name: 'SILVER' },
  { name: 'CRUDEOIL' },
  { name: 'MGOLD' },
  { name: 'MSILVER' },
  { name: 'COPPER' },
  { name: 'NICKEL' },
  { name: 'ZINC' },
  { name: 'LEAD' },
  { name: 'NATURALGAS' },
  { name: 'ALUMINIUM' },
  { name: 'MENTHOIL' },
  { name: 'COTTON' },
  { name: 'CPO' },
  { name: 'GOLD-MINI' },
  { name: 'SILVER-MIN' },
  { name: 'SILVER-MIC' },
];
// export const MCXSCRIPTOPTIONS = [
//   'GOLD',
//   'SILVER',
//   'CRUDEOIL',
//   'MGOLD',
//   'MSILVER',
//   'COPPER',
//   'NICKEL',
//   'ZINC',
//   'LEAD',
//   'NATURALGAS',
//   'ALUMINIUM',
//   'MENTHOIL',
//   'COTTON',
//   'CPO',
//   'GOLD-MINI',
//   'SILVER-MIN',
//   'SILVER-MIC',
// ];

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.dashboard; // as '/admin/app'
export const PATH_AFTER_LOGIN_OPERATOR = PATH_DASHBOARD.operatorRole.general.app; // as '/operator/app'
export const PATH_AFTER_LOGIN_INSURER = PATH_DASHBOARD.insurerRole.brokerlist.root; // as '/operator/app'
export const PATH_AFTER_LOGIN_BANKER = PATH_DASHBOARD.bankerRole.watchlist.root; // as '/operator/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: true,
  selectedTab: 'MCX',
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: frFR,
    icon: '/assets/icons/flags/ic_flag_fr.svg',
  },
  {
    label: 'Vietnamese',
    value: 'vn',
    systemValue: viVN,
    icon: '/assets/icons/flags/ic_flag_vn.svg',
  },
  {
    label: 'Chinese',
    value: 'cn',
    systemValue: zhCN,
    icon: '/assets/icons/flags/ic_flag_cn.svg',
  },
  {
    label: 'Arabic (Sudan)',
    value: 'ar',
    systemValue: arSD,
    icon: '/assets/icons/flags/ic_flag_sa.svg',
  },
  {
    label: 'India (Hindi)',
    value: 'in',
    systemValue: hiIN,
    icon: '/assets/icons/flags/ic_flag_sa.svg',
  },
];

export const defaultLang = allLangs[0]; // English
