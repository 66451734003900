/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import modifyScriptName from '../../../../utils/modifyScriptName';
import { fDateTime } from '../../../../utils/formatTime';

const TradeHistoryRow = ({ trade }) => {
  const { type, buy, sell, buyBrokerage, sellBrokerage, boughtAt, soldAt, script, profit, brokerage, side, updatedAt } =
    trade;
  let color;
  if (['EXECUTED', 'CANCELLED'].includes(type)) {
    color = 'green';
  }
  if (['OPEN', 'ORDER'].includes(type)) {
    if (side === 'BUY') {
      color = 'blue';
    } else {
      color = 'red';
    }
  }
  return (
    <Box sx={{ borderBottom: '0.5px solid grey', width: '100%', py: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        {/* First column */}
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          <Typography variant="h5">{modifyScriptName(script.tradingsymbol)},</Typography>
          <Typography
            variant="h5"
            textTransform={'capitalize'}
            color={color}
            // color={`${trade.side === 'BUY' ? 'blue' : trade.side === 'SELL' ? 'red' : 'green'}`}
          >
            {trade.type === 'ORDER' && `Close ${trade.side === 'BUY' ? 'Sell ' : 'Buy '}`}
            {trade.type === 'OPEN' && `New ${trade.side.toLowerCase()} `}
            {['EXECUTED', 'CANCELLED'].includes(trade.type) &&
              `Limit ${trade.type.toLowerCase()}  ${trade.side.toLowerCase()} `}
            {trade.quantity}
          </Typography>
        </Box>
        {type === 'ORDER' && (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid container columnGap={2.5}>
              <Grid item>
                <Typography variant="body2">BoughtAt: </Typography>
                <Typography variant="body2">SoldAt:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{fDateTime(boughtAt)}</Typography>
                <Typography variant="body2">{fDateTime(soldAt)}</Typography>
              </Grid>
            </Grid>
            <Typography variant="h4" color={`${profit > 0 ? 'blue' : 'red'}`}>
              {profit.toFixed(2)}
            </Typography>
          </Box>
        )}
        {['EXECUTED', 'CANCELLED'].includes(type) && (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid container columnGap={2.5}>
              <Grid item>
                <Typography variant="body2">{type === 'CANCELLED' ? 'CancelledAt' : 'UpdatedAt'}: </Typography>
                {/* <Typography variant="body2">SoldAt:</Typography> */}
              </Grid>
              <Grid item>
                {/* <Typography variant="body2">{fDateTime(boughtAt)}</Typography> */}
                <Typography variant="body2">{fDateTime(updatedAt)}</Typography>
              </Grid>
            </Grid>
            {/* <Typography variant="h4" color={`${profit > 0 ? 'blue' : 'red'}`}>
              {profit.toFixed(2)}
            </Typography> */}
          </Box>
        )}
        {type === 'OPEN' && (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid container columnGap={2.5}>
              <Grid item>
                <Typography variant="body2">{side === 'BUY' ? 'boughAt' : 'soldAt'}: </Typography>
                {/* <Typography variant="body2">SoldAt:</Typography> */}
              </Grid>
              <Grid item>
                {/* <Typography variant="body2">{fDateTime(boughtAt)}</Typography> */}
                <Typography variant="body2">{fDateTime(side === 'BUY' ? boughtAt : soldAt)}</Typography>
              </Grid>
            </Grid>
            {/* <Typography variant="h4" color={`${profit > 0 ? 'blue' : 'red'}`}>
              {profit.toFixed(2)}
            </Typography> */}
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {/* Buy and sell prices */}
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              {/* Buy price */}
              {buy && (
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body2">Buy:</Typography>
                  <Typography variant="body2"> {buy?.toFixed(2) || '--'}</Typography>
                </Box>
              )}
              {sell && (
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body2">Sell:</Typography>
                  <Typography variant="body2"> {sell?.toFixed(2) || '--'}</Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            {type === 'OPEN' && (
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="body2">Brokerage:</Typography>
                <Typography variant="body2"> {brokerage?.toFixed(2) || '--'}</Typography>
              </Box>
            )}
            {type === 'ORDER' && (
              <>
                {' '}
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body2">Buy Brokerage:</Typography>
                  <Typography variant="body2"> {buyBrokerage?.toFixed(2) || '--'}</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body2">Sell Brokerage:</Typography>
                  <Typography variant="body2"> {sellBrokerage?.toFixed(2) || '--'}</Typography>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TradeHistoryRow;
